import React, { useState, useEffect, useCallback } from "react";
import ReactSlider from "react-slider";
import axios from "axios";
import "./css/PriceRangeFilter.css";

const PriceRangeFilter = ({ onChange, minPrice, maxPrice }) => {
  const [min, setMin] = useState(minPrice);
  const [max, setMax] = useState(maxPrice);
  const [inputMin, setInputMin] = useState(minPrice);
  const [inputMax, setInputMax] = useState(maxPrice);
  const [initialMin, setInitialMin] = useState(minPrice);
  const [initialMax, setInitialMax] = useState(maxPrice);
  const [settings, setSettings] = useState({
    phones: "",
    currency: "",
  });

  // Add debounce delay
  const DEBOUNCE_DELAY = 1000; // 1 second delay

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/api/settings`
        );
        setSettings(response.data);
      } catch (error) {
        console.error("Error fetching settings from the database:", error);
      }
    };

    fetchSettings();
  }, []);

  useEffect(() => {
    const fetchPrices = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/api/prices`
        );
        const minValue = response.data.minPrice || 0;
        const maxValue = response.data.maxPrice || 1000;

        setInitialMin(minValue);
        setInitialMax(maxValue);
        setMin(minValue);
        setMax(maxValue);
        setInputMin(minValue);
        setInputMax(maxValue);
        onChange("minPrice", minValue);
        onChange("maxPrice", maxValue);
      } catch (error) {
        console.error("Error fetching prices:", error);
      }
    };
    fetchPrices();
  }, []);

  // Debounced price update
  useEffect(() => {
    const timer = setTimeout(() => {
      if (
        inputMin >= initialMin &&
        inputMin <= inputMax &&
        inputMax >= inputMin
      ) {
        onChange("minPrice", inputMin);
        onChange("maxPrice", inputMax);
        setMin(inputMin);
        setMax(inputMax);
      }
    }, DEBOUNCE_DELAY);

    return () => clearTimeout(timer);
  }, [inputMin, inputMax]);

  const handleSliderChange = (values) => {
    setMin(values[0]);
    setMax(values[1]);
    setInputMin(values[0]);
    setInputMax(values[1]);
    onChange("minPrice", values[0]);
    onChange("maxPrice", values[1]);
  };

  const handleMinPriceChange = (e) => {
    const value = Number(e.target.value);
    if (!isNaN(value)) {
      setInputMin(value);
    }
  };

  const handleMaxPriceChange = (e) => {
    const value = Number(e.target.value);
    if (!isNaN(value)) {
      setInputMax(value);
    }
  };

  // Handle input blur (when user clicks away)
  const handleInputBlur = () => {
    // Validate and update values immediately on blur
    let newMin = inputMin;
    let newMax = inputMax;

    // Ensure min is not less than initial min
    if (newMin < initialMin) newMin = initialMin;
    // Ensure max is not greater than initial max
    if (newMax > initialMax) newMax = initialMax;
    // Ensure min is not greater than max
    if (newMin > newMax) newMin = newMax;
    // Ensure max is not less than min
    if (newMax < newMin) newMax = newMin;

    setInputMin(newMin);
    setInputMax(newMax);
    setMin(newMin);
    setMax(newMax);
    onChange("minPrice", newMin);
    onChange("maxPrice", newMax);
  };

  return (
    <div className="price-range-filter">
      <h6>Price range</h6>
      <ReactSlider
        className="horizontal-slider"
        thumbClassName="thumb"
        trackClassName="track"
        value={[min, max]}
        min={initialMin}
        max={initialMax}
        step={10}
        pearling
        minDistance={10}
        onChange={handleSliderChange}
      />
      <div className="price-inputs">
        <span>{settings.currency}</span>
        <input
          className="price-range-input"
          type="number"
          value={inputMin}
          onChange={handleMinPriceChange}
          onBlur={handleInputBlur}
        />
        <span>To</span>
        <input
          className="price-range-input"
          type="number"
          value={inputMax}
          onChange={handleMaxPriceChange}
          onBlur={handleInputBlur}
        />
      </div>
    </div>
  );
};

export default PriceRangeFilter;
