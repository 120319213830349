import React from 'react';
import './css/ViewMoreButton.css'; // Import CSS for additional styling

const ViewMoreButton = ({ onClick, isVisible }) => {
  if (!isVisible) {
    return null;
  }

  return (
    <div align="center" className="text-center mt-4">
      <button align="center" className="btn btn-secondary view-more-button d-flex align-items-center justify-content-center" onClick={onClick}>
        View more
        <span className="material-icons ms-2">expand_more</span>
      </button>
    </div>
  );
};

export default ViewMoreButton;
