import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt, faEnvelope, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faInstagram, faYoutube, faTwitter, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import './css/Footer.css';

const Footer = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [settings, setSettings] = useState({
    phones: '',
    email: '',
    location: '',
    currency: '',
    websiteName: '',
    shortDescription: '',
    privacyPolicy: '',
    termsConditions: '',
    returnPolicy: '',
    facebookLink: '',
    twitterLink: '',
    youtubeLink: '',
    whatsappLink: '',
    jwtSecret: '',
    baseUrl: '',
    logoUrl: '',
    whatsappPhone: '',
    serverUrl: ''
  });

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/settings`);
        setSettings(response.data);
      } catch (error) {
        console.error('Error fetching settings from the database:', error);
      }
    };

    fetchSettings();
  }, []);

  const handleSubscribe = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/subscribe`, { email });
      setMessage(response.data.message);
    } catch (error) {
      setMessage(error.response.data.message || 'An error occurred.');
    }
  };


  return (
    <footer className="footer mt-auto py-5 bg-light">
      <div className="container" align="left">
        <div className="row mb-4">
          <div className="col-md-4 mb-3 mb-md-0">
            <h5 className="footer-title">
              <img src={`${settings.serverUrl}${settings.logoUrl}`} alt="Logo" className="footer-logo" />
            </h5>
            <p className="footer-description">
              {settings.shortDescription.replace("<p>","").replace("</p>","")}
            </p>
            <ul className="list-unstyled contact-info">
              <li><FontAwesomeIcon icon={faPhoneAlt} className="me-2" />{settings.phones}</li>
              <li><FontAwesomeIcon icon={faEnvelope} className="me-2" />{settings.email}</li>
              <li><FontAwesomeIcon icon={faMapMarkerAlt} className="me-2" />{settings.location}</li>
            </ul>
          </div>
          <div className="col-md-2 mb-3 mb-md-0">
            <h5 className="footer-title">Important Links</h5>
            <ul className="list-unstyled footer-link" >
              <li className='footer-link'><a href="/">Home</a></li>
              <li className='footer-link'><a href="https://karibuhub.com/">Get a website like this</a></li>
            </ul>
          </div>
          <div className="col-md-2 mb-3 mb-md-0">
            <h5 className="footer-title">Quick Links</h5>
            <ul className="list-unstyled">
              <li className='footer-link'><a href="/privacy-policy">Privacy Policy</a></li>
              <li className='footer-link'><a href="/terms-and-conditions">Terms And Conditions</a></li>
              <li className='footer-link'><a href="/return-policy">Return Policy</a></li>
            </ul>
          </div>
          <div className="col-md-4">
            <h5 className="footer-title">Join Our Mailing List</h5>
            <p>Stay updated with our latest offers and news!</p>
            <form className="subscribe-form" onSubmit={(e) => { e.preventDefault(); handleSubscribe(); }}>
              <div className="input-group mb-3">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Enter your email"
                  aria-label="Recipient's email"
                  aria-describedby="button-addon2"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <button className="btn btn-subscribe" type="submit" id="button-addon2">Subscribe</button>
              </div>
              {message && <p className="subscribe-message">{message}</p>}
            </form>

            <h5 className="footer-title">Stay Connected with Us!</h5>
            <ul className="list-inline social-links">
              <li className="list-inline-item"><a href={settings.facebookLink}><FontAwesomeIcon icon={faFacebook} /></a></li>
              <li className="list-inline-item"><a href={settings.instagramLink}><FontAwesomeIcon icon={faInstagram} /></a></li>
              <li className="list-inline-item"><a href={settings.youtubeLink}><FontAwesomeIcon icon={faYoutube} /></a></li>
              <li className="list-inline-item"><a href={settings.twitterLink}><FontAwesomeIcon icon={faTwitter} /></a></li>
              <li className="list-inline-item"><a href={settings.whatsappLink}><FontAwesomeIcon icon={faWhatsapp} /></a></li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-center">
            <p className="mb-0">&copy; {process.env.REACT_APP_COPYRIGHT}</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
