import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Modal,
  Button,
  Form,
  Table,
  Pagination,
  Alert,
  InputGroup,
} from "react-bootstrap";
import { Helmet } from "react-helmet";
import {
  Search,
  UserPlus,
  Edit2,
  Trash2,
  Mail,
  Phone,
  User,
} from "lucide-react";

const ManageUsers = () => {
  // State management
  const [users, setUsers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    password: "",
    receiveNewsletters: false,
  });
  const [error, setError] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const usersPerPage = 10;
  const [settings, setSettings] = useState({
    websiteName: "",
    siteDescription: "",
    keywords: "",
    baseUrl: "",
    serverUrl: "",
    logoUrl: "",
  });

  // Styles
  const cardStyle = {
    background: "white",
    borderRadius: "12px",
    padding: "24px",
    marginBottom: "24px",
    boxShadow: "0 2px 12px rgba(0,0,0,0.1)",
    border: "1px solid #eee",
  };

  const tableContainerStyle = {
    background: "white",
    borderRadius: "12px",
    overflow: "hidden",
    boxShadow: "0 2px 12px rgba(0,0,0,0.1)",
    border: "1px solid #eee",
  };

  const inputStyle = {
    padding: "12px",
    borderRadius: "8px",
    border: "1px solid #e0e0e0",
    fontSize: "14px",
    width: "100%",
    transition: "all 0.3s ease",
  };

  const buttonStyle = {
    padding: "10px 20px",
    borderRadius: "8px",
    border: "none",
    fontSize: "14px",
    fontWeight: "500",
    cursor: "pointer",
    transition: "all 0.3s ease",
  };

  // Fetch settings
  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/api/settings`
        );
        setSettings(response.data);
      } catch (error) {
        console.error("Error fetching settings:", error);
      }
    };
    fetchSettings();
  }, []);

  // Fetch users
  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/users`
      );
      setUsers(response.data);
    } catch (error) {
      setError("Failed to fetch users");
    } finally {
      setLoading(false);
    }
  };

  // Handle modal
  const handleShowModal = (user) => {
    if (user) {
      setSelectedUser(user);
      setFormData(user);
    } else {
      setSelectedUser(null);
      setFormData({
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        password: "",
        receiveNewsletters: false,
      });
    }
    setShowModal(true);
  };

  // Form handlers
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (selectedUser) {
        await axios.put(
          `${process.env.REACT_APP_SERVER_URL}/api/users/${selectedUser.id}`,
          formData
        );
      } else {
        await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/api/users`,
          formData
        );
      }
      fetchUsers();
      setShowModal(false);
    } catch (error) {
      setError("Failed to save user");
    }
  };

  const handleDeleteUser = async (id) => {
    if (window.confirm("Are you sure you want to delete this user?")) {
      try {
        await axios.delete(
          `${process.env.REACT_APP_SERVER_URL}/api/users/${id}`
        );
        fetchUsers();
      } catch (error) {
        setError("Failed to delete user");
      }
    }
  };

  // Filter and pagination
  const filteredUsers = users.filter(
    (user) =>
      user.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.phone.includes(searchTerm)
  );

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);
  const totalPages = Math.ceil(filteredUsers.length / usersPerPage);

  return (
    <div style={{ margin: "0 auto", padding: "20px 20px" }}>
      <Helmet>
        <title>{`Users | ${settings.websiteName}`}</title>
        <meta name="description" content={settings.siteDescription} />
        <meta name="keywords" content={settings.keywords} />
      </Helmet>

      {/* Header Section */}
      <div style={cardStyle}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <h1 style={{ fontSize: "1.8rem", margin: 0, color: "#2c3e50" }}>
            <User size={24} style={{ marginRight: "10px" }} />
            Users
          </h1>
          <Button
            variant="primary"
            onClick={() => handleShowModal(null)}
            style={{ ...buttonStyle, backgroundColor: "#2c3e50" }}
          >
            <UserPlus size={18} style={{ marginRight: "8px" }} />
            Add User
          </Button>
        </div>

        {/* Search Section */}
        <div style={{ alignItems: "left", gap: "8px" }} align="left">
          <Search size={20} style={{ marginRight: "8px" }} />
          <input
            type="text"
            placeholder="Search users..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{
              padding: "8px",
              borderRadius: "4px",
              border: "1px solid #ccc",
              flex: 1,
            }}
          />
        </div>
      </div>

      {/* Users Table */}
      <div style={tableContainerStyle}>
        <Table hover style={{ margin: 0 }}>
          <thead style={{ backgroundColor: "#f8f9fa" }}>
            <tr>
              <th style={{ padding: "16px", textAlign: "left" }}>Name</th>
              <th style={{ padding: "16px", textAlign: "left" }}>Contact</th>
              <th style={{ padding: "16px", textAlign: "left" }}>Newsletter</th>
              <th style={{ padding: "16px", textAlign: "left" }}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr style={{ textAlign: "left" }}>
                <td colSpan="4" style={{ textAlign: "left", padding: "20px" }}>
                  Loading...
                </td>
              </tr>
            ) : currentUsers.length === 0 ? (
              <tr>
                <td colSpan="4" style={{ textAlign: "left", padding: "20px" }}>
                  No users found
                </td>
              </tr>
            ) : (
              currentUsers.map((user) => (
                <tr key={user.id}>
                  <td style={{ padding: "16px", textAlign: "left" }}>
                    <div style={{ fontWeight: "500" }}>
                      {user.firstName} {user.lastName}
                    </div>
                  </td>
                  <td style={{ padding: "16px", textAlign: "left" }}>
                    <span
                      style={{
                        alignItems: "left",
                        gap: "8px",
                      }}
                    >
                      <Mail size={14} /> {user.email} | <Phone size={14} />{" "}
                      {user.phone}
                    </span>
                  </td>

                  <td style={{ padding: "16px", textAlign: "left" }}>
                    <span
                      style={{
                        padding: "4px 12px",
                        borderRadius: "12px",
                        fontSize: "12px",
                        backgroundColor: user.receiveNewsletters
                          ? "#e3fcef"
                          : "#fee2e2",
                        color: user.receiveNewsletters ? "#059669" : "#dc2626",
                      }}
                    >
                      {user.receiveNewsletters
                        ? "Subscribed"
                        : "Not subscribed"}
                    </span>
                  </td>
                  <td style={{ padding: "16px", textAlign: "left" }}>
                    <div style={{ display: "flex", gap: "8px" }}>
                      <Button
                        variant="outline-primary"
                        size="sm"
                        onClick={() => handleShowModal(user)}
                        style={{ padding: "8px", borderRadius: "6px" }}
                      >
                        <Edit2 size={16} />
                      </Button>
                      <Button
                        variant="outline-danger"
                        size="sm"
                        onClick={() => handleDeleteUser(user.id)}
                        style={{ padding: "8px", borderRadius: "6px" }}
                      >
                        <Trash2 size={16} />
                      </Button>
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
      </div>

      {/* Pagination */}
      {totalPages > 1 && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <Pagination>
            <Pagination.First onClick={() => setCurrentPage(1)} />
            <Pagination.Prev
              onClick={() => setCurrentPage((p) => Math.max(1, p - 1))}
              disabled={currentPage === 1}
            />
            {[...Array(totalPages)].map((_, idx) => (
              <Pagination.Item
                key={idx + 1}
                active={idx + 1 === currentPage}
                onClick={() => setCurrentPage(idx + 1)}
              >
                {idx + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next
              onClick={() => setCurrentPage((p) => Math.min(totalPages, p + 1))}
              disabled={currentPage === totalPages}
            />
            <Pagination.Last onClick={() => setCurrentPage(totalPages)} />
          </Pagination>
        </div>
      )}

      {/* User Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
        <Modal.Header
          closeButton
          style={{ border: "none", padding: "20px 24px 0" }}
        >
          <Modal.Title style={{ color: "#2c3e50" }}>
            {selectedUser ? "Edit User" : "Add New User"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: "20px !important" }}>
          <Form onSubmit={handleSubmit} style={{ padding: "20px !important" }}>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "16px",
                marginBottom: "16px",
              }}
            >
              <Form.Group>
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  style={inputStyle}
                  required
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  style={inputStyle}
                  required
                />
              </Form.Group>
            </div>

            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "16px",
                marginBottom: "16px",
              }}
            >
              <Form.Group>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  style={inputStyle}
                  required
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  type="text"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  style={inputStyle}
                  required
                />
              </Form.Group>
            </div>

            <Form.Group style={{ marginBottom: "16px" }}>
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                style={inputStyle}
                required={!selectedUser}
              />
            </Form.Group>

            <Form.Group style={{ marginBottom: "24px" }}>
              <Form.Check
                type="checkbox"
                name="receiveNewsletters"
                label="Subscribe to newsletter"
                checked={formData.receiveNewsletters}
                onChange={handleChange}
              />
            </Form.Group>

            <div
              style={{
                display: "flex",
                gap: "10px",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="outline-secondary"
                onClick={() => setShowModal(false)}
                style={buttonStyle}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                type="submit"
                style={{ ...buttonStyle, backgroundColor: "#2c3e50" }}
              >
                {selectedUser ? "Update User" : "Add User"}
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ManageUsers;
