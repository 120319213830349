import React, { useState, useEffect, useRef } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  useLocation,
} from "react-router-dom";
import { UserProvider, UserContext } from "./UserContext";
import ProductList from "./components/ProductList";
import Cart from "./components/Cart";
import ManageProducts from "./components/ManageProducts";
import ManageCategories from "./components/ManageCategories";
import CategoryList from "./components/CategoryList";
import LargeTopBanner from "./components/LargeTopBanner";
import FeatureSection from "./components/FeatureSection";
import Footer from "./components/Footer";
import CategoryView from "./components/CategoryView";
import ViewMoreButton from "./components/ViewMoreButton";
import ProductDetail from "./components/ProductDetail";
import SearchView from "./components/SearchView";
import Login from "./components/Login";
import Register from "./components/Register";
import Profile from "./components/Profile";
import ResetPassword from "./components/ResetPassword";
import NewPassword from "./components/NewPassword";
import Dashboard from "./components/Dashboard";
import TopSelling from "./components/TopSelling";
import UserDashboard from "./components/UserDashboard";
import axios from "axios";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHeart,
  faBars,
  faTimes,
  faUser,
  faShoppingCart,
} from "@fortawesome/free-solid-svg-icons";
import { Modal, Button } from "react-bootstrap";
import CartIcon from "./components/CartIcon";
import SendEmail from "./components/SendEmail";
import SMTPSettings from "./components/SMTPSettings";
import VerifyEmail from "./components/VerifyEmail";
import ManageUsers from "./components/ManageUsers";
import Wishlist from "./components/Wishlist";
import ManageFAQs from "./components/ManageFAQs";
import FAQList from "./components/FAQList";
import Settings from "./components/Settings";
import TermsConditions from "./components/TermsAndConditions";
import PrivacyPolicy from "./components/PrivacyPolicy";
import ReturnPolicy from "./components/ReturnPolicy";
import Search from "./components/Search";
import { Helmet } from "react-helmet";
import ManageDeliveryAddresses from "./components/ManageDeliveryAddresses";
import StoreLocationSettings from "./components/StoreLocationSettings";
import Payment from "./components/Payment";
import Checkout from "./components/Checkout";
import MpesaSettings from "./components/MpesaSettings";
import OrderList from "./components/OrderList";
import PaymentSuccess from "./components/PaymentSuccess";
import ManageOrderCategories from "./components/ManageOrderCategories";
import ManageCardPayments from "./components/ManageCardPayments";
import CardPaymentSuccess from "./components/CardPaymentSuccess";
import MyOrders from "./components/MyOrders";
import AdminLayout from "./components/AdminLayout";
import AdminLogin from "./components/AdminLogin";
import AdminResetPassword from "./components/AdminResetPassword";
import AdminNewPassword from "./components/AdminNewPassword";

// PublicLayout Component
const PublicLayout = ({ children }) => {
  return (
    <div className="App">
      <div className="nav-fixed">
        <div className="navbar-support-line bg-light d-none d-md-flex">
          <div className="container-fluid d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <span className="navbar-text me-3">
                NEED HELP? {children.props.settings?.phones}
              </span>
            </div>
            <div className="d-flex align-items-center support-links">
              <Link className="nav-link" to="/help-and-faqs">
                HELP & FAQs
              </Link>
              <Link className="nav-link" to="https://karibuhub.com/">
                GET A WEBSITE LIKE THIS
              </Link>
              <Link className="nav-link" to="/my-orders">
                TRACK ORDER
              </Link>
              <UserContext.Consumer>
                {({ user, logout }) =>
                  user ? (
                    <>
                      <Link
                        to="/dashboard"
                        style={{
                          textDecoration: "none",
                          display: "flex",
                          alignItems: "center",
                          padding: "8px 16px",
                          color: "#adb5bd",
                          transition: "color 0.3s ease",
                          ":hover": { color: "#fff" },
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faUser}
                          style={{
                            marginRight: "8px",
                            fontSize: "16px",
                          }}
                        />
                        <span style={{ fontSize: "14px", fontWeight: "500" }}>
                          {user.firstName}
                        </span>
                      </Link>
                      <Button
                        onClick={logout}
                        style={{
                          background: "none",
                          border: "none",
                          padding: "8px 16px",
                          color: "#adb5bd",
                          fontSize: "14px",
                          fontWeight: "500",
                          transition: "color 0.3s ease",
                          cursor: "pointer",
                          ":hover": { color: "#fff" },
                        }}
                      >
                        LOGOUT
                      </Button>
                    </>
                  ) : (
                    <Link
                      to="/login"
                      style={{
                        textDecoration: "none",
                        padding: "8px 16px",
                        color: "#adb5bd",
                        fontSize: "14px",
                        fontWeight: "500",
                        transition: "color 0.3s ease",
                        ":hover": { color: "#fff" },
                      }}
                    >
                      LOGIN
                    </Link>
                  )
                }
              </UserContext.Consumer>
            </div>
          </div>
        </div>
        <div className="divider"></div>
        <nav className="navbar navbar-expand-lg navbar-light main-nav">
          <div className="container-fluid d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center mobo">
              <button
                className="navbar-toggler d-md-none"
                type="button"
                onClick={children.props.toggleMenu}
                ref={children.props.menuButtonRef}
              >
                <FontAwesomeIcon
                  icon={children.props.menuOpen ? faTimes : faBars}
                />
              </button>
              <Link className="navbar-brand" to="/">
                <img
                  src={
                    process.env.REACT_APP_SERVER_URL + "/public/images/logo.png"
                  }
                  alt="Logo"
                  className="logo"
                />
              </Link>
              <div
                className="d-flex align-items-right d-md-none cart-icon-container"
                align="right"
              >
                <Link className="nav-link d-flex align-items-right" to="/cart">
                  <FontAwesomeIcon
                    icon={faShoppingCart}
                    className="custom-icon-cart"
                  />
                  {children.props.cartItems.length > 0 && (
                    <span className="badge badge-pill badge-danger cart-badge">
                      {children.props.cartItems.length}
                    </span>
                  )}
                </Link>
              </div>
            </div>
            <div className="d-flex justify-content-center flex-grow-1 mx-3">
              <SearchView />
            </div>
            <div className="d-flex align-items-center ms-auto">
              <div className="d-none d-md-flex align-items-center">
                <Link
                  className="nav-link d-flex flex-column align-items-center"
                  to="/wishlist"
                >
                  <FontAwesomeIcon
                    icon={faHeart}
                    className="custom-icon me-2"
                  />
                  <span className="wishlistText">Wishlist</span>
                </Link>
                <CartIcon cartItems={children.props.cartItems} />
              </div>
            </div>
          </div>
          <div className="divider"></div>
          <div
            ref={children.props.menuRef}
            className={`collapse navbar-collapse ${
              children.props.menuOpen ? "show" : ""
            } animated-menu`}
            id="navbarNav"
          >
            <ul className="navbar-nav ms-auto">
              <li className="nav-item d-md-none">
                <Link
                  className="nav-link top-item item-mobile"
                  to="/support"
                  onClick={() => children.props.toggleMenu()}
                >
                  SUPPORT
                </Link>
              </li>
              <li className="nav-item d-md-none">
                <Link
                  className="nav-link top-item item-mobile"
                  to="/my-orders"
                  onClick={() => children.props.toggleMenu()}
                >
                  TRACK ORDER
                </Link>
              </li>
              <li className="nav-item d-md-none item-mobile">
                <UserContext.Consumer>
                  {({ user, logout }) =>
                    user ? (
                      <>
                        <Link
                          className="nav-link d-flex flex-column align-items-left item-mobile"
                          to="/dashboard"
                          onClick={() => children.props.toggleMenu()}
                        >
                          <FontAwesomeIcon
                            icon={faUser}
                            className="custom-icon me-2"
                          />
                          <span className="item-mobile">{user.firstName}</span>
                        </Link>
                        <Button
                          variant="link"
                          onClick={() => {
                            logout();
                            children.props.toggleMenu();
                          }}
                          className="nav-link btn-logout item-mobile"
                        >
                          Logout
                        </Button>
                      </>
                    ) : (
                      <Link
                        className="nav-link top-item"
                        to="/login"
                        onClick={() => children.props.toggleMenu()}
                      >
                        LOGIN
                      </Link>
                    )
                  }
                </UserContext.Consumer>
              </li>
              <li className="nav-item d-md-none item-mobile">
                <Link
                  className="nav-link d-flex flex-column align-items-left"
                  to="/wishlist"
                  onClick={() => children.props.toggleMenu()}
                >
                  <FontAwesomeIcon
                    icon={faHeart}
                    className="custom-icon me-2"
                  />
                  <span className="item-mobile">Wishlist</span>
                </Link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
      <div className="container my-4 home-sec home-section">
        <div className="row home-section-2">
          <div className="divider1"></div>
          <ConditionalCategoryList />
          {children}
        </div>
      </div>
      <Footer />
    </div>
  );
};

// Main App Component
const App = () => {
  const [cartItems, setCartItems] = useState(() => {
    const savedCartItems = localStorage.getItem("cartItems");
    return savedCartItems ? JSON.parse(savedCartItems) : [];
  });

  const [menuOpen, setMenuOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [settings, setSettings] = useState({
    phones: "",
    currency: "",
  });

  const menuRef = useRef(null);
  const menuButtonRef = useRef(null);

  const clearCartItems = () => {
    setCartItems([]);
    localStorage.removeItem("cartItems");
  };

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/api/settings`
        );
        setSettings(response.data);
      } catch (error) {
        console.error("Error fetching settings:", error);
      }
    };

    fetchSettings();
  }, []);

  useEffect(() => {
    localStorage.setItem("cartItems", JSON.stringify(cartItems));
  }, [cartItems]);

  const handleAddToCart = (product, quantity) => {
    const existingItem = cartItems.find((item) => item.id === product.id);
    if (existingItem) {
      setCartItems(
        cartItems.map((item) =>
          item.id === product.id
            ? { ...item, quantity: item.quantity + quantity }
            : item
        )
      );
    } else {
      setCartItems([...cartItems, { ...product, quantity }]);
    }
  };

  const handleRemoveFromCart = (productId) => {
    setCartItems(cartItems.filter((item) => item.id !== productId));
  };

  const handleQuantityChange = (productId, quantity) => {
    setCartItems(
      cartItems.map((item) =>
        item.id === productId ? { ...item, quantity: quantity } : item
      )
    );
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleShowModal = (product) => {
    setSelectedProduct(product);
    setQuantity(1);
    setModalShow(true);
  };

  const handleModalSubmit = () => {
    handleAddToCart(selectedProduct, quantity);
    setModalShow(false);
  };

  const handleClickOutside = (event) => {
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target) &&
      menuButtonRef.current &&
      !menuButtonRef.current.contains(event.target)
    ) {
      setMenuOpen(false);
    }
  };

  useEffect(() => {
    if (menuOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuOpen]);

  // Combine all props
  const commonProps = {
    handleAddToCart,
    handleRemoveFromCart,
    cartItems,
    handleShowModal,
    handleQuantityChange,
    clearCartItems,
    settings,
    menuOpen,
    menuRef,
    menuButtonRef,
    toggleMenu,
  };

  return (
    <UserProvider>
      <Router>
        <Routes>
          {/* Admin Auth Routes */}
          <Route path="/admin/login" element={<AdminLogin />} />
          <Route
            path="/admin/reset-password"
            element={<AdminResetPassword />}
          />
          <Route path="/admin/new-password" element={<AdminNewPassword />} />

          {/* Admin Routes */}
          <Route path="/admin/*" element={<AdminLayout />}>
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="manage-categories" element={<ManageCategories />} />
            <Route path="manage-products" element={<ManageProducts />} />
            <Route path="smtp-settings" element={<SMTPSettings />} />
            <Route path="send-emails" element={<SendEmail />} />
            <Route path="manage-users" element={<ManageUsers />} />
            <Route
              path="store-location-settings"
              element={<StoreLocationSettings />}
            />
            <Route
              path="manage-delivery-addresses"
              element={<ManageDeliveryAddresses />}
            />
            <Route
              path="manage-order-categories"
              element={<ManageOrderCategories />}
            />
            <Route path="order-list" element={<OrderList />} />
            <Route path="manage-faqs" element={<ManageFAQs />} />
            <Route path="mpesa-settings" element={<MpesaSettings />} />
            <Route
              path="manage-card-payments"
              element={<ManageCardPayments />}
            />
            <Route path="settings" element={<Settings />} />
          </Route>

          {/* Public Routes */}
          <Route
            path="/*"
            element={
              <PublicLayout>
                <Routes {...commonProps}>
                  <Route path="/" element={<MainContent {...commonProps} />} />
                  <Route path="/dashboard" element={<UserDashboard />} />
                  <Route
                    path="/category/:categoryName"
                    element={<CategoryViewWrapper {...commonProps} />}
                  />

                  <Route
                    path="/category/:categoryName/subcategory/:subcategoryName"
                    element={<CategoryViewWrapper {...commonProps} />}
                  />
                  <Route
                    path="/product/:productId"
                    element={<ProductDetailWrapper {...commonProps} />}
                  />
                  <Route path="/cart" element={<Cart {...commonProps} />} />
                  <Route path="/checkout" element={<Checkout />} />
                  <Route
                    path="/payment"
                    element={<Payment clearCartItems={clearCartItems} />}
                  />
                  {/* Continuing from the previous routes */}
                  <Route path="/payment-success" element={<PaymentSuccess />} />
                  <Route
                    path="/card-payment-success"
                    element={
                      <CardPaymentSuccess clearCartItems={clearCartItems} />
                    }
                  />
                  <Route path="/login" element={<Login />} />
                  <Route path="/register" element={<Register />} />
                  <Route path="/reset-password" element={<ResetPassword />} />
                  <Route path="/new-password" element={<NewPassword />} />
                  <Route path="/verify-email" element={<VerifyEmail />} />
                  <Route path="/profile" element={<Profile />} />
                  <Route path="/wishlist" element={<Wishlist />} />
                  <Route path="/my-orders" element={<MyOrders />} />
                  <Route path="/help-and-faqs" element={<FAQList />} />
                  <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                  <Route
                    path="/terms-and-conditions"
                    element={<TermsConditions />}
                  />
                  <Route path="/return-policy" element={<ReturnPolicy />} />
                  <Route path="/search" element={<Search {...commonProps} />} />
                </Routes>
              </PublicLayout>
            }
          />
        </Routes>

        {/* Modal */}
        <Modal show={modalShow} onHide={() => setModalShow(false)}>
          <Modal.Header closeButton>
            <Modal.Title>{selectedProduct?.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedProduct && (
              <div className="text-center">
                <img
                  src={`${process.env.REACT_APP_SERVER_URL}${selectedProduct.images[0]}`}
                  alt={selectedProduct.name}
                  className="img-thumbnail mb-3"
                  style={{ maxHeight: "200px" }}
                />
                <p>
                  Price: {settings.currency} {selectedProduct.price}
                </p>
                <div className="d-flex justify-content-center align-items-center">
                  <button
                    className="btn btn-outline-secondary"
                    onClick={() => setQuantity((prev) => Math.max(1, prev - 1))}
                  >
                    -
                  </button>
                  <input
                    type="number"
                    value={quantity}
                    readOnly
                    className="form-control text-center mx-2"
                    style={{ width: "60px" }}
                  />
                  <button
                    className="btn btn-outline-secondary"
                    onClick={() => setQuantity((prev) => prev + 1)}
                  >
                    +
                  </button>
                </div>
                <p className="mt-3">
                  Total: {settings.currency} {selectedProduct.price * quantity}
                </p>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setModalShow(false)}>
              Close
            </Button>
            <Button variant="primary" onClick={handleModalSubmit}>
              Add to Cart
            </Button>
          </Modal.Footer>
        </Modal>
      </Router>
    </UserProvider>
  );
};

// Wrapper Components
const ConditionalCategoryList = () => {
  const location = useLocation();
  const showCategoryList = !(
    location.pathname.startsWith("/admin") ||
    location.pathname.startsWith("/category") ||
    location.pathname.startsWith("/product") ||
    location.pathname.startsWith("/manage-products") ||
    location.pathname.startsWith("/manage-categories") ||
    location.pathname.startsWith("/cart") ||
    location.pathname.startsWith("/send-emails") ||
    location.pathname.startsWith("/login") ||
    location.pathname.startsWith("/register") ||
    location.pathname.startsWith("/profile") ||
    location.pathname.startsWith("/reset-password") ||
    location.pathname.startsWith("/manage-users") ||
    location.pathname.startsWith("/new-password") ||
    location.pathname.startsWith("/verify-email") ||
    location.pathname.startsWith("/manage-faqs") ||
    location.pathname.startsWith("/help-and-faqs") ||
    location.pathname.startsWith("/wishlist") ||
    location.pathname.startsWith("/settings") ||
    location.pathname.startsWith("/privacy-policy") ||
    location.pathname.startsWith("/terms-and-conditions") ||
    location.pathname.startsWith("/return-policy") ||
    location.pathname.startsWith("/smtp-settings") ||
    location.pathname.startsWith("/search") ||
    location.pathname.startsWith("/checkout") ||
    location.pathname.startsWith("/manage-delivery-addresses") ||
    location.pathname.startsWith("/store-location-settings") ||
    location.pathname.startsWith("/payment") ||
    location.pathname.startsWith("/mpesa-settings") ||
    location.pathname.startsWith("/payment-success") ||
    location.pathname.startsWith("/manage-order-categories") ||
    location.pathname.startsWith("/manage-card-payments") ||
    location.pathname.startsWith("/card-payment-success") ||
    location.pathname.startsWith("/order-list") ||
    location.pathname.startsWith("/my-orders") ||
    location.pathname.startsWith("/dashboard")
  );

  return showCategoryList ? (
    <div className="col-md-3">
      <CategoryList />
    </div>
  ) : null;
};

const MainContent = (props) => {
  const [settings, setSettings] = useState({
    phones: "",
    currency: "",
    websiteName: "",
    baseUrl: "",
    keywords: "",
    siteDescription: "",
    serverUrl: "",
    logoUrl: "",
    shortDescription: "",
  });

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/api/settings`
        );
        setSettings(response.data);
      } catch (error) {
        console.error("Error fetching settings:", error);
      }
    };

    fetchSettings();
  }, []);

  return (
    <>
      <Helmet>
        <meta name="description" content={settings.siteDescription} />
        <meta name="keywords" content={settings.keywords} />
        <meta name="author" content={settings.websiteName} />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content={settings.websiteName} />
        <meta property="og:description" content={settings.siteDescription} />
        <meta
          property="og:image"
          content={`${settings.serverUrl}${settings.logoUrl}`}
        />
        <meta property="og:url" content={settings.baseUrl} />
        <meta property="og:type" content="website" />
        <title>
          {`${settings.websiteName} - ${settings.shortDescription?.replace(
            /<[^>]+>/g,
            ""
          )}`}
        </title>
      </Helmet>
      <div className="col-md-9">
        <Routes>
          <Route
            path="/"
            element={
              <LargeTopBanner
                onAddToCart={props.handleAddToCart}
                onRemoveFromCart={props.handleRemoveFromCart}
                cartItems={props.cartItems}
              />
            }
          />
        </Routes>
      </div>
      <div className="row features">
        <div className="col-md-12">
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <FeatureSection />
                  <ProductList
                    onAddToCart={props.handleAddToCart}
                    onRemoveFromCart={props.handleRemoveFromCart}
                    cartItems={props.cartItems}
                    handleShowModal={props.handleShowModal}
                  />
                  <ViewMoreButton />
                </>
              }
            />
          </Routes>
        </div>
      </div>
    </>
  );
};

const CategoryViewWrapper = (props) => {
  const location = useLocation();
  const colSize = location.pathname.includes("/category") ? "12" : "9";
  return (
    <div className={`col-md-${colSize}`}>
      <CategoryView
        handleAddToCart={props.handleAddToCart}
        handleRemoveFromCart={props.handleRemoveFromCart}
        cartItems={props.cartItems}
        handleShowModal={props.handleShowModal}
      />
    </div>
  );
};

const ProductDetailWrapper = (props) => (
  <div className="col-md-12">
    <ProductDetail
      handleAddToCart={props.handleAddToCart}
      handleRemoveFromCart={props.handleRemoveFromCart}
      cartItems={props.cartItems}
    />
  </div>
);

export default App;
