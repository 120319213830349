import React, { useState, useEffect } from "react";
import axios from "axios";
import { CreditCard, Key, Shield, Save, AlertCircle } from "lucide-react";

const ManageCardPayments = () => {
  // States
  const [publishableKey, setPublishableKey] = useState("");
  const [webhookChallenge, setWebhookChallenge] = useState("");
  const [status, setStatus] = useState({ type: "", message: "" });
  const [loading, setLoading] = useState(false);

  // Styles
  const cardStyle = {
    background: "white",
    borderRadius: "12px",
    padding: "24px",
    marginBottom: "24px",
    boxShadow: "0 2px 12px rgba(0,0,0,0.1)",
    border: "1px solid #eee",
  };

  const inputGroupStyle = {
    marginBottom: "20px",
  };

  const labelStyle = {
    textAlign: "left",
    display: "block",
    marginBottom: "8px",
    fontWeight: "500",
    color: "#2c3e50",
    fontSize: "14px",
  };

  const inputStyle = {
    width: "100%",
    padding: "12px",
    borderRadius: "8px",
    border: "1px solid #e0e0e0",
    fontSize: "14px",
    transition: "border-color 0.3s ease",
  };

  const buttonStyle = {
    padding: "12px 24px",
    borderRadius: "8px",
    border: "none",
    backgroundColor: "#2c3e50",
    color: "white",
    fontWeight: "500",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    gap: "8px",
    transition: "background 0.3s ease",
  };

  // Effects
  useEffect(() => {
    fetchCredentials();
  }, []);

  // API Calls
  const fetchCredentials = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/intasend/credentials`
      );
      if (response.data) {
        setPublishableKey(response.data.publishableKey || "");
        setWebhookChallenge(response.data.webhookChallenge || "");
      }
    } catch (error) {
      setStatus({
        type: "error",
        message: "Error fetching credentials",
      });
    } finally {
      setLoading(false);
    }
  };

  // Handlers
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/api/intasend/credentials`,
        {
          publishableKey,
          webhookChallenge,
        }
      );
      setStatus({
        type: "success",
        message: "Payment settings updated successfully",
      });
    } catch (error) {
      setStatus({
        type: "error",
        message: "Error updating payment settings",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ margin: "0 auto", padding: "20px 20px" }}>
      <div style={cardStyle}>
        {status.message && (
          <div
            style={{
              padding: "12px",
              borderRadius: "8px",
              marginBottom: "20px",
              backgroundColor:
                status.type === "success" ? "#e3fcef" : "#fee2e2",
              color: status.type === "success" ? "#059669" : "#dc2626",
              display: "flex",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <AlertCircle size={18} />
            {status.message}
          </div>
        )}

        <form onSubmit={handleSubmit}>
          <div style={inputGroupStyle}>
            <label style={labelStyle}>
              <Key
                size={16}
                style={{ marginRight: "8px", verticalAlign: "middle" }}
              />
              Publishable Key
            </label>
            <input
              type="text"
              value={publishableKey}
              onChange={(e) => setPublishableKey(e.target.value)}
              style={inputStyle}
              placeholder="Enter IntaSend publishable key"
              required
            />
          </div>

          <div style={inputGroupStyle}>
            <label style={labelStyle}>
              <Shield
                size={16}
                style={{ marginRight: "8px", verticalAlign: "middle" }}
              />
              Webhook Challenge
            </label>
            <input
              type="text"
              value={webhookChallenge}
              onChange={(e) => setWebhookChallenge(e.target.value)}
              style={inputStyle}
              placeholder="Enter webhook challenge string"
              required
            />
          </div>

          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <button type="submit" style={buttonStyle} disabled={loading}>
              <Save size={18} />
              {loading ? "Saving..." : "Save Settings"}
            </button>
          </div>
        </form>
      </div>

      <div
        style={{
          ...cardStyle,
          backgroundColor: "#f8fafc",
          borderStyle: "dashed",
        }}
      >
        <h3
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            color: "#2c3e50",
            marginBottom: "16px",
            fontSize: "1rem",
          }}
        >
          <AlertCircle size={20} />
          Important Information
        </h3>
        <p
          style={{
            color: "#4b5563",
            fontSize: "14px",
            lineHeight: "1.6",
            margin: 0,
          }}
        >
          These credentials are used to process card payments through IntaSend.
          Make sure to keep these credentials secure and never share them
          publicly.
        </p>
      </div>
    </div>
  );
};

export default ManageCardPayments;
