import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./css/SimilarProducts.css";
import { Helmet } from "react-helmet";
import { UserContext } from "../UserContext";

const Wishlist = () => {
  const { user } = useContext(UserContext);
  const [wishlistItems, setWishlistItems] = useState([]);
  const navigate = useNavigate();
  const [settings, setSettings] = useState({
    phones: "",
    currency: "",
    websiteName: "",
    baseUrl: "",
    keywords: "",
    siteDescription: "",
  });

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/api/settings`
        );
        setSettings(response.data);
      } catch (error) {
        console.error("Error fetching settings from the database:", error);
      }
    };

    fetchSettings();
  }, []);

  useEffect(() => {
    if (!user) {
      navigate("/login");
    } else {
      const fetchWishlist = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_SERVER_URL}/api/wishlist/${user.id}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );
          setWishlistItems(response.data);
        } catch (error) {
          console.error("Error fetching wishlist:", error);
        }
      };

      fetchWishlist();
    }
  }, [user, navigate]);

  const handleRemoveFromWishlist = async (productId) => {
    try {
      await axios.delete(`${process.env.REACT_APP_SERVER_URL}/api/wishlist`, {
        data: { userId: user.id, productId },
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      setWishlistItems(
        wishlistItems.filter((item) => item.productId !== productId)
      );
    } catch (error) {
      console.error("Error removing from wishlist:", error);
    }
  };

  if (!user) {
    return <div>Loading...</div>;
  }

  if (wishlistItems.length === 0) {
    return <p>Your wishlist is empty.</p>;
  }

  return (
    <div align="left" className="similar-products">
      <Helmet>
        <meta name="description" content={settings.siteDescription} />
        <meta name="keywords" content={settings.keywords} />
        <meta name="author" content={settings.websiteName} />
        <meta property="og:title" content={settings.websiteName} />
        <meta property="og:description" content={settings.siteDescription} />
        <meta
          property="og:image"
          content={`${settings.serverUrl}${settings.logoUrl}`}
        />
        <meta property="og:url" content={settings.baseUrl} />
        <meta property="og:type" content="website" />
        <title>{`Wishlist | ${settings.websiteName}`}</title>
      </Helmet>
      <h3 style={{ textAlign: "center", marginTop: "20px" }}>Your Wishlist</h3>

      <br />
      <div className="row">
        {wishlistItems.map((item) => (
          <div align="left" className="col-md-3" key={item.productId}>
            <div
              className="card mb-3"
              onClick={() => navigate(`/product/${item.productId}`)}
              style={{ cursor: "pointer" }}
            >
              <div className="position-relative">
                <img
                  src={`${process.env.REACT_APP_SERVER_URL}${item.Product.images[0]}`}
                  className="card-img-top"
                  alt={item.Product.name}
                />
              </div>
              <div align="left" className="card-body">
                <h5 className="card-title text-truncate wishlist-product-name">
                  {item.Product.name}
                </h5>
                <p className="product-condition">{item.Product.condition}</p>
                <p align="left" className="card-text">
                  <span className="price-new">
                    {settings.currency}{" "}
                    {Number(item.Product.price).toLocaleString()}
                  </span>
                  {item.Product.originalPrice &&
                    item.Product.originalPrice !== item.Product.price && (
                      <span className="price-old">
                        {settings.currency}{" "}
                        {Number(item.Product.originalPrice).toLocaleString()}
                      </span>
                    )}
                </p>
                <button
                  className="btn btn-danger center-content"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleRemoveFromWishlist(item.productId);
                  }}
                >
                  <span className="material-icons">close</span> Remove from
                  Wishlist
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Wishlist;
