import React, { useState, useEffect } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  BarChart,
  Bar,
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
} from "recharts";
import { Users, ShoppingCart, DollarSign, AlertCircle } from "lucide-react";

const Dashboard = () => {
  const [salesData, setSalesData] = useState(null);
  const [customerData, setCustomerData] = useState(null);
  const [productData, setProductData] = useState(null);
  const [orderData, setOrderData] = useState(null);
  const [paymentData, setPaymentData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedPeriod, setSelectedPeriod] = useState("monthly");
  const [currentPage, setCurrentPage] = useState(1);
  const [settings, setSettings] = useState({
    websiteName: "",
    currency: "",
    siteDescription: "",
    keywords: "",
    baseUrl: "",
    serverUrl: "",
    logoUrl: "",
  });

  const ITEMS_PER_PAGE = 5;
  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

  const cardStyle = {
    background: "white",
    borderRadius: "12px",
    padding: "16px",
    marginBottom: "16px",
    boxShadow: "0 2px 12px rgba(0,0,0,0.1)",
    border: "1px solid #eee",
  };

  const statCardStyle = {
    background: "white",
    borderRadius: "12px",
    padding: "16px",
    display: "flex",
    alignItems: "center",
    gap: "12px",
    boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
  };

  const iconContainerStyle = {
    minWidth: "48px",
    height: "48px",
    borderRadius: "12px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
  };

  const formatOrderStatus = (status) => {
    return status
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const getCurrentItems = () => {
    const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
    const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;
    return (
      productData?.lowStockProducts?.slice(indexOfFirstItem, indexOfLastItem) ||
      []
    );
  };

  const totalPages = Math.ceil(
    (productData?.lowStockProducts?.length || 0) / ITEMS_PER_PAGE
  );

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/api/settings`
        );
        setSettings(response.data);
      } catch (error) {
        console.error("Error fetching settings:", error);
      }
    };
    fetchSettings();
  }, []);

  useEffect(() => {
    const fetchDashboardData = async () => {
      setLoading(true);
      try {
        const [
          salesResponse,
          customerResponse,
          productResponse,
          orderResponse,
          paymentResponse,
        ] = await Promise.all([
          axios.get(
            `${process.env.REACT_APP_SERVER_URL}/api/dashboard/sales?period=${selectedPeriod}`
          ),
          axios.get(
            `${process.env.REACT_APP_SERVER_URL}/api/dashboard/customers`
          ),
          axios.get(
            `${process.env.REACT_APP_SERVER_URL}/api/dashboard/products`
          ),
          axios.get(`${process.env.REACT_APP_SERVER_URL}/api/dashboard/orders`),
          axios.get(
            `${process.env.REACT_APP_SERVER_URL}/api/dashboard/payments`
          ),
        ]);

        setSalesData(salesResponse.data);
        setCustomerData(customerResponse.data);
        setProductData(productResponse.data);
        setOrderData(orderResponse.data);
        setPaymentData(paymentResponse.data);
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchDashboardData();
  }, [selectedPeriod]);

  if (loading) {
    return <div style={{ padding: "20px" }}>Loading dashboard data...</div>;
  }

  return (
    <div style={{ padding: "16px", width: "100%", boxSizing: "border-box" }}>
      <Helmet>
        <meta name="description" content={settings.siteDescription} />
        <meta name="keywords" content={settings.keywords} />
        <meta name="author" content={settings.websiteName} />
        <meta property="og:title" content={settings.websiteName} />
        <meta property="og:description" content={settings.siteDescription} />
        <meta
          property="og:image"
          content={`${settings.serverUrl}${settings.logoUrl}`}
        />
        <meta property="og:url" content={settings.baseUrl} />
        <meta property="og:type" content="website" />
        <title>{`Dashboard | ${settings.websiteName}`}</title>
      </Helmet>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          marginBottom: "24px",
        }}
      >
        <select
          value={selectedPeriod}
          onChange={(e) => setSelectedPeriod(e.target.value)}
          style={{
            padding: "8px 16px",
            borderRadius: "8px",
            border: "1px solid #e0e0e0",
            width: "100%",
            maxWidth: "200px",
          }}
        >
          <option value="daily">Daily</option>
          <option value="weekly">Weekly</option>
          <option value="monthly">Monthly</option>
          <option value="yearly">Yearly</option>
        </select>
      </div>

      {/* Quick Stats */}
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
          gap: "16px",
          marginBottom: "24px",
        }}
      >
        <div style={statCardStyle}>
          <div style={{ ...iconContainerStyle, background: "#4CAF50" }}>
            <DollarSign size={24} />
          </div>
          <div>
            <h3 style={{ margin: 0, color: "#666", fontSize: "16px" }}>
              Revenue
            </h3>
            <p
              style={{
                margin: "4px 0 0 0",
                fontSize: "1.25rem",
                fontWeight: "bold",
              }}
            >
              {settings.currency}{" "}
              {salesData?.salesMetrics?.totalRevenue?.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) || "0.00"}
            </p>
          </div>
        </div>

        <div style={statCardStyle}>
          <div style={{ ...iconContainerStyle, background: "#2196F3" }}>
            <ShoppingCart size={24} />
          </div>
          <div>
            <h3 style={{ margin: 0, color: "#666", fontSize: "16px" }}>
              Orders
            </h3>
            <p
              style={{
                margin: "4px 0 0 0",
                fontSize: "1.25rem",
                fontWeight: "bold",
              }}
            >
              {salesData?.salesMetrics?.orderCount || "0"}
            </p>
          </div>
        </div>

        <div style={statCardStyle}>
          <div style={{ ...iconContainerStyle, background: "#9C27B0" }}>
            <Users size={24} />
          </div>
          <div>
            <h3 style={{ margin: 0, color: "#666", fontSize: "16px" }}>
              Customers
            </h3>
            <p
              style={{
                margin: "4px 0 0 0",
                fontSize: "1.25rem",
                fontWeight: "bold",
              }}
            >
              {customerData?.totalCustomers || "0"}
            </p>
          </div>
        </div>

        <div style={statCardStyle}>
          <div style={{ ...iconContainerStyle, background: "#F44336" }}>
            <AlertCircle size={24} />
          </div>
          <div>
            <h3 style={{ margin: 0, color: "#666", fontSize: "16px" }}>
              Low Stock
            </h3>
            <p
              style={{
                margin: "4px 0 0 0",
                fontSize: "1.25rem",
                fontWeight: "bold",
              }}
            >
              {productData?.lowStockProducts?.length || "0"}
            </p>
          </div>
        </div>
      </div>

      {/* Charts Section */}
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
          gap: "16px",
        }}
      >
        <div style={cardStyle}>
          <h2
            style={{
              margin: "0 0 16px 0",
              fontSize: "1.25rem",
              color: "#2c3e50",
            }}
          >
            Order Status
          </h2>
          <div style={{ width: "100%", height: "300px" }}>
            <ResponsiveContainer>
              <PieChart>
                <Pie
                  data={orderData?.map((item) => ({
                    ...item,
                    orderStatus: formatOrderStatus(item.orderStatus),
                    count: Number(item.count),
                  }))}
                  dataKey="count"
                  nameKey="orderStatus"
                  cx="50%"
                  cy="50%"
                  outerRadius={80}
                  fill="#8884d8"
                  label
                >
                  {orderData?.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>

        <div style={cardStyle}>
          <h2
            style={{
              margin: "0 0 16px 0",
              fontSize: "1.25rem",
              color: "#2c3e50",
            }}
          >
            Payment Methods
          </h2>
          <div style={{ width: "100%", height: "300px" }}>
            <ResponsiveContainer>
              <BarChart data={paymentData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="paymentMethod" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="count" fill="#8884d8" name="Payment Count" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>

        <div style={cardStyle}>
          <h2
            style={{
              margin: "0 0 16px 0",
              fontSize: "1.25rem",
              color: "#2c3e50",
            }}
          >
            Top Products
          </h2>
          <div style={{ width: "100%", height: "300px" }}>
            <ResponsiveContainer>
              <BarChart data={salesData?.topProducts}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="name"
                  angle={-45}
                  textAnchor="end"
                  height={80}
                  interval={0}
                />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="totalSold" fill="#82ca9d" name="Units Sold" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>

        <div style={cardStyle}>
          <h2
            style={{
              margin: "0 0 16px 0",
              fontSize: "1.25rem",
              color: "#2c3e50",
            }}
          >
            Customer Overview
          </h2>
          <div style={{ width: "100%", height: "300px" }}>
            <ResponsiveContainer>
              <PieChart>
                <Pie
                  data={[
                    {
                      name: "New Customers",
                      value: customerData?.newCustomers,
                    },
                    {
                      name: "Returning Customers",
                      value: customerData?.returningCustomersCount,
                    },
                  ]}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  outerRadius={80}
                  fill="#8884d8"
                  label
                >
                  <Cell fill="#00C49F" />
                  <Cell fill="#FFBB28" />
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>

      {/* Low Stock Products Table */}
      <div style={cardStyle}>
        <h2
          style={{
            margin: "0 0 16px 0",
            fontSize: "1.25rem",
            color: "#2c3e50",
          }}
        >
          Low Stock Products
        </h2>
        <div style={{ overflowX: "auto", WebkitOverflowScrolling: "touch" }}>
          <table
            style={{
              width: "100%",
              borderCollapse: "collapse",
              minWidth: "600px",
            }}
          >
            <thead>
              <tr style={{ backgroundColor: "#f8fafc" }}>
                <th
                  style={{
                    padding: "12px",
                    textAlign: "left",
                    borderBottom: "1px solid #e2e8f0",
                  }}
                >
                  Product Name
                </th>
                <th
                  style={{
                    padding: "12px",
                    textAlign: "left",
                    borderBottom: "1px solid #e2e8f0",
                  }}
                >
                  Category
                </th>
                <th
                  style={{
                    padding: "12px",
                    textAlign: "left",
                    borderBottom: "1px solid #e2e8f0",
                  }}
                >
                  Current Stock
                </th>
              </tr>
            </thead>
            <tbody>
              {getCurrentItems().map((product) => (
                <tr key={product.id}>
                  <td
                    style={{
                      padding: "12px",
                      borderBottom: "1px solid #e2e8f0",
                    }}
                  >
                    {product.name}
                  </td>
                  <td
                    style={{
                      padding: "12px",
                      borderBottom: "1px solid #e2e8f0",
                    }}
                  >
                    {product.category}
                  </td>
                  <td
                    style={{
                      padding: "12px",
                      borderBottom: "1px solid #e2e8f0",
                      color: product.stock < 5 ? "#ef4444" : "#f59e0b",
                    }}
                  >
                    {product.stock}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "16px",
              flexWrap: "wrap",
              gap: "8px",
              padding: "8px 0",
            }}
          >
            <button
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
              style={{
                padding: "8px 16px",
                border: "1px solid #e0e0e0",
                borderRadius: "8px",
                backgroundColor: "white",
                cursor: currentPage === 1 ? "not-allowed" : "pointer",
                opacity: currentPage === 1 ? 0.5 : 1,
                minWidth: "100px",
              }}
            >
              Previous
            </button>
            <span
              style={{
                fontSize: "14px",
                color: "#4a5568",
              }}
            >
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={() =>
                setCurrentPage((prev) => Math.min(prev + 1, totalPages))
              }
              disabled={currentPage === totalPages}
              style={{
                padding: "8px 16px",
                border: "1px solid #e0e0e0",
                borderRadius: "8px",
                backgroundColor: "white",
                cursor: currentPage === totalPages ? "not-allowed" : "pointer",
                opacity: currentPage === totalPages ? 0.5 : 1,
                minWidth: "100px",
              }}
            >
              Next
            </button>
          </div>
        </div>
      </div>

      <style jsx>{`
        @media (max-width: 768px) {
          .recharts-wrapper {
            width: 100% !important;
          }

          .recharts-legend-wrapper {
            position: relative !important;
            width: 100% !important;
            margin-top: 16px !important;
          }
        }
      `}</style>
    </div>
  );
};

export default Dashboard;
