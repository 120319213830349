import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Table, Form, Button, Pagination, Modal, Badge } from "react-bootstrap";
import { Helmet } from "react-helmet";
import moment from "moment";
import { UserContext } from "../UserContext";
import { Eye, Package, Calendar, MapPin, Clock, Search } from "lucide-react";

const MyOrders = () => {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const [settings, setSettings] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchOrderId, setSearchOrderId] = useState("");
  const [selectedCartItems, setSelectedCartItems] = useState([]);
  const [loadingUser, setLoadingUser] = useState(true);

  const ordersPerPage = 10;

  useEffect(() => {
    if (user === null && !loadingUser) {
      navigate("/login");
    } else if (user) {
      fetchSettings();
      fetchOrders();
    }
  }, [currentPage, searchOrderId, user, navigate, loadingUser]);

  useEffect(() => {
    if (user !== undefined) {
      setLoadingUser(false);
    }
  }, [user]);

  const fetchSettings = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/settings`
      );
      setSettings(response.data || {});
      window.scrollTo(0, 0);
    } catch (error) {
      console.error("Error fetching settings:", error);
    }
  };

  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case "delivered":
        return "#10b981"; // green
      case "shipping":
        return "#3b82f6"; // blue
      case "paid_waiting_confirmation":
        return "#6366f1"; // indigo
      default:
        return "#9ca3af"; // gray
    }
  };
  const fetchOrders = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/my-orders`,
        {
          params: {
            page: currentPage,
            limit: ordersPerPage,
            orderId: searchOrderId,
            userId: user.id,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setOrders(response.data.orders || []);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  const getStatusBadgeClass = (status) => {
    switch (status.toLowerCase()) {
      case "delivered":
        return "status-badge bg-success text-white";
      case "shipping":
        return "status-badge bg-info text-white";
      case "paid_waiting_confirmation":
        return "status-badge bg-primary text-white";
      default:
        return "status-badge bg-secondary text-white";
    }
  };

  const formatOrderId = (id) => {
    return String(id).padStart(8, "0");
  };

  const tableStyles = {
    container1: {
      overflowX: "auto",
      width: "100%",
      margin: "24px 0",
      borderRadius: "8px",
    },
    table1: {
      minWidth: "750px", // Minimum width before horizontal scroll
      background: "white",
      boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
      width: "100%",
    },
  };

  return (
    <div className="order-dashboard" style={{ marginTop: "20px" }}>
      <Helmet>
        <meta name="description" content={settings.siteDescription} />
        <meta name="keywords" content={settings.keywords} />
        <title>{`My Orders | ${settings.websiteName}`}</title>
      </Helmet>

      <div className="orders-container">
        <div
          className="dashboard-header"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "24px",
            padding: "16px",
            backgroundColor: "white",
            borderRadius: "8px",
            boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
          }}
        >
          <h2
            style={{
              fontSize: "1.2rem",
              fontWeight: 600,
              color: "#374151",
              margin: 0,
            }}
          >
            Orders
          </h2>
          <div
            style={{
              position: "relative",
              width: "300px",
            }}
          >
            <Search
              size={16}
              style={{
                position: "absolute",
                left: "12px",
                top: "50%",
                transform: "translateY(-50%)",
                color: "#6B7280",
              }}
            />
            <Form.Control
              type="text"
              placeholder="Search by Order ID"
              value={searchOrderId}
              onChange={(e) => setSearchOrderId(e.target.value)}
              style={{
                paddingLeft: "35px",
                paddingRight: "12px",
                height: "38px",
                borderRadius: "6px",
                border: "1px solid #E5E7EB",
                fontSize: "14px",
                width: "100%",
                transition: "all 0.2s",
              }}
            />
          </div>
        </div>
        <div className="orders-table-container" style={tableStyles.container1}>
          <Table className="orders-table table1" style={tableStyles.table1}>
            <thead>
              <tr>
                <th
                  style={{
                    textAlign: "center",
                    padding: "16px",
                    backgroundColor: "#f8fafc",
                    fontWeight: 600,
                    color: "#374151",
                  }}
                >
                  Order ID
                </th>
                <th
                  style={{
                    textAlign: "center",
                    padding: "16px",
                    backgroundColor: "#f8fafc",
                    fontWeight: 600,
                    color: "#374151",
                  }}
                >
                  Amount
                </th>
                <th
                  style={{
                    textAlign: "center",
                    padding: "16px",
                    backgroundColor: "#f8fafc",
                    fontWeight: 600,
                    color: "#374151",
                  }}
                >
                  Payment
                </th>
                <th
                  style={{
                    textAlign: "center",
                    padding: "16px",
                    backgroundColor: "#f8fafc",
                    fontWeight: 600,
                    color: "#374151",
                  }}
                >
                  Status
                </th>
                <th
                  style={{
                    textAlign: "center",
                    padding: "16px",
                    backgroundColor: "#f8fafc",
                    fontWeight: 600,
                    color: "#374151",
                  }}
                >
                  Date
                </th>
                <th
                  style={{
                    textAlign: "center",
                    padding: "16px",
                    backgroundColor: "#f8fafc",
                    fontWeight: 600,
                    color: "#374151",
                  }}
                >
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {orders.map((order) => (
                <tr
                  key={order.id}
                  style={{ borderBottom: "1px solid #e5e7eb" }}
                >
                  <td
                    style={{
                      textAlign: "center",
                      padding: "16px",
                      verticalAlign: "middle",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-flex",
                        alignItems: "center",
                        gap: "8px",
                        color: "#4b5563",
                      }}
                    >
                      <Package size={16} />#{formatOrderId(order.id)}
                    </div>
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      padding: "16px",
                      verticalAlign: "middle",
                      fontWeight: 500,
                      color: "#374151",
                    }}
                  >
                    {settings.currency} {order.totalAmount}
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      padding: "16px",
                      verticalAlign: "middle",
                      textTransform: "capitalize",
                      color: "#4b5563",
                    }}
                  >
                    {order.paymentMethod}
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      padding: "16px",
                      verticalAlign: "middle",
                    }}
                  >
                    <span
                      style={{
                        padding: "6px 12px",
                        borderRadius: "9999px",
                        fontSize: "0.875rem",
                        fontWeight: 500,
                        backgroundColor: getStatusColor(order.orderStatus),
                        color: "white",
                        display: "inline-block",
                      }}
                    >
                      {order.orderStatus}
                    </span>
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      padding: "16px",
                      verticalAlign: "middle",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-flex",
                        alignItems: "center",
                        gap: "8px",
                        color: "#4b5563",
                      }}
                    >
                      <Calendar size={16} />
                      {moment(order.createdAt).format("MMM DD, YYYY")}
                    </div>
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      padding: "16px",
                      verticalAlign: "middle",
                    }}
                  >
                    <Button
                      variant="link"
                      onClick={() => setSelectedCartItems(order.cartItems)}
                      style={{
                        color: "#2563eb",
                        display: "inline-flex",
                        alignItems: "center",
                        gap: "4px",
                        padding: "6px 12px",
                        borderRadius: "6px",
                        transition: "all 0.2s",
                        textDecoration: "none",
                        fontSize: "14px",
                      }}
                    >
                      <Eye size={16} /> View Details
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "8px",
            justifyContent: "center",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          {[...Array(totalPages).keys()].map((number) => (
            <Button
              key={number + 1}
              variant={
                currentPage === number + 1 ? "primary" : "outline-primary"
              }
              style={{
                minWidth: "40px",
                height: "40px",
                padding: "8px 12px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "4px",
                cursor: "pointer",
                transition: "all 0.2s ease",
              }}
              onClick={() => setCurrentPage(number + 1)}
            >
              {number + 1}
            </Button>
          ))}
        </div>
        <Modal
          show={selectedCartItems.length > 0}
          onHide={() => setSelectedCartItems([])}
          className="order-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title className="modal-title">Order Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table className="orders-table">
              <thead>
                <tr>
                  <th>Product</th>
                  <th style={{ textAlign: "center" }}>Quantity</th>
                  <th style={{ textAlign: "center" }}>Action</th>
                </tr>
              </thead>
              <tbody>
                {selectedCartItems.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "12px",
                        }}
                      >
                        <img
                          src={`${process.env.REACT_APP_SERVER_URL}${item.images[0]}`}
                          alt={item.name}
                          className="product-image"
                          style={{
                            width: "50px",
                            height: "50px",
                            objectFit: "cover",
                          }}
                        />
                        <span>{item.name}</span>
                      </div>
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        verticalAlign: "middle",
                      }}
                    >
                      {item.quantity}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        verticalAlign: "middle",
                      }}
                    >
                      <Button
                        variant="link"
                        href={`/product/${item.id}`}
                        target="_blank"
                        className="action-button view-button"
                      >
                        View Product
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default MyOrders;
