import React from "react";
import "./css/AgeFilter.css";
 
const AgeFilter = ({ onChange, age }) => {
  const handleAgeChange = (value) => {
    onChange("age", value);
  };

  return (
    <div align="left" className="age-filter">
      <h6>Condition</h6>
      <div className="button-group">
        <button
          className={`btn-filter ${age === "all" ? "selected" : ""}`}
          onClick={() => handleAgeChange("all")}
        >
          All
        </button>
        <button
          className={`btn-filter ${age === "Brand New" ? "selected" : ""}`}
          onClick={() => handleAgeChange("Brand New")}
        >
          New
        </button>
        <button
          className={`btn-filter ${age === "Used" ? "selected" : ""}`}
          onClick={() => handleAgeChange("Used")}
        >
          Used
        </button>
        <button
          className={`btn-filter ${age === "Refurbished" ? "selected" : ""}`}
          onClick={() => handleAgeChange("Refurbished")}
        >
          Refurbished
        </button>
      </div>
    </div>
  );
};

export default AgeFilter;
