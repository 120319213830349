import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import "./css/CategoryListFilter.css";

const CategoryListFilter = () => {
  const { categoryName, subcategoryName } = useParams();
  const [categories, setCategories] = useState([]);
  const [expandedCategories, setExpandedCategories] = useState({});

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/api/categories`
        );
        setCategories(response.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  // Set the current category as expanded if there's a subcategory selected
  useEffect(() => {
    if (categoryName && subcategoryName) {
      setExpandedCategories((prev) => ({
        ...prev,
        [categoryName]: true,
      }));
    }
  }, [categoryName, subcategoryName]);

  const toggleCategory = (categoryName, e) => {
    e.preventDefault();
    setExpandedCategories((prev) => ({
      ...prev,
      [categoryName]: !prev[categoryName],
    }));
  };

  return (
    <div className="category-list-filter" style={{ marginTop: "20px" }}>
      <h6>Categories</h6>
      <ul className="list-group list-group-category-filter">
        {categories.map((category) => {
          const validSubcategories = category.subcategories.filter(
            (subcategory) => subcategory.trim() !== ""
          );
          const hasSubcategories = validSubcategories.length > 0;
          const isExpanded = expandedCategories[category.name];
          const isActive = categoryName === category.name;

          return (
            <li
              key={category.id}
              className={`category-filter-list list-group-item ${
                isActive ? "active" : ""
              }`}
            >
              <div className="category-header">
                <Link to={`/category/${category.name}`}>{category.name}</Link>
                {hasSubcategories && (
                  <button
                    className="expand-button"
                    onClick={(e) => toggleCategory(category.name, e)}
                  >
                    {isExpanded ? "−" : "+"}
                  </button>
                )}
              </div>
              {hasSubcategories && isExpanded && (
                <ul className="list-group subcategory-list expanded">
                  {validSubcategories.map((subcategory, index) => (
                    <li
                      key={index}
                      className={`sub-subcategory-list list-group-item ${
                        subcategoryName === subcategory ? "active" : ""
                      }`}
                    >
                      <Link
                        to={`/category/${category.name}/subcategory/${subcategory}`}
                      >
                        {subcategoryName === subcategory ? (
                          subcategory
                        ) : (
                          <span>{subcategory}</span>
                        )}
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default CategoryListFilter;
