import React, { useState, useEffect } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import {
  Plus,
  X,
  Edit2,
  Trash2,
  Image as ImageIcon,
  Tag,
  Component as CategoriesIcon,
} from "lucide-react";

const ManageCategories = () => {
  const [categories, setCategories] = useState([]);
  const [name, setName] = useState("");
  const [subcategoryInput, setSubcategoryInput] = useState("");
  const [subcategoriesList, setSubcategoriesList] = useState([]);
  const [icon, setIcon] = useState(null);
  const [editingCategory, setEditingCategory] = useState(null);
  const [settings, setSettings] = useState({
    host: "",
    port: "",
    username: "",
    password: "",
    phones: "",
    currency: "",
    websiteName: "",
    baseUrl: "",
    keywords: "",
    siteDescription: "",
  });

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/api/settings`
        );
        setSettings(response.data || {});
      } catch (error) {
        console.error("Error fetching SMTP settings:", error);
      }
    };

    fetchSettings();
  }, []);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/api/categories`
        );
        setCategories(response.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };
    fetchCategories();
  }, []);

  const handleDeleteCategory = async (id) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_SERVER_URL}/api/categories/${id}`
      );
      setCategories(categories.filter((category) => category.id !== id));
    } catch (error) {
      console.error("Error deleting category:", error);
    }
  };
  const handleAddSubcategory = (e) => {
    e.preventDefault();
    if (subcategoryInput.trim()) {
      setSubcategoriesList([...subcategoriesList, subcategoryInput.trim()]);
      setSubcategoryInput("");
    }
  };

  const removeSubcategory = (index) => {
    setSubcategoriesList(subcategoriesList.filter((_, i) => i !== index));
  };

  const handleAddCategory = async () => {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("subcategories", subcategoriesList.join(","));
    if (icon) {
      formData.append("icon", icon);
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/categories`,
        formData
      );
      setCategories([...categories, response.data]);
      resetForm();
    } catch (error) {
      console.error("Error adding category:", error);
    }
  };

  const handleUpdateCategory = async () => {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("subcategories", subcategoriesList.join(","));
    if (icon) {
      formData.append("icon", icon);
    }

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/api/categories/${editingCategory?.id}`,
        formData
      );
      setCategories(
        categories.map((category) =>
          category.id === editingCategory?.id ? response.data : category
        )
      );
      resetForm();
    } catch (error) {
      console.error("Error updating category:", error);
    }
  };

  const handleEditClick = (category) => {
    setEditingCategory(category);
    setName(category.name);
    setSubcategoriesList(category.subcategories);
    setIcon(null);
  };

  const resetForm = () => {
    setName("");
    setSubcategoriesList([]);
    setSubcategoryInput("");
    setIcon(null);
    setEditingCategory(null);
  };

  const cardStyle = {
    background: "white",
    borderRadius: "12px",
    padding: "24px",
    marginBottom: "24px",
    boxShadow: "0 2px 12px rgba(0,0,0,0.1)",
    border: "1px solid #eee",
  };

  const chipStyle = {
    backgroundColor: "#e9ecef",
    borderRadius: "16px",
    padding: "4px 12px",
    margin: "4px",
    display: "inline-flex",
    alignItems: "center",
    gap: "6px",
    fontSize: "14px",
  };

  return (
    <div style={{ margin: "20px" }}>
      <Helmet>
        <title>{`Manage Categories | ${settings.websiteName}`}</title>
      </Helmet>

      {/* Add/Edit Form */}
      <div style={cardStyle}>
        <h2
          style={{
            fontSize: "1.5rem",
            marginBottom: "24px",
            color: "#2c3e50",
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          {editingCategory ? <Edit2 size={24} /> : <Plus size={24} />}
          {editingCategory ? "Edit Category" : "Add New Category"}
        </h2>

        <div style={{ marginBottom: "20px" }}>
          <label
            style={{
              display: "block",
              marginBottom: "8px",
              fontWeight: "500",
              color: "#2c3e50",
              textAlign: "left",
            }}
          >
            Category Name
          </label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            style={{
              width: "100%",
              padding: "12px",
              borderRadius: "8px",
              border: "1px solid #dee2e6",
              fontSize: "14px",
            }}
            placeholder="Enter category name"
          />
        </div>

        <div style={{ marginBottom: "20px" }}>
          <label
            style={{
              display: "block",
              marginBottom: "8px",
              fontWeight: "500",
              color: "#2c3e50",
              textAlign: "left",
            }}
          >
            Subcategories
          </label>
          <div style={{ display: "flex", gap: "10px", marginBottom: "10px" }}>
            <input
              type="text"
              value={subcategoryInput}
              onChange={(e) => setSubcategoryInput(e.target.value)}
              onKeyPress={(e) => e.key === "Enter" && handleAddSubcategory(e)}
              style={{
                flex: 1,
                padding: "12px",
                borderRadius: "8px",
                border: "1px solid #dee2e6",
                fontSize: "14px",
              }}
              placeholder="Type and press Enter to add subcategory"
            />
            <button
              onClick={handleAddSubcategory}
              style={{
                padding: "12px 24px",
                borderRadius: "8px",
                border: "none",
                backgroundColor: "#2c3e50",
                color: "white",
                cursor: "pointer",
              }}
            >
              Add
            </button>
          </div>
          <div style={{ display: "flex", flexWrap: "wrap", gap: "8px" }}>
            {subcategoriesList.map((subcat, index) => (
              <div key={index} style={chipStyle}>
                <Tag size={14} />
                {subcat}
                <button
                  onClick={() => removeSubcategory(index)}
                  style={{
                    border: "none",
                    background: "none",
                    padding: "0 4px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <X size={14} />
                </button>
              </div>
            ))}
          </div>
        </div>

        <div style={{ marginBottom: "20px" }}>
          <label
            style={{
              display: "block",
              marginBottom: "8px",
              fontWeight: "500",
              color: "#2c3e50",
              textAlign: "left",
            }}
          >
            Category Icon
          </label>
          <input
            type="file"
            onChange={(e) => setIcon(e.target.files?.[0] || null)}
            style={{
              width: "100%",
              padding: "12px",
              borderRadius: "8px",
              border: "1px solid #dee2e6",
              fontSize: "14px",
            }}
          />
        </div>

        <div
          style={{ display: "flex", gap: "10px", justifyContent: "flex-end" }}
        >
          {editingCategory && (
            <button
              onClick={resetForm}
              style={{
                padding: "12px 24px",
                borderRadius: "8px",
                border: "1px solid #dee2e6",
                backgroundColor: "white",
                cursor: "pointer",
              }}
            >
              Cancel
            </button>
          )}
          <button
            onClick={editingCategory ? handleUpdateCategory : handleAddCategory}
            style={{
              padding: "12px 24px",
              borderRadius: "8px",
              border: "none",
              backgroundColor: "#2c3e50",
              color: "white",
              cursor: "pointer",
            }}
          >
            {editingCategory ? "Update Category" : "Add Category"}
          </button>
        </div>
      </div>

      {/* Categories List */}
      <div style={cardStyle}>
        <h2
          style={{
            fontSize: "1.5rem",
            marginBottom: "24px",
            color: "#2c3e50",
          }}
        >
          Categories
        </h2>

        <div style={{ display: "grid", gap: "16px" }}>
          {categories.map((category) => (
            <div
              key={category.id}
              style={{
                padding: "16px",
                borderRadius: "8px",
                border: "1px solid #dee2e6",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
              }}
            >
              <div
                style={{ display: "flex", gap: "16px", alignItems: "center" }}
              >
                {category.icon && (
                  <img
                    src={`${process.env.REACT_APP_SERVER_URL}${category.icon}`}
                    alt={category.name}
                    style={{
                      width: "40px",
                      height: "40px",
                      objectFit: "cover",
                      borderRadius: "8px",
                    }}
                  />
                )}
                <div>
                  <h3
                    style={{
                      margin: "0 0 8px 0",
                      fontSize: "16px",
                      fontWeight: "500",
                      textAlign: "left",
                    }}
                  >
                    {category.name}
                  </h3>
                  <h4
                    style={{
                      color: "grey",
                      margin: "0 0 8px 0",
                      fontSize: "12px",
                      fontWeight: "500",
                      textAlign: "left",
                    }}
                  >
                    Sub categories
                  </h4>
                  <div
                    style={{ display: "flex", flexWrap: "wrap", gap: "4px" }}
                  >
                    {category.subcategories.map((subcat, index) => (
                      <span key={index} style={chipStyle}>
                        {subcat}
                      </span>
                    ))}
                  </div>
                </div>
              </div>

              <div style={{ display: "flex", gap: "8px" }}>
                <button
                  onClick={() => handleEditClick(category)}
                  style={{
                    padding: "8px",
                    borderRadius: "8px",
                    border: "1px solid #dee2e6",
                    backgroundColor: "white",
                    cursor: "pointer",
                  }}
                >
                  <Edit2 size={16} />
                </button>
                <button
                  onClick={() => handleDeleteCategory(category.id)}
                  style={{
                    padding: "8px",
                    borderRadius: "8px",
                    border: "1px solid #ffcdd2",
                    backgroundColor: "#ffebee",
                    color: "#d32f2f",
                    cursor: "pointer",
                  }}
                >
                  <Trash2 size={16} />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ManageCategories;
