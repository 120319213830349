import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./css/SimilarProducts.css";
import AddToCartModal from "./AddToCartModal";
import axios from "axios";

const SimilarProducts = ({
  products,
  onRemoveFromCart,
  cartItems,
  handleAddToCart,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const navigate = useNavigate();
  const [settings, setSettings] = useState({
    phones: "",
    currency: "",
  });

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/api/settings`
        );
        setSettings(response.data);
      } catch (error) {
        console.error("Error fetching settings from the database:", error);
      }
    };

    fetchSettings();
  }, []);
  const handleAddToCartClick = (product) => {
    setSelectedProduct(product);
    setShowModal(true);
  };

  const scrollToTopAndNavigate = (url) => {
    window.scrollTo(0, 0); // Scroll to top
    navigate(url); // Navigate to the specified URL
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleModalSave = (product, quantity) => {
    if (quantity > 0 && quantity <= product.stock) {
      handleAddToCart(product, quantity);
    } else {
      alert("Invalid quantity");
    }
    setShowModal(false);
  };

  if (products.length === 0) {
    return <p>No similar products found</p>;
  }

  return (
    <div className="row" align="left">
      {products.map((product) => {
        const inCart = cartItems.some((item) => item.id === product.id);
        return (
          <div className="col-md-3" key={product.id}>
            <div
              className="card mb-3"
              onClick={() => scrollToTopAndNavigate(`/product/${product.id}`)}
              style={{ cursor: "pointer" }}
            >
              <div className="position-relative">
                <img
                  src={`${process.env.REACT_APP_SERVER_URL}${product.images[0]}`}
                  className="card-img-top"
                  alt={product.name}
                />
                {product.discount > 0 && (
                  <span className="badge badge-discount position-absolute top-0 end-0">
                    -{Math.ceil(product.discount)}%
                  </span>
                )}
              </div>
              <div className="card-body">
                <h5 className="card-title text-truncate">{product.name}</h5>
                <p className="product-condition">{product.condition}</p>
                <p className="card-text">
                  <span className="price-new">
                    {settings.currency} {Number(product.price).toLocaleString()}
                  </span>
                  {product.originalPrice &&
                    product.originalPrice !== product.price && (
                      <span className="price-old">
                        {settings.currency}{" "}
                        {Number(product.originalPrice).toLocaleString()}
                      </span>
                    )}
                </p>
                {product.stock === 0 ? (
                  <p className="out-of-stock-text" style={{ color: "red" }}>
                    <b>Out of stock</b>
                  </p>
                ) : inCart ? (
                  <button
                    className="btn btn-danger center-content"
                    onClick={(e) => {
                      e.stopPropagation();
                      onRemoveFromCart(product.id);
                    }}
                  >
                    <span className="material-icons">remove_shopping_cart</span>{" "}
                    Remove from Cart
                  </button>
                ) : (
                  <button
                    className="btn btn-primary center-content"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleAddToCartClick(product);
                    }}
                  >
                    <span className="material-icons">add_shopping_cart</span>{" "}
                    Add to Cart
                  </button>
                )}
              </div>
            </div>
          </div>
        );
      })}
      <AddToCartModal
        show={showModal}
        handleClose={handleModalClose}
        handleSave={handleModalSave}
        product={selectedProduct}
      />
    </div>
  );
};

export default SimilarProducts;
