import React, { useEffect, useState } from "react";
import axios from "axios";
import ProductForm from "./ProductForm";
import {
  Modal,
  Button,
  Pagination,
  Alert,
  Form,
  InputGroup,
} from "react-bootstrap";
import { Helmet } from "react-helmet";
import {
  Search,
  AlertTriangle,
  FileEdit,
  Trash2,
  Plus,
  Package as ProductIcon,
} from "lucide-react";
import "./css/ManageProducts.css";

const ManageProducts = () => {
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [categories, setCategories] = useState([]);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterCategory, setFilterCategory] = useState("");
  const [lowStockAlert, setLowStockAlert] = useState(false);
  const [sortBy, setSortBy] = useState("name");
  const [sortOrder, setSortOrder] = useState("asc");
  const [isLoading, setIsLoading] = useState(false);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);
  const [selectedImage, setSelectedImage] = useState(null);

  const itemsPerPage = 10;
  const [settings, setSettings] = useState({
    phones: "",
    currency: "",
    websiteName: "",
    baseUrl: "",
    keywords: "",
    siteDescription: "",
    lowStockThreshold: "",
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 500);

    return () => clearTimeout(timer);
  }, [searchTerm]);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/api/settings`
        );
        setSettings(response.data);
      } catch (error) {
        console.error("Error fetching settings:", error);
      }
    };
    fetchSettings();
  }, []);

  useEffect(() => {
    fetchProducts();
    fetchCategories();
  }, [currentPage, debouncedSearchTerm, filterCategory, sortBy, sortOrder]);

  const fetchProducts = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/products`,
        {
          params: {
            page: currentPage,
            limit: itemsPerPage,
            search: debouncedSearchTerm,
            category: filterCategory,
            sortBy: sortBy,
            sortOrder: sortOrder,
          },
        }
      );
      if (Array.isArray(response.data.products)) {
        setProducts(response.data.products);
        setTotalPages(response.data.totalPages);
        checkLowStock(response.data.products);
      } else {
        setError("No products found");
        setProducts([]);
      }
    } catch (error) {
      setError(error.message);
      setProducts([]);
    } finally {
      setIsLoading(false);
    }
  };

  const checkLowStock = (products) => {
    const hasLowStock = products.some(
      (product) => product.stock <= settings.lowStockThreshold
    );
    setLowStockAlert(hasLowStock);
  };

  const fetchCategories = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/categories`
      );
      if (Array.isArray(response.data)) {
        setCategories(response.data);
      } else {
        setCategories([]);
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
      setCategories([]);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this product?")) {
      try {
        await axios.delete(
          `${process.env.REACT_APP_SERVER_URL}/api/products/${id}`
        );
        fetchProducts();
      } catch (error) {
        setError(error.message);
      }
    }
  };

  const getStockStatusClass = (stock) => {
    if (stock <= settings.lowStockThreshold) return "bg-danger text-white";
    if (stock <= settings.lowStockThreshold * 1.25) return "bg-warning";
    return "bg-success text-white";
  };

  const clearFilters = () => {
    setSearchTerm("");
    setFilterCategory("");
    setSortBy("name");
    setSortOrder("asc");
    setCurrentPage(1);
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  return (
    <div className="manage-products-container">
      <Helmet>
        <meta name="description" content={settings.siteDescription} />
        <meta name="keywords" content={settings.keywords} />
        <meta name="author" content={settings.websiteName} />
        <title>{`Manage Products | ${settings.websiteName}`}</title>
      </Helmet>

      <div className="header-section mb-4">
        {lowStockAlert && (
          <Alert variant="warning" className="d-flex align-items-center">
            <AlertTriangle className="me-2" />
            Some products are running low on stock!
          </Alert>
        )}
      </div>

      <div className="controls-section mb-4">
        <div className="row g-3">
          <div className="col-md-4">
            <InputGroup style={{ height: "38px" }}>
              <InputGroup.Text
                style={{
                  height: "38px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Search size={20} />
              </InputGroup.Text>
              <Form.Control
                type="text"
                placeholder="Search products..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{ height: "38px" }}
              />
            </InputGroup>
          </div>

          <div className="col-md-2">
            <Form.Select
              value={filterCategory}
              onChange={(e) => setFilterCategory(e.target.value)}
              style={{ height: "38px" }}
            >
              <option value="">All Categories</option>
              {categories.map((category) => (
                <option key={category.id} value={category.name}>
                  {category.name}
                </option>
              ))}
            </Form.Select>
          </div>

          <div className="col-md-2">
            <Form.Select
              value={`${sortBy}-${sortOrder}`}
              onChange={(e) => {
                const [newSortBy, newSortOrder] = e.target.value.split("-");
                setSortBy(newSortBy);
                setSortOrder(newSortOrder);
              }}
              style={{ height: "38px" }}
            >
              <option value="name-asc">Name (A-Z)</option>
              <option value="name-desc">Name (Z-A)</option>
              <option value="price-asc">Price (Low-High)</option>
              <option value="price-desc">Price (High-Low)</option>
              <option value="stock-asc">Stock (Low-High)</option>
              <option value="stock-desc">Stock (High-Low)</option>
            </Form.Select>
          </div>

          <div className="col-md-2">
            <Button
              variant="outline-secondary"
              onClick={clearFilters}
              className="w-100"
              disabled={
                !searchTerm &&
                !filterCategory &&
                sortBy === "name" &&
                sortOrder === "asc"
              }
              style={{
                height: "38px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Clear Filters
            </Button>
          </div>

          <div className="col-md-2">
            <Button
              variant="primary"
              onClick={() => setShowModal(true)}
              className="w-100"
              style={{
                height: "38px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Plus size={20} className="me-2" />
              Add Product
            </Button>
          </div>
        </div>
      </div>

      <div className="table-responsive">
        <table className="table table-hover product-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Price</th>
              <th>Original Price</th>
              <th>Stock</th>
              <th>Category</th>
              <th>Images</th>
              <th>Views</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan="8" className="text-center p-4">
                  <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </td>
              </tr>
            ) : products.length === 0 ? (
              <tr>
                <td colSpan="8" className="text-center p-4">
                  No products found
                </td>
              </tr>
            ) : (
              products.map((product) => (
                <tr key={product.id}>
                  <td>{product.name}</td>
                  <td>
                    {settings.currency} {Number(product.price).toLocaleString()}
                  </td>
                  <td>
                    {product.originalPrice && (
                      <del className="text-muted">
                        {settings.currency}{" "}
                        {Number(product.originalPrice).toLocaleString()}
                      </del>
                    )}
                  </td>
                  <td>
                    <span
                      className={`badge ${getStockStatusClass(
                        product.stock
                      )} stock-badge`}
                    >
                      {product.stock} in stock
                    </span>
                  </td>
                  <td>{product.category}</td>
                  <td>
                    <div className="product-images">
                      {(product.images || []).map((image, index) => (
                        <div
                          key={index}
                          className="product-image-container"
                          onClick={() => handleImageClick(image)}
                        >
                          <img
                            src={`${process.env.REACT_APP_SERVER_URL}${image}`}
                            alt={`${product.name} ${index + 1}`}
                            className="product-image"
                          />
                        </div>
                      ))}
                    </div>
                  </td>
                  <td>
                    <span className="badge bg-info">
                      {product.viewCount} views
                    </span>
                  </td>
                  <td>
                    <div className="action-buttons">
                      <Button
                        variant="outline-primary"
                        size="sm"
                        onClick={() => {
                          setSelectedProduct(product);
                          setShowModal(true);
                        }}
                        className="me-2"
                      >
                        <FileEdit size={16} />
                      </Button>
                      <Button
                        variant="outline-danger"
                        size="sm"
                        onClick={() => handleDelete(product.id)}
                      >
                        <Trash2 size={16} />
                      </Button>
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      <div className="d-flex justify-content-center mt-4">
        <Pagination>
          <Pagination.First onClick={() => setCurrentPage(1)} />
          <Pagination.Prev
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
          />
          {[...Array(totalPages).keys()].map((number) => (
            <Pagination.Item
              key={number + 1}
              active={number + 1 === currentPage}
              onClick={() => setCurrentPage(number + 1)}
            >
              {number + 1}
            </Pagination.Item>
          ))}
          <Pagination.Next
            onClick={() =>
              setCurrentPage((prev) => Math.min(prev + 1, totalPages))
            }
          />
          <Pagination.Last onClick={() => setCurrentPage(totalPages)} />
        </Pagination>
      </div>

      <Modal
        show={showModal}
        onHide={() => {
          setShowModal(false);
          setSelectedProduct(null);
        }}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {selectedProduct ? "Edit Product" : "Add Product"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ProductForm
            product={selectedProduct}
            onSuccess={() => {
              fetchProducts();
              setShowModal(false);
              setSelectedProduct(null);
            }}
            categories={categories}
          />
        </Modal.Body>
      </Modal>

      {selectedImage && (
        <div
          className="image-preview-overlay"
          onClick={() => setSelectedImage(null)}
        >
          <div className="image-preview-container">
            <img
              src={`${process.env.REACT_APP_SERVER_URL}${selectedImage}`}
              alt="Preview"
              className="image-preview"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ManageProducts;
