import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./css/CategoryList.css";

const CategoryList = () => {
  const [categories, setCategories] = useState([]);
  const [expandedCategory, setExpandedCategory] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/api/categories`
        );
        setCategories(response.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };
    fetchCategories();
  }, []);

  const toggleCategory = (categoryId) => {
    setExpandedCategory((prevState) =>
      prevState === categoryId ? null : categoryId
    );
  };

  const handleCategoryClick = (category) => {
    navigate(`/category/${category.name}`);
  };

  const handleSubcategoryClick = (category, subcategory) => {
    navigate(`/category/${category.name}/subcategory/${subcategory}`);
  };

  return (
    <div className="category-list">
      <ul className="list-group">
        {categories.map((category) => (
          <li key={category.id} className="list-group-item category">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div
                align="left"
                style={{
                  display: "flex",
                  alignItems: "left",
                  cursor: "pointer",
                }}
                onClick={() => handleCategoryClick(category)}
              >
                {category.icon && (
                  <img
                    src={`${process.env.REACT_APP_SERVER_URL}${category.icon}`}
                    alt={category.name}
                    style={{
                      width: "30px",
                      height: "30px",
                      marginRight: "10px",
                      objectFit: "contain",
                    }}
                  />
                )}
                {category.name}
              </div>
              {category.subcategories.filter(
                (subcategory) => subcategory.trim() !== ""
              ).length > 0 && (
                <span
                  className="material-icons"
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleCategory(category.id);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {expandedCategory === category.id
                    ? "expand_more"
                    : "chevron_right"}
                </span>
              )}
            </div>
            {expandedCategory === category.id && (
              <ul className="list-group mt-2 subcategory-list expanded">
                {category.subcategories
                  .filter((subcategory) => subcategory.trim() !== "")
                  .map((subcategory, index) => (
                    <li
                      align="left"
                      key={`${category.id}-${index}`}
                      className="list-group-item subcategory-item"
                      onClick={() =>
                        handleSubcategoryClick(category, subcategory)
                      }
                      style={{ paddingLeft: "34px" }} // Added padding to align with category name
                    >
                      {subcategory}
                    </li>
                  ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CategoryList;
