import React, { useState, useEffect } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import {
  Mail,
  Clock,
  Users,
  Send,
  Edit2,
  Trash2,
  CheckCircle,
  AlertCircle,
} from "lucide-react";

const SendEmail = () => {
  // States
  const [settings, setSettings] = useState({
    websiteName: "",
    siteDescription: "",
    keywords: "",
    baseUrl: "",
  });

  const [emailData, setEmailData] = useState({
    subject: "",
    message: "",
    recipients: "",
  });

  const [schedule, setSchedule] = useState(false);
  const [scheduleDate, setScheduleDate] = useState("");
  const [sendToAll, setSendToAll] = useState(false);
  const [emails, setEmails] = useState([]);
  const [subscribers, setSubscribers] = useState([]);
  const [editEmail, setEditEmail] = useState(null);
  const [loading, setLoading] = useState(false);

  // Styles
  const cardStyle = {
    background: "white",
    borderRadius: "12px",
    padding: "24px",
    marginBottom: "24px",
    boxShadow: "0 2px 12px rgba(0,0,0,0.1)",
    border: "1px solid #eee",
  };

  const inputStyle = {
    padding: "12px",
    borderRadius: "8px",
    border: "1px solid #e0e0e0",
    fontSize: "14px",
    width: "100%",
    marginBottom: "16px",
  };

  const buttonStyle = {
    padding: "12px 24px",
    borderRadius: "8px",
    border: "none",
    backgroundColor: "#2c3e50",
    color: "white",
    fontWeight: "500",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    gap: "8px",
    transition: "background 0.3s ease",
  };

  const tableStyle = {
    width: "100%",
    borderCollapse: "separate",
    borderSpacing: "0",
    borderRadius: "8px",
    overflow: "hidden",
  };

  // Effects
  useEffect(() => {
    fetchSettings();
    fetchEmails();
    fetchSubscribers();
  }, []);

  // API Calls
  const fetchSettings = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/settings`
      );
      setSettings(response.data || {});
    } catch (error) {
      console.error("Error fetching settings:", error);
    }
  };

  const fetchEmails = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/email-queue`
      );
      setEmails(response.data);
    } catch (error) {
      console.error("Error fetching emails:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchSubscribers = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/subscribers`
      );
      setSubscribers(response.data);
    } catch (error) {
      console.error("Error fetching subscribers:", error);
    }
  };

  // Handlers
  const handleChange = (e) => {
    const { name, value } = e.target;
    setEmailData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSendEmail = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const emailPayload = {
        ...emailData,
        recipients: sendToAll
          ? "ALL"
          : emailData.recipients.split(",").map((email) => email.trim()),
        schedule: schedule ? scheduleDate : null,
      };

      await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/email/send`,
        emailPayload
      );
      alert("Email scheduled successfully.");
      fetchEmails();
      resetForm();
    } catch (error) {
      console.error("Error sending email:", error);
      alert("Failed to send email.");
    } finally {
      setLoading(false);
    }
  };

  const resetForm = () => {
    setEmailData({
      subject: "",
      message: "",
      recipients: "",
    });
    setSchedule(false);
    setScheduleDate("");
    setSendToAll(false);
  };

  const handleEditClick = (email) => {
    setEditEmail(email);
    setScheduleDate(email.scheduleTime);
  };

  const handleUpdateEmail = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/api/email-queue/${editEmail.id}`,
        { scheduleTime: scheduleDate }
      );
      alert("Email schedule updated successfully.");
      setEditEmail(null);
      setScheduleDate("");
      fetchEmails();
    } catch (error) {
      console.error("Error updating email schedule:", error);
      alert("Failed to update email schedule.");
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteEmail = async (id) => {
    if (!window.confirm("Are you sure you want to delete this email?")) return;

    setLoading(true);
    try {
      await axios.delete(
        `${process.env.REACT_APP_SERVER_URL}/api/email-queue/${id}`
      );
      alert("Email deleted successfully.");
      fetchEmails();
    } catch (error) {
      console.error("Error deleting email:", error);
      alert("Failed to delete email.");
    } finally {
      setLoading(false);
    }
  };

  const handleSubscriberStatusChange = async (id, status) => {
    try {
      await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/api/subscribers/${id}`,
        { status }
      );
      fetchSubscribers();
    } catch (error) {
      console.error("Error updating subscriber status:", error);
      alert("Failed to update subscriber status.");
    }
  };

  return (
    <div style={{ margin: "0 auto", padding: "20px 20px" }}>
      <Helmet>
        <title>{`Send Email | ${settings.websiteName}`}</title>
        <meta name="description" content={settings.siteDescription} />
        <meta name="keywords" content={settings.keywords} />
      </Helmet>

      {/* Compose Email Section */}
      <div style={cardStyle}>
        <h2
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            color: "#2c3e50",
            marginBottom: "24px",
          }}
        >
          <Mail size={24} />
          Compose Email
        </h2>

        <form onSubmit={handleSendEmail}>
          <div style={{ marginBottom: "20px" }}>
            <label
              style={{
                textAlign: "left",
                display: "block",
                marginBottom: "8px",
                fontWeight: "500",
              }}
            >
              Subject
            </label>
            <input
              type="text"
              name="subject"
              value={emailData.subject}
              onChange={handleChange}
              style={inputStyle}
              required
            />
          </div>

          <div style={{ marginBottom: "20px" }}>
            <label
              style={{
                textAlign: "left",
                display: "block",
                marginBottom: "8px",
                fontWeight: "500",
              }}
            >
              Message
            </label>
            <textarea
              name="message"
              value={emailData.message}
              onChange={handleChange}
              rows="5"
              style={inputStyle}
              required
            />
          </div>

          <div style={{ marginBottom: "20px" }}>
            <label
              style={{
                textAlign: "left",
                display: "block",
                marginBottom: "8px",
                fontWeight: "500",
              }}
            >
              Recipients
            </label>
            <input
              type="text"
              name="recipients"
              value={emailData.recipients}
              onChange={handleChange}
              disabled={sendToAll}
              style={inputStyle}
              placeholder="Enter email addresses separated by commas"
              required={!sendToAll}
            />
          </div>

          <div
            style={{
              display: "flex",
              gap: "20px",
              marginBottom: "20px",
            }}
          >
            <label
              style={{
                textAlign: "left",
                display: "flex",
                alignItems: "center",
                gap: "8px",
                cursor: "pointer",
              }}
            >
              <input
                type="checkbox"
                checked={sendToAll}
                onChange={(e) => setSendToAll(e.target.checked)}
              />
              Send to all subscribers
            </label>

            <label
              style={{
                textAlign: "left",
                display: "flex",
                alignItems: "center",
                gap: "8px",
                cursor: "pointer",
              }}
            >
              <input
                type="checkbox"
                checked={schedule}
                onChange={(e) => setSchedule(e.target.checked)}
              />
              Schedule for later
            </label>
          </div>

          {schedule && (
            <div style={{ marginBottom: "20px" }}>
              <label
                style={{
                  textAlign: "left",
                  display: "block",
                  marginBottom: "8px",
                  fontWeight: "500",
                }}
              >
                Schedule Date and Time
              </label>
              <input
                type="datetime-local"
                value={scheduleDate}
                onChange={(e) => setScheduleDate(e.target.value)}
                style={inputStyle}
                required
              />
            </div>
          )}

          <button type="submit" style={buttonStyle} disabled={loading}>
            <Send size={18} />
            {loading ? "Sending..." : "Send Email"}
          </button>
        </form>
      </div>

      {/* Email Queue Section */}
      <div style={cardStyle}>
        <h2
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            color: "#2c3e50",
            marginBottom: "24px",
          }}
        >
          <Clock size={24} />
          Email Queue
        </h2>

        <div style={{ overflowX: "auto" }}>
          <table style={tableStyle}>
            <thead>
              <tr style={{ backgroundColor: "#f8f9fa" }}>
                <th style={{ padding: "12px", textAlign: "left" }}>Subject</th>
                <th style={{ padding: "12px", textAlign: "left" }}>
                  Recipients
                </th>
                <th style={{ padding: "12px", textAlign: "left" }}>Status</th>
                <th style={{ padding: "12px", textAlign: "left" }}>Schedule</th>
                <th style={{ padding: "12px", textAlign: "right" }}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td
                    colSpan="5"
                    style={{ padding: "20px", textAlign: "left" }}
                  >
                    Loading...
                  </td>
                </tr>
              ) : emails.length === 0 ? (
                <tr>
                  <td
                    colSpan="5"
                    style={{ padding: "20px", textAlign: "left" }}
                  >
                    No emails in queue
                  </td>
                </tr>
              ) : (
                emails.map((email) => (
                  <tr key={email.id} style={{ borderBottom: "1px solid #eee" }}>
                    <td style={{ padding: "12px", textAlign: "left" }}>
                      {email.subject}
                    </td>
                    <td style={{ padding: "12px", textAlign: "left" }}>
                      {Array.isArray(email.recipients)
                        ? email.recipients.join(", ")
                        : "All Subscribers"}
                    </td>
                    <td style={{ padding: "12px", textAlign: "left" }}>
                      <span
                        style={{
                          padding: "4px 12px",
                          borderRadius: "12px",
                          fontSize: "12px",
                          backgroundColor:
                            email.status === "sent"
                              ? "#e3fcef"
                              : email.status === "scheduled"
                              ? "#fff7ed"
                              : "#fee2e2",
                          color:
                            email.status === "sent"
                              ? "#059669"
                              : email.status === "scheduled"
                              ? "#d97706"
                              : "#dc2626",
                        }}
                      >
                        {email.status}
                      </span>
                    </td>
                    <td style={{ padding: "12px", textAlign: "left" }}>
                      {email.scheduleTime || "Immediate"}
                    </td>
                    <td style={{ padding: "12px", textAlign: "left" }}>
                      {email.status === "scheduled" && (
                        <div
                          style={{
                            display: "flex",
                            gap: "8px",
                            justifyContent: "flex-end",
                          }}
                        >
                          <button
                            onClick={() => handleEditClick(email)}
                            style={{
                              padding: "8px",
                              borderRadius: "6px",
                              border: "1px solid #e0e0e0",
                              background: "white",
                              cursor: "pointer",
                            }}
                          >
                            <Edit2 size={16} />
                          </button>
                          <button
                            onClick={() => handleDeleteEmail(email.id)}
                            style={{
                              padding: "8px",
                              borderRadius: "6px",
                              border: "1px solid #fee2e2",
                              background: "#fff5f5",
                              cursor: "pointer",
                            }}
                          >
                            <Trash2 size={16} color="#dc2626" />
                          </button>
                        </div>
                      )}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>

      {/* Subscribers Section */}
      <div style={cardStyle}>
        <h2
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            color: "#2c3e50",
            marginBottom: "24px",
          }}
        >
          <Users size={24} />
          Subscribers
        </h2>

        <div style={{ overflowX: "auto" }}>
          <table style={tableStyle}>
            <thead>
              <tr style={{ backgroundColor: "#f8f9fa" }}>
                <th style={{ padding: "12px", textAlign: "left" }}>Email</th>
                <th style={{ padding: "12px", textAlign: "left" }}>Status</th>
                <th style={{ padding: "12px", textAlign: "left" }}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {subscribers.map((subscriber) => (
                <tr
                  key={subscriber.id}
                  style={{ borderBottom: "1px solid #eee" }}
                >
                  <td style={{ padding: "12px", textAlign: "left" }}>
                    {subscriber.email}
                  </td>
                  <td style={{ padding: "12px", textAlign: "left" }}>
                    <span
                      style={{
                        padding: "4px 12px",
                        borderRadius: "12px",
                        fontSize: "12px",
                        backgroundColor:
                          subscriber.status === "subscribed"
                            ? "#e3fcef"
                            : "#fee2e2",
                        color:
                          subscriber.status === "subscribed"
                            ? "#059669"
                            : "#dc2626",
                      }}
                    >
                      {subscriber.status}
                    </span>
                  </td>
                  <td style={{ padding: "12px", textAlign: "left" }}>
                    <button
                      onClick={() =>
                        handleSubscriberStatusChange(
                          subscriber.id,
                          subscriber.status === "subscribed"
                            ? "unsubscribed"
                            : "subscribed"
                        )
                      }
                      style={{
                        padding: "8px 16px",
                        borderRadius: "6px",
                        border: "1px solid #e0e0e0",
                        background: "white",
                        cursor: "pointer",
                        fontSize: "14px",
                      }}
                    >
                      {subscriber.status === "subscribed"
                        ? "Unsubscribe"
                        : "Subscribe"}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Edit Email Modal */}
      {editEmail && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 1000,
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              padding: "24px",
              borderRadius: "12px",
              width: "90%",
              maxWidth: "500px",
            }}
          >
            <h3 style={{ marginBottom: "20px", color: "#2c3e50" }}>
              Edit Schedule
            </h3>
            <form onSubmit={handleUpdateEmail}>
              <div style={{ marginBottom: "20px" }}>
                <label
                  style={{
                    textAlign: "left",
                    display: "block",
                    marginBottom: "8px",
                    fontWeight: "500",
                  }}
                >
                  Schedule Date and Time
                </label>
                <input
                  type="datetime-local"
                  value={scheduleDate}
                  onChange={(e) => setScheduleDate(e.target.value)}
                  style={inputStyle}
                  required
                />
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  justifyContent: "flex-end",
                }}
              >
                <button
                  type="button"
                  onClick={() => setEditEmail(null)}
                  style={{
                    ...buttonStyle,
                    backgroundColor: "white",
                    color: "#2c3e50",
                    border: "1px solid #e0e0e0",
                  }}
                >
                  Cancel
                </button>
                <button type="submit" style={buttonStyle} disabled={loading}>
                  {loading ? "Updating..." : "Update Schedule"}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default SendEmail;
