import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './css/SearchView.css';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SearchView = () => {
  const [query, setQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const navigate = useNavigate();

  const fetchSuggestions = async (searchTerm) => {
    if (searchTerm.length > 0) {
      try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/search/suggestions?q=${searchTerm}`);
        setSuggestions(response.data);
      } catch (error) {
        console.error('Error fetching suggestions:', error);
      }
    } else {
      setSuggestions([]);
    }
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    setQuery(value);
    fetchSuggestions(value);
  };

  const handleSuggestionClick = async (suggestion) => {
    setQuery(suggestion.name);
    setSuggestions([]);
    // Navigate to the category with the search query for the exact product name
    navigate(`/category/${suggestion.categoryName}?search=${suggestion.name}`);
  };

  return (
    <div className="search-view search-bar">
      <div className="input-group">
        <span className="search-icon">
          <FontAwesomeIcon icon={faSearch} />
        </span>
        <input
          type="search"
          className="form-control search-input"
          value={query}
          onChange={handleInputChange}
          placeholder="What are you looking for?"
        />
        {suggestions.length > 0 && (
          <ul align="left" className="suggestions-list">
            {suggestions.map((suggestion, index) => (
              <li
                key={index}
                className="suggestion-item"
                onClick={() => handleSuggestionClick(suggestion)}
                dangerouslySetInnerHTML={{ __html: `${suggestion.name} in <b>${suggestion.categoryName}</b>` }}
              />
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default SearchView;
