import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import "./css/Payment.css";
import { UserContext } from "../UserContext";
import { Helmet } from "react-helmet";
import mpesaLogo from "./assets/mpesa-logo.png"; // Ensure you have the logo in the specified path

const Payment = ({ clearCartItems }) => {
  const { user, setUser } = useContext(UserContext);
  const location = useLocation();
  const navigate = useNavigate();
  const {
    totalAmount,
    productCost,
    deliveryMethod,
    deliveryCost,
    deliveryAddress,
    deliveryCoordinates,
    deliveryInstructions,
    cartItems,
  } = location.state || {};
  const [paymentMethod, setPaymentMethod] = useState("mpesa");
  const [mpesaNumber, setMpesaNumber] = useState("");
  const [loadingUser, setLoadingUser] = useState(true);
  const [billingDetails, setBillingDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    city: "",
    address: "",
    zipCode: "",
    state: "",
  });

  const [settings, setSettings] = useState({
    host: "",
    port: "",
    username: "",
    password: "",
    phones: "",
    currency: "",
    websiteName: "",
    baseUrl: "",
    keywords: "",
    siteDescription: "",
  });

  const handleBillingChange = (e) => {
    const { name, value } = e.target;
    setBillingDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const [loading, setLoading] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("");

  useEffect(() => {
    if (user === null && !loadingUser) {
      navigate("/login");
    } else if (user) {
      let phone = user.phone.trim().replace(" ", "").replace("+254", "");
      if (phone.startsWith("0")) {
        phone = "254" + phone.substring(1);
      } else if (!phone.startsWith("254")) {
        phone = "254" + phone;
      }
      setMpesaNumber(phone);

      setBillingDetails({
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        city: "",
        address: "",
        zipCode: "",
        state: "",
      });
    }
  }, [user, navigate, loadingUser]);

  useEffect(() => {
    const fetchUserProfile = async () => {
      const token = localStorage.getItem("token");
      if (token) {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_SERVER_URL}/api/profile`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
          setUser(response.data);
        } catch (error) {
          console.error("Error fetching profile", error);
        }
      }
      setLoadingUser(false);
    };

    if (user === undefined) {
      fetchUserProfile();
    } else {
      setLoadingUser(false);
    }
  }, [setUser, user]);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/api/settings`
        );
        setSettings(response.data || {});
        window.scrollTo(0, 0);
      } catch (error) {
        console.error("Error fetching settings:", error);
      }
    };

    fetchSettings();
  }, []);

  const handlePaymentMethodChange = (e) => {
    setPaymentMethod(e.target.value);
  };

  const handleMpesaNumberChange = (e) => {
    setMpesaNumber(e.target.value);
  };

  const handlePayment = async () => {
    try {
      if (paymentMethod === "mpesa") {
        if (!mpesaNumber) {
          alert("Please enter your Mpesa number.");
          return;
        }

        let phone = mpesaNumber.trim().replace(" ", "").replace("+254", "");
        if (phone.startsWith("0")) {
          phone = "254" + phone.substring(1);
        } else if (!phone.startsWith("254")) {
          phone = "254" + phone;
        }

        setLoading(true);
        setPaymentStatus("Waiting for payment... please enter your PIN.");

        const response = await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/api/payment/mpesa`,
          {
            amount: totalAmount,
            mpesaNumber: phone,
            userId: user.id,
            productCost: productCost,
            deliveryMethod: deliveryMethod,
            deliveryCost: deliveryCost,
            deliveryAddress: deliveryAddress,
            deliveryCoordinates: deliveryCoordinates,
            deliveryInstructions: deliveryInstructions,
            cartItems: cartItems,
            ref: "Invoice123",
          }
        );

        const merchantRequestID = response.data.MerchantRequestID;
        const checkPaymentStatus = async () => {
          try {
            const statusResponse = await axios.get(
              `${process.env.REACT_APP_SERVER_URL}/api/payment/status`,
              {
                params: { ref: merchantRequestID },
              }
            );

            if (statusResponse.data.status === "success") {
              const insertId = statusResponse.data.insertId;
              const orderNumber = String(insertId).padStart(8, "0");

              setPaymentStatus("Payment successful.");
              clearCartItems();
              setLoading(false);
              setAlertMessage("Payment received successfully.");
              setAlertType("success");

              navigate("/payment-success", {
                replace: true,
                state: { orderNumber },
              });
            } else if (statusResponse.data.status === "cancelled") {
              setPaymentStatus("You cancelled the STK payment prompt.");
              setLoading(false);
              setAlertMessage("You cancelled the STK payment prompt.");
              setAlertType("warning");
            } else if (statusResponse.data.status === "failed") {
              setPaymentStatus("Payment failed.");
              setLoading(false);
              setAlertMessage("Payment failed.");
              setAlertType("danger");
            } else {
              setTimeout(checkPaymentStatus, 3000);
            }
          } catch (error) {
            console.error("Error checking payment status:", error);
            setPaymentStatus("Payment failed.");
            setLoading(false);
            setAlertMessage("Payment failed.");
            setAlertType("danger");
          }
        };

        setTimeout(checkPaymentStatus, 3000);
      } else if (paymentMethod === "card") {
        /*setLoading(true);*/
        const response = await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/api/payment/card`,
          {
            amount: totalAmount,
            userId: user.id,
            productCost: productCost,
            deliveryMethod: deliveryMethod,
            deliveryCost: deliveryCost,
            deliveryAddress: deliveryAddress,
            deliveryCoordinates: deliveryCoordinates,
            deliveryInstructions: deliveryInstructions,
            cartItems: cartItems,
            firstName: billingDetails.firstName,
            lastName: billingDetails.lastName,
            email: billingDetails.email,
            city: billingDetails.city,
            address: billingDetails.address,
            zipCode: billingDetails.zipCode,
            state: billingDetails.state,
          }
        );

        const paymentLink = response.data.paymentLink;
        window.location.href = paymentLink;
      }
    } catch (error) {
      console.error("Error processing payment:", error);
      alert("Payment failed.");
      setLoading(false);
      setAlertMessage("Payment failed.");
      setAlertType("danger");
    }
  };

  if (loadingUser) {
    return <div>Loading...</div>;
  }
  return (
    <div className="payment-container container" align="left">
      <Helmet>
        <meta name="description" content={settings.siteDescription} />
        <meta name="keywords" content={settings.keywords} />
        <meta name="author" content={settings.websiteName} />
        <meta property="og:title" content={settings.websiteName} />
        <meta property="og:description" content={settings.siteDescription} />
        <meta
          property="og:image"
          content={`${settings.serverUrl}${settings.logoUrl}`}
        />
        <meta property="og:url" content={settings.baseUrl} />
        <meta property="og:type" content="website" />
        <title>{`Payment methods | ${settings.websiteName}`}</title>
      </Helmet>
      {loading && (
        <div className="fullscreen-loader">
          <div className="loader-content">
            <p>Waiting for payment... please enter your PIN.</p>
          </div>
        </div>
      )}
      <>
        {alertMessage && (
          <div className={`alert alert-${alertType}`} role="alert">
            {alertMessage}
          </div>
        )}
        <h3 className="payment-title">Payment</h3>
        <div className="form-group">
          <label className="payment-input-label">
            <b>Select Payment Method</b>
          </label>
          <div className="form-group select-container">
            <select
              className="form-control form-control1"
              value={paymentMethod}
              onChange={handlePaymentMethodChange}
            >
              <option value="mpesa">Mpesa</option>
              <option value="card">Card</option>
            </select>
          </div>
        </div>
        {paymentMethod === "mpesa" && (
          <div className="form-group">
            <img src={mpesaLogo} alt="Mpesa Logo" className="mpesa-logo" />
            <label className="payment-input-label">
              <b>Mpesa Number</b>
            </label>
            <input
              type="text"
              className="form-control form-control1"
              value={mpesaNumber}
              onChange={handleMpesaNumberChange}
            />
          </div>
        )}
        {paymentMethod === "card" && (
          <div>
            <div className="form-group">
              <label>First Name</label>
              <input
                type="text"
                className="form-control form-control1"
                name="firstName"
                value={billingDetails.firstName}
                onChange={handleBillingChange}
              />
              <label>Last Name</label>
              <input
                type="text"
                className="form-control form-control1"
                name="lastName"
                value={billingDetails.lastName}
                onChange={handleBillingChange}
              />
              <label>Email</label>
              <input
                type="email"
                className="form-control form-control1"
                name="email"
                value={billingDetails.email}
                onChange={handleBillingChange}
              />
              <label>City</label>
              <input
                type="text"
                className="form-control form-control1"
                name="city"
                value={billingDetails.city}
                onChange={handleBillingChange}
              />
              <label>Address</label>
              <input
                type="text"
                className="form-control form-control1"
                name="address"
                value={billingDetails.address}
                onChange={handleBillingChange}
              />
              <label>Zip Code</label>
              <input
                type="text"
                className="form-control form-control1"
                name="zipCode"
                value={billingDetails.zipCode}
                onChange={handleBillingChange}
              />
              <label>State</label>
              <input
                type="text"
                className="form-control form-control1"
                name="state"
                value={billingDetails.state}
                onChange={handleBillingChange}
              />
            </div>
          </div>
        )}

        <button
          align="left"
          className="payment-submit btn btn-primary mt-3"
          onClick={handlePayment}
        >
          Make Payment
        </button>
        <br />
        <div align="left" className="total-amount">
          <b>
            Total Amount: {settings.currency}{" "}
            {Number(totalAmount).toLocaleString()}
          </b>
        </div>
        <div align="left" className="total-amount-sub">
          Product(s) Cost: {settings.currency}{" "}
          {Number(productCost).toLocaleString()}
        </div>
        <div align="left" className="total-amount-sub">
          Delivery Cost: {settings.currency}{" "}
          {Number(deliveryCost).toLocaleString()}
        </div>
        <br />
        <div align="left" className="total-amount-sub">
          Delivery Method: {deliveryMethod}
        </div>
        <div align="left" className="total-amount-sub">
          Delivery Address: {deliveryAddress} ({deliveryCoordinates})
        </div>
        <div align="left" className="total-amount-sub">
          Delivery Contact: {user.firstName} {user.lastName} ({user.phone}){" "}
          <a className="payment-link" href="/profile">
            Edit
          </a>
        </div>
        <br />
        <div align="left" className="total-amount-sub">
          <b>Delivery Instructions:</b>
          <br /> {deliveryInstructions}{" "}
        </div>
        <div align="left" className="product-list product-list1">
          <h5>
            <b>Products</b>
          </h5>
          <ul className="cart-list list-group" align="left">
            {cartItems.map((item) => (
              <li
                key={item.id}
                className="cart-list-item list-group-item d-flex flex-column flex-md-row align-items-center"
              >
                <div className="cart-item-image mb-3 mb-md-0">
                  <img
                    src={
                      item.images && item.images[0]
                        ? `${process.env.REACT_APP_SERVER_URL}${item.images[0]}`
                        : "default-image-path"
                    }
                    alt={item.name}
                    className="img-fluid"
                    style={{
                      width: "100px",
                      height: "100px",
                      objectFit: "cover",
                    }}
                  />
                </div>
                <div className="cart-item-details ms-md-3 mb-3 mb-md-0 text-center text-md-start">
                  <h4 className="cart-item-name my-0">{item.name}</h4>
                  <div className="d-flex justify-content-center justify-content-md-start align-items-center mt-2">
                    Quantity: {item.quantity}
                  </div>
                  <small className="cart-item-stock text-muted mt-2">
                    {settings.currency}{" "}
                    {Number(item.price * item.quantity).toLocaleString()}
                  </small>
                </div>
                <div className="ms-md-auto d-flex flex-column align-items-center align-items-md-end"></div>
              </li>
            ))}
          </ul>
        </div>
      </>
    </div>
  );
};

export default Payment;
