import React, { useState, useEffect } from "react";
import axios from "axios";
import { Modal, Button, Table, Form, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
import moment from "moment";
import {
  Search,
  Filter,
  Eye,
  Edit2,
  User,
  Calendar,
  Package,
  RefreshCw,
} from "lucide-react";

const OrderList = () => {
  // State Management
  const [orders, setOrders] = useState([]);
  const [customers, setCustomers] = useState({});
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedCartItems, setSelectedCartItems] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [newStatus, setNewStatus] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [settings, setSettings] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [filters, setFilters] = useState({
    searchOrderId: "",
    searchName: "",
    searchPhone: "",
    searchEmail: "",
    dateFilter: "",
    fromDate: "",
    toDate: "",
  });
  const [showFilters, setShowFilters] = useState(false);

  const ordersPerPage = 10;

  // Initial data fetching
  useEffect(() => {
    fetchSettings();
    fetchOrders();
    fetchStatuses();
  }, [currentPage, filters]);

  // Fetch settings
  const fetchSettings = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/settings`
      );
      setSettings(response.data || {});
    } catch (error) {
      console.error("Error fetching settings:", error);
      setAlertMessage("Failed to load settings");
    }
  };

  // Fetch orders
  const fetchOrders = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/orders`,
        {
          params: {
            page: currentPage,
            limit: ordersPerPage,
            ...Object.fromEntries(
              Object.entries(filters).filter(([_, value]) => value !== "")
            ),
          },
        }
      );

      const ordersData = response.data.orders || [];
      setOrders(ordersData);
      setTotalPages(response.data.totalPages);

      if (ordersData.length === 0 && currentPage > 1) {
        setCurrentPage(1);
      }

      // Fetch customers for the orders
      if (ordersData.length > 0) {
        const userIds = [...new Set(ordersData.map((order) => order.userId))];
        const customerPromises = userIds.map((userId) =>
          axios.get(
            `${process.env.REACT_APP_SERVER_URL}/api/customers/${userId}`
          )
        );

        const customersData = await Promise.all(customerPromises);
        const customersMap = {};
        customersData.forEach((response) => {
          const customer = response.data;
          customersMap[customer.id] = customer;
        });

        setCustomers(customersMap);
      }
    } catch (error) {
      console.error("Error fetching orders:", error);
      setAlertMessage("Failed to load orders");
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch order statuses
  const fetchStatuses = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/order-statuses`
      );
      setStatuses(response.data || []);
    } catch (error) {
      console.error("Error fetching order statuses:", error);
      setAlertMessage("Failed to load order statuses");
    }
  };

  // Handle order status change
  const handleOrderStatusChange = async (orderId) => {
    try {
      await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/api/orders/${orderId}/status`,
        {
          status: newStatus,
        }
      );
      setOrders((prev) =>
        prev.map((order) =>
          order.id === orderId ? { ...order, orderStatus: newStatus } : order
        )
      );
      setSelectedOrderId(null);
      setAlertMessage("Order status updated successfully");
      setTimeout(() => setAlertMessage(""), 3000);
    } catch (error) {
      console.error("Error updating order status:", error);
      setAlertMessage("Error updating order status");
    }
  };

  // Filter handlers
  const handleFilterChange = (name, value) => {
    setFilters((prev) => {
      // If changing date filter, clear custom date range
      if (name === "dateFilter" && value !== "") {
        return {
          ...prev,
          [name]: value,
          fromDate: "",
          toDate: "",
        };
      }

      // If setting custom date range, clear date filter
      if ((name === "fromDate" || name === "toDate") && value !== "") {
        return {
          ...prev,
          [name]: value,
          dateFilter: "",
        };
      }

      return {
        ...prev,
        [name]: value,
      };
    });
    setCurrentPage(1);
  };

  useEffect(() => {
    if (filters.fromDate && filters.toDate) {
      const fromDate = new Date(filters.fromDate);
      const toDate = new Date(filters.toDate);

      if (fromDate > toDate) {
        setAlertMessage("From date cannot be later than To date");
        setFilters((prev) => ({
          ...prev,
          fromDate: "",
          toDate: "",
        }));
      }
    }
  }, [filters.fromDate, filters.toDate]);

  const clearFilters = () => {
    setFilters({
      searchOrderId: "",
      searchName: "",
      searchPhone: "",
      searchEmail: "",
      dateFilter: "",
      fromDate: "",
      toDate: "",
    });
    setCurrentPage(1);
  };

  // Status color helper
  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case "delivered":
        return "#10b981"; // green
      case "shipping":
        return "#3b82f6"; // blue
      case "paid_waiting_confirmation":
        return "#6366f1"; // indigo
      default:
        return "#9ca3af"; // gray
    }
  };

  const formatOrderId = (id) => {
    return String(id).padStart(8, "0");
  };

  // Main render
  return (
    <div
      style={{
        padding: "2rem",
        backgroundColor: "#f8fafc",
        minHeight: "100vh",
      }}
    >
      <Helmet>
        <meta name="description" content={settings.siteDescription} />
        <meta name="keywords" content={settings.keywords} />
        <title>{`Orders | ${settings.websiteName}`}</title>
      </Helmet>

      <div
        style={{
          margin: "0 auto",
          backgroundColor: "#ffffff",
          borderRadius: "12px",
          boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
          padding: "2rem",
        }}
      >
        {/* Header Section */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "2rem",
          }}
        >
          <h2
            style={{
              fontSize: "1.875rem",
              fontWeight: 600,
              color: "#1a2b4b",
              margin: 0,
            }}
          >
            Orders
          </h2>

          <Button
            variant="outline-secondary"
            onClick={() => setShowFilters(!showFilters)}
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              padding: "8px 16px",
              borderRadius: "6px",
              fontSize: "14px",
              height: "40px",
            }}
          >
            <Filter size={16} />
            {showFilters ? "Hide Filters" : "Show Filters"}
          </Button>
        </div>

        {/* Alert Message */}
        {alertMessage && (
          <div
            style={{
              padding: "1rem",
              marginBottom: "1rem",
              backgroundColor: "#EFF6FF",
              border: "1px solid #BFDBFE",
              borderRadius: "6px",
              color: "#1E40AF",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {alertMessage}
            <button
              onClick={() => setAlertMessage("")}
              style={{
                background: "none",
                border: "none",
                color: "#1E40AF",
                cursor: "pointer",
                fontSize: "1.5rem",
                lineHeight: 1,
              }}
            >
              ×
            </button>
          </div>
        )}

        {/* Filters Section */}
        {showFilters && (
          <div
            style={{
              backgroundColor: "#ffffff",
              borderRadius: "8px",
              padding: "1.5rem",
              marginBottom: "2rem",
              boxShadow: "0 1px 2px rgba(0, 0, 0, 0.05)",
            }}
          >
            <Form>
              <Row className="mb-3">
                <Col md={3}>
                  <Form.Group>
                    <Form.Label
                      style={{
                        fontSize: "0.875rem",
                        fontWeight: "500",
                        color: "#4B5563",
                        marginBottom: "0.5rem",
                      }}
                    >
                      Order ID
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Search by Order ID"
                      value={filters.searchOrderId}
                      onChange={(e) =>
                        handleFilterChange("searchOrderId", e.target.value)
                      }
                      style={{
                        padding: "0.625rem",
                        borderRadius: "6px",
                        border: "1px solid #E5E7EB",
                        fontSize: "0.875rem",
                      }}
                    />
                  </Form.Group>
                </Col>

                <Col md={3}>
                  <Form.Group>
                    <Form.Label
                      style={{
                        fontSize: "0.875rem",
                        fontWeight: "500",
                        color: "#4B5563",
                        marginBottom: "0.5rem",
                      }}
                    >
                      Customer Name
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Search by Name"
                      value={filters.searchName}
                      onChange={(e) =>
                        handleFilterChange("searchName", e.target.value)
                      }
                      style={{
                        padding: "0.625rem",
                        borderRadius: "6px",
                        border: "1px solid #E5E7EB",
                        fontSize: "0.875rem",
                      }}
                    />
                  </Form.Group>
                </Col>

                <Col md={3}>
                  <Form.Group>
                    <Form.Label
                      style={{
                        fontSize: "0.875rem",
                        fontWeight: "500",
                        color: "#4B5563",
                        marginBottom: "0.5rem",
                      }}
                    >
                      Phone
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Search by Phone"
                      value={filters.searchPhone}
                      onChange={(e) =>
                        handleFilterChange("searchPhone", e.target.value)
                      }
                      style={{
                        padding: "0.625rem",
                        borderRadius: "6px",
                        border: "1px solid #E5E7EB",
                        fontSize: "0.875rem",
                      }}
                    />
                  </Form.Group>
                </Col>

                <Col md={3}>
                  <Form.Group>
                    <Form.Label
                      style={{
                        fontSize: "0.875rem",
                        fontWeight: "500",
                        color: "#4B5563",
                        marginBottom: "0.5rem",
                      }}
                    >
                      Email
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Search by Email"
                      value={filters.searchEmail}
                      onChange={(e) =>
                        handleFilterChange("searchEmail", e.target.value)
                      }
                      style={{
                        padding: "0.625rem",
                        borderRadius: "6px",
                        border: "1px solid #E5E7EB",
                        fontSize: "0.875rem",
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <Form.Group>
                    <Form.Label
                      style={{
                        fontSize: "0.875rem",
                        fontWeight: "500",
                        color: "#4B5563",
                        marginBottom: "0.5rem",
                      }}
                    >
                      Date Filter
                    </Form.Label>
                    <Form.Select
                      value={filters.dateFilter}
                      onChange={(e) =>
                        handleFilterChange("dateFilter", e.target.value)
                      }
                      style={{
                        padding: "0.625rem",
                        borderRadius: "6px",
                        border: "1px solid #E5E7EB",
                        fontSize: "0.875rem",
                      }}
                    >
                      <option value="">Select Date Range</option>
                      <option value="last7days">Last 7 Days</option>
                      <option value="last30days">Last 30 Days</option>
                      <option value="last90days">Last 90 Days</option>
                      <option value="lastYear">Last Year</option>
                    </Form.Select>
                  </Form.Group>
                </Col>

                <Col md={4}>
                  <Form.Group>
                    <Form.Label
                      style={{
                        fontSize: "0.875rem",
                        fontWeight: "500",
                        color: "#4B5563",
                        marginBottom: "0.5rem",
                      }}
                    >
                      From Date
                    </Form.Label>
                    <Form.Control
                      type="date"
                      value={filters.fromDate}
                      onChange={(e) =>
                        handleFilterChange("fromDate", e.target.value)
                      }
                      style={{
                        padding: "0.625rem",
                        borderRadius: "6px",
                        border: "1px solid #E5E7EB",
                        fontSize: "0.875rem",
                      }}
                    />
                  </Form.Group>
                </Col>

                <Col md={4}>
                  <Form.Group>
                    <Form.Label
                      style={{
                        fontSize: "0.875rem",
                        fontWeight: "500",
                        color: "#4B5563",
                        marginBottom: "0.5rem",
                      }}
                    >
                      To Date
                    </Form.Label>
                    <Form.Control
                      type="date"
                      value={filters.toDate}
                      onChange={(e) =>
                        handleFilterChange("toDate", e.target.value)
                      }
                      style={{
                        padding: "0.625rem",
                        borderRadius: "6px",
                        border: "1px solid #E5E7EB",
                        fontSize: "0.875rem",
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <div
                style={{ marginTop: "1.5rem", display: "flex", gap: "1rem" }}
              >
                <Button
                  variant="primary"
                  onClick={() => fetchOrders()}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    padding: "8px 16px",
                    height: "40px",
                    borderRadius: "6px",
                    fontSize: "14px",
                    backgroundColor: "#2563eb",
                    border: "none",
                  }}
                >
                  <Search size={16} /> Apply Filters
                </Button>
                <Button
                  variant="secondary"
                  onClick={clearFilters}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    padding: "8px 16px",
                    height: "40px",
                    borderRadius: "6px",
                    fontSize: "14px",
                    backgroundColor: "#6B7280",
                    border: "none",
                  }}
                >
                  <RefreshCw size={16} /> Clear Filters
                </Button>
              </div>
            </Form>
          </div>
        )}

        {/* Orders Table */}
        <div style={{ overflowX: "auto", width: "100%" }}>
          <Table
            style={{
              width: "100%",
              borderCollapse: "separate",
              borderSpacing: 0,
              backgroundColor: "white",
            }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    padding: "16px",
                    backgroundColor: "#f8fafc",
                    borderBottom: "2px solid #e5e7eb",
                    color: "#374151",
                    fontWeight: 600,
                    textAlign: "center",
                  }}
                >
                  Order ID
                </th>
                <th
                  style={{
                    padding: "16px",
                    backgroundColor: "#f8fafc",
                    borderBottom: "2px solid #e5e7eb",
                    color: "#374151",
                    fontWeight: 600,
                    textAlign: "center",
                  }}
                >
                  Customer
                </th>
                <th
                  style={{
                    padding: "16px",
                    backgroundColor: "#f8fafc",
                    borderBottom: "2px solid #e5e7eb",
                    color: "#374151",
                    fontWeight: 600,
                    textAlign: "center",
                  }}
                >
                  Amount
                </th>
                <th
                  style={{
                    padding: "16px",
                    backgroundColor: "#f8fafc",
                    borderBottom: "2px solid #e5e7eb",
                    color: "#374151",
                    fontWeight: 600,
                    textAlign: "center",
                  }}
                >
                  Payment
                </th>
                <th
                  style={{
                    padding: "16px",
                    backgroundColor: "#f8fafc",
                    borderBottom: "2px solid #e5e7eb",
                    color: "#374151",
                    fontWeight: 600,
                    textAlign: "center",
                  }}
                >
                  Status
                </th>
                <th
                  style={{
                    padding: "16px",
                    backgroundColor: "#f8fafc",
                    borderBottom: "2px solid #e5e7eb",
                    color: "#374151",
                    fontWeight: 600,
                    textAlign: "center",
                  }}
                >
                  Date
                </th>
                <th
                  style={{
                    padding: "16px",
                    backgroundColor: "#f8fafc",
                    borderBottom: "2px solid #e5e7eb",
                    color: "#374151",
                    fontWeight: 600,
                    textAlign: "center",
                  }}
                >
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {orders.map((order) => (
                <tr
                  key={order.id}
                  style={{
                    borderBottom: "1px solid #e5e7eb",
                    transition: "background-color 0.2s",
                  }}
                >
                  <td
                    style={{
                      padding: "16px",
                      textAlign: "center",
                      verticalAlign: "middle",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-flex",
                        alignItems: "center",
                        gap: "8px",
                        color: "#4b5563",
                      }}
                    >
                      <Package size={16} />#{formatOrderId(order.id)}
                    </div>
                  </td>
                  <td
                    style={{
                      padding: "16px",
                      textAlign: "center",
                      verticalAlign: "middle",
                    }}
                  >
                    <Button
                      variant="link"
                      onClick={() =>
                        setSelectedCustomer(customers[order.userId])
                      }
                      style={{
                        display: "inline-flex",
                        alignItems: "center",
                        gap: "8px",
                        color: "#2563eb",
                        textDecoration: "none",
                        padding: "6px 12px",
                        fontSize: "14px",
                        border: "none",
                        background: "none",
                        cursor: "pointer",
                      }}
                    >
                      <User size={16} />
                      {customers[order.userId]
                        ? `${customers[order.userId].firstName} ${
                            customers[order.userId].lastName
                          }`
                        : "Loading..."}
                    </Button>
                  </td>
                  <td
                    style={{
                      padding: "16px",
                      textAlign: "center",
                      verticalAlign: "middle",
                      fontWeight: 500,
                      color: "#374151",
                    }}
                  >
                    {settings.currency} {order.totalAmount}
                  </td>
                  <td
                    style={{
                      padding: "16px",
                      textAlign: "center",
                      verticalAlign: "middle",
                      textTransform: "capitalize",
                      color: "#4b5563",
                    }}
                  >
                    {order.paymentMethod}
                  </td>
                  <td
                    style={{
                      padding: "16px",
                      textAlign: "center",
                      verticalAlign: "middle",
                    }}
                  >
                    <span
                      style={{
                        padding: "6px 12px",
                        borderRadius: "9999px",
                        fontSize: "0.875rem",
                        fontWeight: 500,
                        backgroundColor: getStatusColor(order.orderStatus),
                        color: "white",
                        display: "inline-block",
                      }}
                    >
                      {order.orderStatus}
                    </span>
                  </td>
                  <td
                    style={{
                      padding: "16px",
                      textAlign: "center",
                      verticalAlign: "middle",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-flex",
                        alignItems: "center",
                        gap: "8px",
                        color: "#4b5563",
                      }}
                    >
                      <Calendar size={16} />
                      {moment(order.createdAt).format("MMM DD, YYYY")}
                    </div>
                  </td>
                  <td
                    style={{
                      padding: "16px",
                      textAlign: "center",
                      verticalAlign: "middle",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: "8px",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        variant="link"
                        onClick={() => setSelectedCartItems(order.cartItems)}
                        style={{
                          backgroundColor: "#eff6ff",
                          color: "#2563eb",
                          border: "none",
                          height: "36px",
                          padding: "0 16px",
                          borderRadius: "6px",
                          fontSize: "14px",
                          fontWeight: 500,
                          display: "inline-flex",
                          alignItems: "center",
                          gap: "6px",
                          textDecoration: "none",
                        }}
                      >
                        <Eye size={16} /> View
                      </Button>
                      <Button
                        variant="link"
                        onClick={() => {
                          setSelectedOrderId(order.id);
                          setNewStatus(order.orderStatus);
                        }}
                        style={{
                          backgroundColor: "#f0fdf4",
                          color: "#16a34a",
                          border: "none",
                          height: "36px",
                          padding: "0 16px",
                          borderRadius: "6px",
                          fontSize: "14px",
                          fontWeight: 500,
                          display: "inline-flex",
                          alignItems: "center",
                          gap: "6px",
                          textDecoration: "none",
                        }}
                      >
                        <Edit2 size={16} /> Status
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>

        {/* Pagination */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "8px",
            marginTop: "24px",
          }}
        >
          {[...Array(totalPages)].map((_, index) => (
            <Button
              key={index + 1}
              variant={
                currentPage === index + 1 ? "primary" : "outline-primary"
              }
              onClick={() => setCurrentPage(index + 1)}
              style={{
                minWidth: "40px",
                height: "40px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "6px",
                fontWeight: 500,
                fontSize: "14px",
              }}
            >
              {index + 1}
            </Button>
          ))}
        </div>

        {/* Customer Details Modal */}
        <Modal
          show={selectedCustomer !== null}
          onHide={() => setSelectedCustomer(null)}
          centered
        >
          <Modal.Header
            style={{
              padding: "20px",
              borderBottom: "1px solid #e5e7eb",
            }}
          >
            <Modal.Title
              style={{
                fontSize: "1.25rem",
                fontWeight: 600,
                color: "#1f2937",
              }}
            >
              Customer Details
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{ padding: "20px !important" }}
            className="NewModalBody"
          >
            {selectedCustomer && (
              <div className="NewModalBody">
                <div style={{ marginBottom: "16px" }}>
                  <label
                    style={{
                      display: "block",
                      fontSize: "14px",
                      fontWeight: 500,
                      color: "#6b7280",
                      marginBottom: "4px",
                    }}
                  >
                    Full Name
                  </label>
                  <div
                    style={{
                      fontSize: "16px",
                      color: "#1f2937",
                    }}
                  >
                    {selectedCustomer.firstName} {selectedCustomer.lastName}
                  </div>
                </div>

                <div style={{ marginBottom: "16px" }}>
                  <label
                    style={{
                      display: "block",
                      fontSize: "14px",
                      fontWeight: 500,
                      color: "#6b7280",
                      marginBottom: "4px",
                    }}
                  >
                    Email Address
                  </label>
                  <div
                    style={{
                      fontSize: "16px",
                      color: "#1f2937",
                    }}
                  >
                    {selectedCustomer.email}
                  </div>
                </div>

                <div style={{ marginBottom: "16px" }}>
                  <label
                    style={{
                      display: "block",
                      fontSize: "14px",
                      fontWeight: 500,
                      color: "#6b7280",
                      marginBottom: "4px",
                    }}
                  >
                    Phone Number
                  </label>
                  <div
                    style={{
                      fontSize: "16px",
                      color: "#1f2937",
                    }}
                  >
                    {selectedCustomer.phone}
                  </div>
                </div>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer
            style={{
              padding: "16px",
              borderTop: "1px solid #e5e7eb",
            }}
          >
            <Button
              variant="secondary"
              onClick={() => setSelectedCustomer(null)}
              style={{
                padding: "8px 16px",
                borderRadius: "6px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Cart Items Modal */}
        <Modal
          show={selectedCartItems.length > 0}
          onHide={() => setSelectedCartItems([])}
          size="lg"
          centered
        >
          <Modal.Header
            style={{
              padding: "20px",
              borderBottom: "1px solid #e5e7eb",
            }}
          >
            <Modal.Title
              style={{
                fontSize: "1.25rem",
                fontWeight: 600,
                color: "#1f2937",
              }}
            >
              Order Details
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{ padding: "20px !important" }}
            className="NewModalBody"
          >
            <Table
              style={{
                width: "100%",
                borderCollapse: "separate",
                borderSpacing: 0,
              }}
            >
              <thead>
                <tr>
                  <th
                    style={{
                      padding: "12px 16px",
                      backgroundColor: "#f8fafc",
                      borderBottom: "2px solid #e5e7eb",
                      textAlign: "left",
                      color: "#374151",
                      fontWeight: 600,
                    }}
                  >
                    Product
                  </th>
                  <th
                    style={{
                      padding: "12px 16px",
                      backgroundColor: "#f8fafc",
                      borderBottom: "2px solid #e5e7eb",
                      textAlign: "center",
                      color: "#374151",
                      fontWeight: 600,
                    }}
                  >
                    Quantity
                  </th>
                  <th
                    style={{
                      padding: "12px 16px",
                      backgroundColor: "#f8fafc",
                      borderBottom: "2px solid #e5e7eb",
                      textAlign: "center",
                      color: "#374151",
                      fontWeight: 600,
                    }}
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {selectedCartItems.map((item, index) => (
                  <tr
                    key={index}
                    style={{
                      borderBottom: "1px solid #e5e7eb",
                    }}
                  >
                    <td
                      style={{
                        padding: "16px",
                        verticalAlign: "middle",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "12px",
                        }}
                      >
                        <img
                          src={`${process.env.REACT_APP_SERVER_URL}${item.images[0]}`}
                          alt={item.name}
                          style={{
                            width: "50px",
                            height: "50px",
                            objectFit: "cover",
                            borderRadius: "4px",
                          }}
                        />
                        <span
                          style={{
                            fontSize: "14px",
                            color: "#1f2937",
                          }}
                        >
                          {item.name}
                        </span>
                      </div>
                    </td>
                    <td
                      style={{
                        padding: "16px",
                        textAlign: "center",
                        verticalAlign: "middle",
                        fontSize: "14px",
                        color: "#1f2937",
                      }}
                    >
                      {item.quantity}
                    </td>
                    <td
                      style={{
                        padding: "16px",
                        textAlign: "center",
                        verticalAlign: "middle",
                      }}
                    >
                      <Button
                        variant="link"
                        href={`/product/${item.id}`}
                        target="_blank"
                        style={{
                          color: "#2563eb",
                          textDecoration: "none",
                          fontSize: "14px",
                          padding: "6px 12px",
                          borderRadius: "6px",
                          display: "inline-flex",
                          alignItems: "center",
                          gap: "4px",
                        }}
                      >
                        <Eye size={16} /> View Product
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Modal.Body>
        </Modal>

        {/* Change Order Status Modal */}
        {/* Change Order Status Modal */}
        <Modal
          show={selectedOrderId !== null}
          onHide={() => setSelectedOrderId(null)}
          centered
        >
          <Modal.Header
            style={{
              padding: "20px",
              borderBottom: "1px solid #e5e7eb",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Modal.Title
              style={{
                fontSize: "1.25rem",
                fontWeight: 600,
                color: "#1f2937",
              }}
            >
              Update Order Status
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{ padding: "20px !important" }}
            className="NewModalBody"
          >
            <Form>
              <Form.Group className="NewModalBody">
                <Form.Label
                  className="NewModalBody"
                  style={{
                    display: "block",
                    fontSize: "14px",
                    fontWeight: 500,
                    color: "#4b5563",
                    marginBottom: "8px",
                  }}
                >
                  Status
                </Form.Label>
                <Form.Select
                  value={newStatus}
                  onChange={(e) => setNewStatus(e.target.value)}
                  style={{
                    width: "100%",
                    padding: "10px 12px",
                    borderRadius: "6px",
                    border: "1px solid #e5e7eb",
                    fontSize: "14px",
                    color: "#1f2937",
                    backgroundColor: "#ffffff",
                    backgroundImage: "none",
                  }}
                >
                  {statuses.map((status) => (
                    <option key={status.id} value={status.name}>
                      {status.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer
            style={{
              padding: "16px 20px",
              borderTop: "1px solid #e5e7eb",
              display: "flex",
              justifyContent: "flex-end",
              gap: "8px",
            }}
          >
            <Button
              onClick={() => setSelectedOrderId(null)}
              style={{
                padding: "8px 16px",
                height: "40px",
                minWidth: "80px",
                borderRadius: "6px",
                fontSize: "14px",
                fontWeight: 500,
                backgroundColor: "#6b7280",
                border: "none",
                color: "#ffffff",
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => handleOrderStatusChange(selectedOrderId)}
              style={{
                padding: "8px 16px",
                height: "40px",
                minWidth: "120px",
                borderRadius: "6px",
                fontSize: "14px",
                fontWeight: 500,
                backgroundColor: "#2563eb",
                border: "none",
                color: "var(--primary-color)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "8px",
              }}
            >
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default OrderList;
