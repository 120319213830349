import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import "./css/AddToCartModal.css";
import axios from "axios";

const AddToCartModal = ({ show, handleClose, handleSave, product }) => {
  const [quantity, setQuantity] = useState(1);
  const [settings, setSettings] = useState({
    phones: "",
    currency: "",
  });

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/api/settings`
        );
        setSettings(response.data);
      } catch (error) {
        console.error("Error fetching settings from the database:", error);
      }
    };

    fetchSettings();
  }, []);

  const handleSaveClick = () => {
    handleSave(product, quantity);
    handleClose();
  };

  const formatPrice = (price) => {
    return Number(price).toLocaleString();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add to Cart</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {product && (
          <div>
            <img
              src={
                product.images && product.images[0]
                  ? `${process.env.REACT_APP_SERVER_URL}${product.images[0]}`
                  : "default-image-path"
              }
              alt={product.name}
              style={{ width: "100%" }}
            />
            <div className="modal-content-body">
              <h5 className="modal-product-name">{product.name}</h5>
              <p>
                Price: {settings.currency} {formatPrice(product.price)}
              </p>
              <p>In stock: {product.stock}</p>
              <div className="d-flex align-items-center">
                <button
                  className="btn btn-outline-secondary"
                  onClick={() => setQuantity(Math.max(1, quantity - 1))}
                >
                  -
                </button>
                <input
                  type="number"
                  value={quantity}
                  readOnly
                  className="form-control text-center mx-2 center-txt"
                  style={{ width: "60px" }}
                />
                <button
                  className="btn btn-outline-secondary"
                  onClick={() =>
                    setQuantity(Math.min(product.stock, quantity + 1))
                  }
                >
                  +
                </button>
              </div>
              <p>
                Total: {settings.currency}{" "}
                {formatPrice(product.price * quantity)}
              </p>
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="btn-modal-close"
          variant="secondary"
          onClick={handleClose}
        >
          Close
        </Button>
        <Button
          className="btn-modal-add-to-cart"
          variant="primary"
          onClick={handleSaveClick}
        >
          Add to Cart
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddToCartModal;
