import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { Package, Heart, User, LogOut, ChevronRight } from "lucide-react";
import axios from "axios";
import { UserContext } from "../UserContext";
import { Helmet } from "react-helmet";

const UserDashboard = () => {
  const { user, logout } = useContext(UserContext);
  const navigate = useNavigate();
  const [orderCount, setOrderCount] = useState(0);
  const [wishlistCount, setWishlistCount] = useState(0);
  const [settings, setSettings] = useState({
    websiteName: "",
    siteDescription: "",
    keywords: "",
  });

  useEffect(() => {
    if (!user) {
      navigate("/login");
      return;
    }

    const fetchData = async () => {
      try {
        // Fetch settings
        const settingsResponse = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/api/settings`
        );
        setSettings(settingsResponse.data);

        // Fetch orders count
        const ordersResponse = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/api/my-orders`,
          {
            params: { userId: user.id },
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setOrderCount(ordersResponse.data.totalCount || 0);

        // Fetch wishlist count
        const wishlistResponse = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/api/wishlist/${user.id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setWishlistCount(wishlistResponse.data.length || 0);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [user, navigate]);

  const menuItems = [
    {
      icon: <Package size={24} />,
      title: "My Orders",
      description: "Track your orders and delivery status",
      link: "/my-orders",
      count: orderCount,
      gradient: "linear-gradient(135deg, #6366f1 0%, #8b5cf6 100%)",
    },
    {
      icon: <Heart size={24} />,
      title: "Wishlist",
      description: "Products saved for later",
      link: "/wishlist",
      count: wishlistCount,
      gradient: "linear-gradient(135deg, #f43f5e 0%, #ec4899 100%)",
    },
    {
      icon: <User size={24} />,
      title: "Profile Settings",
      description: "Manage your account details",
      link: "/profile",
      count: null,
      gradient: "linear-gradient(135deg, #0ea5e9 0%, #3b82f6 100%)",
    },
  ];

  const containerStyle = {
    maxWidth: "1200px",
    margin: "2rem auto",
    padding: "0 1.5rem",
  };

  const welcomeContainerStyle = {
    background: "var(--primary-color) !important",
    borderRadius: "1rem",
    padding: "2rem",
    marginBottom: "2rem",
    textAlign: "left",
    color: "white",
    boxShadow:
      "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
  };

  const cardStyle = {
    borderRadius: "1rem",
    border: "none",
    overflow: "hidden",
    cursor: "pointer",
    transition: "all 0.3s ease",
    backgroundColor: "white",
    height: "100%",
  };

  const cardHoverStyle = {
    transform: "translateY(-5px)",
    boxShadow:
      "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
  };

  const [hoveredCard, setHoveredCard] = useState(null);

  const welcomeContentStyle = {
    display: "flex",
    flexDirection: window.innerWidth <= 768 ? "column" : "row",
    justifyContent: "space-between",
    alignItems: window.innerWidth <= 768 ? "flex-start" : "center",
    gap: window.innerWidth <= 768 ? "1rem" : "0",
  };

  const logoutButtonStyle = {
    background: "rgba(255, 255, 255, 0.1)",
    border: "none",
    padding: "0.75rem 1.5rem",
    borderRadius: "0.75rem",
    display: "flex",
    alignItems: "center",
    gap: "0.5rem",
    transition: "all 0.3s ease",
    color: "white",
    alignSelf: window.innerWidth <= 768 ? "stretch" : "center",
    width: window.innerWidth <= 768 ? "100%" : "auto",
  };

  return (
    <div style={containerStyle}>
      <Helmet>
        <title>{`My Account | ${settings.websiteName}`}</title>
      </Helmet>

      <div style={welcomeContainerStyle}>
        <div style={welcomeContentStyle}>
          <div>
            <h1
              style={{
                fontSize: "2.5rem",
                fontWeight: "600",
                marginBottom: "0.5rem",
              }}
            >
              Welcome back, {user?.firstName}!
            </h1>
            <p
              style={{
                fontSize: "1.1rem",
                opacity: "0.9",
                marginBottom: window.innerWidth <= 768 ? "1rem" : 0,
              }}
            >
              Manage your orders and personalize your shopping experience
            </p>
          </div>
          <Button
            onClick={logout}
            style={logoutButtonStyle}
            onMouseEnter={(e) => {
              e.currentTarget.style.background = "rgba(255, 255, 255, 0.2)";
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.background = "rgba(255, 255, 255, 0.1)";
            }}
          >
            <LogOut size={20} />
            Sign Out
          </Button>
        </div>
      </div>

      <Row>
        {menuItems.map((item, index) => (
          <Col key={index} lg={4} md={6} className="mb-4">
            <Link
              to={item.link}
              style={{ textDecoration: "none" }}
              onMouseEnter={() => setHoveredCard(index)}
              onMouseLeave={() => setHoveredCard(null)}
            >
              <Card
                style={{
                  ...cardStyle,
                  ...(hoveredCard === index ? cardHoverStyle : {}),
                }}
              >
                <div
                  style={{
                    background: item.gradient,
                    height: "8px",
                  }}
                />
                <Card.Body style={{ padding: "1.5rem" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      gap: "1rem",
                    }}
                  >
                    <div
                      style={{
                        padding: "1rem",
                        borderRadius: "1rem",
                        background: `${item.gradient}15`,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div style={{ color: item.gradient.split(" ")[2] }}>
                        {item.icon}
                      </div>
                    </div>
                    <div style={{ flex: 1, minWidth: 0 }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginBottom: "0.5rem",
                          gap: "0.5rem",
                        }}
                      >
                        <h3
                          style={{
                            fontSize: "1.25rem",
                            fontWeight: "600",
                            color: "#1e293b",
                            margin: 0,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {item.title}
                        </h3>
                        {item.count > 0 && (
                          <span
                            style={{
                              background: item.gradient,
                              color: "white",
                              padding: "0.25rem 0.75rem",
                              borderRadius: "1rem",
                              fontSize: "0.875rem",
                              fontWeight: "500",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {item.count}
                          </span>
                        )}
                      </div>
                      <p
                        style={{
                          color: "#64748b",
                          margin: 0,
                          fontSize: "0.875rem",
                        }}
                      >
                        {item.description}
                      </p>
                    </div>
                    <ChevronRight
                      size={20}
                      style={{
                        color: "#94a3b8",
                        transition: "transform 0.3s ease",
                        transform:
                          hoveredCard === index ? "translateX(5px)" : "none",
                        flexShrink: 0,
                      }}
                    />
                  </div>
                </Card.Body>
              </Card>
            </Link>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default UserDashboard;
