// src/components/CartIcon.js

import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import './css/CartIcon.css'; // Create this CSS file to style the badge

const CartIcon = ({ cartItems }) => {
  return (
    <div className="cart-icon-container">
      <Link className="nav-link d-flex align-items-right" to="/cart">
        <FontAwesomeIcon icon={faShoppingCart} className="custom-icon-cart" />
        {cartItems.length > 0 && <span className="badge badge-pill badge-danger cart-badge">{cartItems.length}</span>}
      </Link>
    </div>
  );
};

export default CartIcon;
