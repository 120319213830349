import React, { useState, useEffect } from "react";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import { Spinner } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { User, Mail, Lock, Phone, Bell, ArrowLeft } from "lucide-react";

const Register = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    password: "",
    confirmPassword: "",
    receiveNewsletters: true,
    agreeTerms: false,
  });
  const [captchaToken, setCaptchaToken] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const [settings, setSettings] = useState({
    websiteName: "",
    siteDescription: "",
    keywords: "",
  });

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/api/settings`
        );
        setSettings(response.data);
      } catch (error) {
        console.error("Error fetching settings from the database:", error);
      }
    };

    fetchSettings();
  }, []);
  const handleCaptchaChange = (token) => {
    setCaptchaToken(token);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (formData.password !== formData.confirmPassword) {
      setError("Passwords do not match");
      setLoading(false);
      return;
    }

    if (!captchaToken) {
      setError("Please complete the captcha");
      setLoading(false);
      return;
    }

    if (!formData.agreeTerms) {
      setError("You must agree to the terms and conditions");
      setLoading(false);
      return;
    }

    try {
      await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/register`, {
        ...formData,
        captchaToken,
      });
      setSuccess(
        "Registration successful! Please check your email for verification."
      );
      setError("");
      window.scrollTo(0, 0);
    } catch (error) {
      if (
        error.response &&
        error.response.data.error === "Email already exists"
      ) {
        setError("Email already exists. Please use a different email.");
      } else {
        setError("Registration failed. Please try again.");
      }
      setSuccess("");
      window.scrollTo(0, 0);
    } finally {
      setLoading(false);
    }
  };

  const containerStyle = {
    minHeight: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "2rem",
    background: "#f8fafc",
  };

  const cardStyle = {
    textAlign: "left",
    width: "100%",
    maxWidth: "500px",
    background: "white",
    borderRadius: "1rem",
    boxShadow:
      "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
    padding: "2rem",
  };

  const formGroupStyle = {
    marginBottom: "1.5rem",
    position: "relative",
  };

  const inputContainerStyle = {
    position: "relative",
    display: "flex",
    alignItems: "center",
  };

  const inputStyle = {
    width: "100%",
    padding: "0.75rem 1rem 0.75rem 2.5rem",
    borderRadius: "0.5rem",
    border: "1px solid #e2e8f0",
    fontSize: "1rem",
    transition: "all 0.3s ease",
    outline: "none",
  };

  const inputIconStyle = {
    position: "absolute",
    left: "0.75rem",
    color: "#64748b",
  };

  const labelStyle = {
    display: "block",
    marginBottom: "0.5rem",
    color: "#4a5568",
    fontWeight: "500",
    textAlign: "left",
  };

  const buttonStyle = {
    width: "100%",
    padding: "0.75rem",
    borderRadius: "0.5rem",
    border: "none",
    background: "var(--primary-color)",
    color: "white",
    fontSize: "1rem",
    fontWeight: "500",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "0.5rem",
    cursor: "pointer",
    transition: "all 0.3s ease",
  };

  const checkboxStyle = {
    display: "flex",
    alignItems: "center",
    gap: "0.5rem",
    color: "#4a5568",
    cursor: "pointer",
  };

  const backLinkStyle = {
    display: "inline-flex",
    alignItems: "center",
    gap: "0.5rem",
    color: "#64748b",
    textDecoration: "none",
    marginBottom: "1.5rem",
    fontSize: "0.875rem",
    transition: "color 0.3s ease",
  };

  const linkStyle = {
    color: "var(--primary-color)",
    textDecoration: "none",
    transition: "color 0.3s ease",
  };

  return (
    <div style={containerStyle}>
      <Helmet>
        <meta name="description" content={settings.siteDescription} />
        <meta name="keywords" content={settings.keywords} />
        <meta name="author" content={settings.websiteName} />
        <meta name="robots" content="index, follow" />
        <meta name="googlebot" content="index, follow" />
        <meta name="bingbot" content="index, follow" />
        <meta property="og:title" content={settings.websiteName} />
        <meta property="og:description" content={settings.siteDescription} />
        <meta
          property="og:image"
          content={`${settings.serverUrl}${settings.logoUrl}`}
        />
        <meta property="og:url" content={settings.baseUrl} />
        <meta property="og:type" content="website" />
        <title>{`Register | ${settings.websiteName}`}</title>
      </Helmet>
      <div style={cardStyle}>
        <a
          href="/login"
          style={backLinkStyle}
          onMouseEnter={(e) => {
            e.target.style.color = "#1e293b";
          }}
          onMouseLeave={(e) => {
            e.target.style.color = "#64748b";
          }}
        >
          <ArrowLeft size={16} />
          Back to login
        </a>

        <h1
          style={{
            fontSize: "1.875rem",
            fontWeight: "600",
            marginBottom: "0.5rem",
            color: "#1e293b",
          }}
        >
          Create an account
        </h1>
        <p
          style={{
            color: "#64748b",
            marginBottom: "2rem",
          }}
        >
          Fill in your details to get started
        </p>

        {error && (
          <div
            style={{
              padding: "0.75rem",
              marginBottom: "1.5rem",
              borderRadius: "0.5rem",
              background: "#fee2e2",
              color: "#991b1b",
              fontSize: "0.875rem",
            }}
          >
            {error}
          </div>
        )}

        {success && (
          <div
            style={{
              padding: "0.75rem",
              marginBottom: "1.5rem",
              borderRadius: "0.5rem",
              background: "#dcfce7",
              color: "#166534",
              fontSize: "0.875rem",
            }}
          >
            {success}
          </div>
        )}

        <form onSubmit={handleSubmit}>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: "1rem",
              marginBottom: "1.5rem",
            }}
          >
            <div style={formGroupStyle}>
              <label style={labelStyle}>First Name</label>
              <div style={inputContainerStyle}>
                <User size={18} style={inputIconStyle} />
                <input
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  required
                  placeholder="Enter first name"
                  style={inputStyle}
                />
              </div>
            </div>

            <div style={formGroupStyle}>
              <label style={labelStyle}>Last Name</label>
              <div style={inputContainerStyle}>
                <User size={18} style={inputIconStyle} />
                <input
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  required
                  placeholder="Enter last name"
                  style={inputStyle}
                />
              </div>
            </div>
          </div>

          <div style={formGroupStyle}>
            <label style={labelStyle}>Phone</label>
            <div style={inputContainerStyle}>
              <Phone size={18} style={inputIconStyle} />
              <input
                type="text"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                required
                placeholder="Enter phone number"
                style={inputStyle}
              />
            </div>
          </div>

          <div style={formGroupStyle}>
            <label style={labelStyle}>Email</label>
            <div style={inputContainerStyle}>
              <Mail size={18} style={inputIconStyle} />
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                placeholder="Enter email address"
                style={inputStyle}
              />
            </div>
          </div>

          <div style={formGroupStyle}>
            <label style={labelStyle}>Password</label>
            <div style={inputContainerStyle}>
              <Lock size={18} style={inputIconStyle} />
              <input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
                placeholder="Create password"
                style={inputStyle}
              />
            </div>
          </div>

          <div style={formGroupStyle}>
            <label style={labelStyle}>Confirm Password</label>
            <div style={inputContainerStyle}>
              <Lock size={18} style={inputIconStyle} />
              <input
                type="password"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleChange}
                required
                placeholder="Confirm password"
                style={inputStyle}
              />
            </div>
          </div>

          <div style={{ ...formGroupStyle, marginBottom: "1rem" }}>
            <label style={checkboxStyle}>
              <div
                style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}
              >
                <Bell size={18} style={{ color: "#64748b" }} />
                <input
                  type="checkbox"
                  name="receiveNewsletters"
                  checked={formData.receiveNewsletters}
                  onChange={handleChange}
                  style={{ margin: 0 }}
                />
              </div>
              Receive newsletters and updates
            </label>
          </div>

          <div style={{ ...formGroupStyle, marginBottom: "2rem" }}>
            <label style={checkboxStyle}>
              <input
                type="checkbox"
                name="agreeTerms"
                checked={formData.agreeTerms}
                onChange={handleChange}
                required
                style={{ margin: 0 }}
              />
              <span>
                I agree to the{" "}
                <a
                  href="/terms-and-conditions"
                  style={linkStyle}
                  onMouseEnter={(e) => {
                    e.target.style.color = "#1e40af";
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.color = "var(--primary-color)";
                  }}
                >
                  Terms and conditions
                </a>{" "}
                and{" "}
                <a
                  href="/privacy-policy"
                  style={linkStyle}
                  onMouseEnter={(e) => {
                    e.target.style.color = "#1e40af";
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.color = "var(--primary-color)";
                  }}
                >
                  Privacy policy
                </a>
              </span>
            </label>
          </div>

          <div style={{ marginBottom: "1.5rem" }}>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_V2_CAPTCHA_API_KEY}
              onChange={handleCaptchaChange}
            />
          </div>

          <button
            type="submit"
            style={buttonStyle}
            disabled={loading}
            onMouseEnter={(e) => {
              e.target.style.opacity = "0.9";
            }}
            onMouseLeave={(e) => {
              e.target.style.opacity = "1";
            }}
          >
            {loading ? (
              <>
                <Spinner size="sm" />
                Creating account...
              </>
            ) : (
              "Create account"
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Register;
