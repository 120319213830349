import React from "react";
import "./css/FeatureSection.css"; // Import CSS for additional styling

const features = [
  {
    icon: "local_shipping",
    title: "Free Shipping",
    description: "Enjoy the free shipping month for all our latest products",
  },
  {
    icon: "lock",
    title: "Secure Payment",
    description: "Complete your payment with trusted partners",
  },
  {
    icon: "undo",
    title: "Return Policy",
    description: "A 14 day return policy for our special offer",
  },
  {
    icon: "verified",
    title: "Certified Original Seller",
    description: "Trusted sources who can bring you the product you need",
  },
];

const FeatureSection = () => {
  return (
    <div className="feature-section" align="center">
      <div className="row container" align="center">
        {features.map((feature, index) => (
          <div className="col-md-3 feature" key={index}>
            <span className="material-icons feature-icon">{feature.icon}</span>
            <h4>{feature.title}</h4>
            <p>{feature.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FeatureSection;
