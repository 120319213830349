import React, { useState, useEffect } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import {
  MapPin,
  Plus,
  Edit2,
  Trash2,
  DollarSign,
  Globe,
  Search,
  AlertCircle,
} from "lucide-react";

const ManageDeliveryAddresses = () => {
  // States
  const [deliveryAddresses, setDeliveryAddresses] = useState([]);
  const [newAddress, setNewAddress] = useState({
    country: "",
    generalLocation: "",
    specificLocation: "",
    pickupCost: "",
  });
  const [editingAddress, setEditingAddress] = useState(null);
  const [settings, setSettings] = useState({
    websiteName: "",
    siteDescription: "",
    keywords: "",
    currency: "",
  });
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [status, setStatus] = useState({ type: "", message: "" });

  // Styles
  const cardStyle = {
    background: "white",
    borderRadius: "12px",
    padding: "24px",
    marginBottom: "24px",
    boxShadow: "0 2px 12px rgba(0,0,0,0.1)",
    border: "1px solid #eee",
  };

  const inputStyle = {
    width: "100%",
    padding: "12px",
    borderRadius: "8px",
    border: "1px solid #e0e0e0",
    fontSize: "14px",
    marginBottom: "16px",
    transition: "border-color 0.3s ease",
  };

  const buttonStyle = {
    padding: "12px 24px",
    borderRadius: "8px",
    border: "none",
    backgroundColor: "#2c3e50",
    color: "white",
    fontWeight: "500",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    gap: "8px",
    transition: "background 0.3s ease",
  };

  // Effects
  useEffect(() => {
    fetchSettings();
    fetchAddresses();
  }, []);

  // API Calls
  const fetchSettings = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/settings`
      );
      setSettings(response.data || {});
    } catch (error) {
      console.error("Error fetching settings:", error);
    }
  };

  const fetchAddresses = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/delivery-addresses`
      );
      setDeliveryAddresses(response.data);
    } catch (error) {
      setStatus({
        type: "error",
        message: "Error fetching delivery addresses",
      });
    } finally {
      setLoading(false);
    }
  };

  // Handlers
  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewAddress((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (editingAddress) {
        const response = await axios.put(
          `${process.env.REACT_APP_SERVER_URL}/api/delivery-addresses/${editingAddress.id}`,
          newAddress
        );
        setDeliveryAddresses((prev) =>
          prev.map((addr) =>
            addr.id === editingAddress.id ? response.data : addr
          )
        );
        setStatus({
          type: "success",
          message: "Delivery address updated successfully",
        });
      } else {
        const response = await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/api/delivery-addresses`,
          newAddress
        );
        setDeliveryAddresses((prev) => [...prev, response.data]);
        setStatus({
          type: "success",
          message: "Delivery address added successfully",
        });
      }
      resetForm();
    } catch (error) {
      setStatus({
        type: "error",
        message: "Error saving delivery address",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    if (!window.confirm("Are you sure you want to delete this address?"))
      return;

    setLoading(true);
    try {
      await axios.delete(
        `${process.env.REACT_APP_SERVER_URL}/api/delivery-addresses/${id}`
      );
      setDeliveryAddresses((prev) => prev.filter((addr) => addr.id !== id));
      setStatus({
        type: "success",
        message: "Delivery address deleted successfully",
      });
    } catch (error) {
      setStatus({
        type: "error",
        message: "Error deleting delivery address",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (address) => {
    setEditingAddress(address);
    setNewAddress(address);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const resetForm = () => {
    setNewAddress({
      country: "",
      generalLocation: "",
      specificLocation: "",
      pickupCost: "",
    });
    setEditingAddress(null);
  };

  // Filter addresses based on search
  const filteredAddresses = deliveryAddresses.filter(
    (address) =>
      address.country.toLowerCase().includes(searchTerm.toLowerCase()) ||
      address.generalLocation
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      address.specificLocation.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div style={{ margin: "0 auto", padding: "20px 20px" }}>
      <Helmet>
        <title>{`Delivery Addresses | ${settings.websiteName}`}</title>
        <meta name="description" content={settings.siteDescription} />
        <meta name="keywords" content={settings.keywords} />
      </Helmet>

      {/* Add/Edit Form */}
      <div style={cardStyle}>
        <h2
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            color: "#2c3e50",
            marginBottom: "24px",
          }}
        >
          <MapPin size={24} />
          {editingAddress ? "Edit Delivery Address" : "Add Delivery Address"}
        </h2>

        {status.message && (
          <div
            style={{
              padding: "12px",
              borderRadius: "8px",
              marginBottom: "20px",
              backgroundColor:
                status.type === "success" ? "#e3fcef" : "#fee2e2",
              color: status.type === "success" ? "#059669" : "#dc2626",
              display: "flex",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <AlertCircle size={18} />
            {status.message}
          </div>
        )}

        <form onSubmit={handleSubmit}>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: "16px",
            }}
          >
            <div>
              <label
                style={{
                  textAlign: "left",
                  display: "block",
                  marginBottom: "8px",
                  fontWeight: "500",
                  color: "#2c3e50",
                }}
              >
                <Globe
                  size={16}
                  style={{ marginRight: "8px", verticalAlign: "middle" }}
                />
                Country
              </label>
              <input
                type="text"
                name="country"
                value={newAddress.country}
                onChange={handleChange}
                style={inputStyle}
                placeholder="Enter country"
                required
              />
            </div>

            <div>
              <label
                style={{
                  textAlign: "left",
                  display: "block",
                  marginBottom: "8px",
                  fontWeight: "500",
                  color: "#2c3e50",
                }}
              >
                <MapPin
                  size={16}
                  style={{ marginRight: "8px", verticalAlign: "middle" }}
                />
                General Location
              </label>
              <input
                type="text"
                name="generalLocation"
                value={newAddress.generalLocation}
                onChange={handleChange}
                style={inputStyle}
                placeholder="Enter general location"
                required
              />
            </div>
          </div>

          <div style={{ marginBottom: "16px" }}>
            <label
              style={{
                textAlign: "left",
                display: "block",
                marginBottom: "8px",
                fontWeight: "500",
                color: "#2c3e50",
              }}
            >
              <MapPin
                size={16}
                style={{ marginRight: "8px", verticalAlign: "middle" }}
              />
              Specific Location
            </label>
            <input
              type="text"
              name="specificLocation"
              value={newAddress.specificLocation}
              onChange={handleChange}
              style={inputStyle}
              placeholder="Enter specific location"
              required
            />
          </div>

          <div style={{ marginBottom: "24px" }}>
            <label
              style={{
                textAlign: "left",
                display: "block",
                marginBottom: "8px",
                fontWeight: "500",
                color: "#2c3e50",
              }}
            >
              <DollarSign
                size={16}
                style={{ marginRight: "8px", verticalAlign: "middle" }}
              />
              Pickup Cost ({settings.currency})
            </label>
            <input
              type="number"
              name="pickupCost"
              value={newAddress.pickupCost}
              onChange={handleChange}
              style={inputStyle}
              placeholder="Enter pickup cost"
              required
            />
          </div>

          <div style={{ display: "flex", gap: "10px" }}>
            <button type="submit" style={buttonStyle} disabled={loading}>
              {editingAddress ? (
                <>
                  <Edit2 size={18} />
                  {loading ? "Updating..." : "Update Address"}
                </>
              ) : (
                <>
                  <Plus size={18} />
                  {loading ? "Adding..." : "Add Address"}
                </>
              )}
            </button>

            {editingAddress && (
              <button
                type="button"
                onClick={resetForm}
                style={{
                  ...buttonStyle,
                  backgroundColor: "white",
                  color: "#2c3e50",
                  border: "1px solid #e0e0e0",
                }}
              >
                Cancel
              </button>
            )}
          </div>
        </form>
      </div>

      {/* Addresses List */}
      <div style={cardStyle}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "24px",
          }}
        >
          <h2
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              color: "#2c3e50",
              margin: 0,
            }}
          >
            <MapPin size={24} />
            Delivery Addresses
          </h2>

          <div
            style={{
              textAlign: "left",
              display: "flex",
              alignItems: "left",
              gap: "8px",
              padding: "8px 12px",
              borderRadius: "8px",
              border: "1px solid #e0e0e0",
              background: "white",
            }}
          >
            <Search size={18} color="#6b7280" />
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search addresses..."
              style={{
                border: "none",
                outline: "none",
                fontSize: "14px",
                overflowX: "hidden",
              }}
            />
          </div>
        </div>

        {loading ? (
          <div style={{ textAlign: "center", padding: "20px" }}>Loading...</div>
        ) : filteredAddresses.length === 0 ? (
          <div
            style={{ textAlign: "center", padding: "20px", color: "#6b7280" }}
          >
            No delivery addresses found
          </div>
        ) : (
          <div style={{ display: "grid", gap: "16px", textAlign: "left" }}>
            {filteredAddresses.map((address) => (
              <div
                key={address.id}
                style={{
                  padding: "16px",
                  borderRadius: "8px",
                  border: "1px solid #e0e0e0",
                  backgroundColor: "#f8fafc",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                  }}
                >
                  <div>
                    <h3
                      style={{
                        margin: "0 0 8px 0",
                        fontSize: "16px",
                        fontWeight: "500",
                        color: "#2c3e50",
                      }}
                    >
                      {address.country}
                    </h3>
                    <p
                      style={{
                        margin: "0 0 4px 0",
                        fontSize: "14px",
                        color: "#4b5563",
                      }}
                    >
                      {address.generalLocation} - {address.specificLocation}
                    </p>
                    <div
                      style={{
                        display: "inline-block",
                        padding: "4px 12px",
                        borderRadius: "12px",
                        backgroundColor: "#e3fcef",
                        color: "#059669",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    >
                      {settings.currency} {address.pickupCost}
                    </div>
                  </div>

                  <div style={{ display: "flex", gap: "8px" }}>
                    <button
                      onClick={() => handleEdit(address)}
                      style={{
                        padding: "8px",
                        borderRadius: "6px",
                        border: "1px solid #e0e0e0",
                        background: "white",
                        cursor: "pointer",
                      }}
                    >
                      <Edit2 size={16} />
                    </button>
                    <button
                      onClick={() => handleDelete(address.id)}
                      style={{
                        padding: "8px",
                        borderRadius: "6px",
                        border: "1px solid #fee2e2",
                        background: "#fff5f5",
                        cursor: "pointer",
                      }}
                    >
                      <Trash2 size={16} color="#dc2626" />
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ManageDeliveryAddresses;
