import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Lock, ArrowLeft } from "lucide-react";

const NewPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [settings, setSettings] = useState({
    websiteName: "",
    siteDescription: "",
    keywords: "",
  });

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/api/settings`
        );
        setSettings(response.data);
      } catch (error) {
        console.error("Error fetching settings from the database:", error);
      }
    };

    fetchSettings();
  }, []);

  useEffect(() => {
    const token = searchParams.get("token");
    if (!token) {
      setError("Invalid password reset link.");
    }
  }, [searchParams]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    try {
      const token = searchParams.get("token");
      await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/reset-password/` + token,
        { newPassword: password }
      );
      setSuccess("Password reset successful. Redirecting to login...");
      setError("");
      setTimeout(() => navigate("/login"), 3000);
    } catch (error) {
      setError("Error resetting password. Please try again.");
      setSuccess("");
    }
  };

  const containerStyle = {
    minHeight: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "2rem",
    background: "#f8fafc",
  };

  const cardStyle = {
    textAlign: "left",
    width: "100%",
    maxWidth: "400px",
    background: "white",
    borderRadius: "1rem",
    boxShadow:
      "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
    padding: "2rem",
  };

  const formGroupStyle = {
    marginBottom: "1.5rem",
    position: "relative",
  };

  const inputContainerStyle = {
    position: "relative",
    display: "flex",
    alignItems: "center",
  };

  const inputStyle = {
    width: "100%",
    padding: "0.75rem 1rem 0.75rem 2.5rem",
    borderRadius: "0.5rem",
    border: "1px solid #e2e8f0",
    fontSize: "1rem",
    transition: "all 0.3s ease",
    outline: "none",
  };

  const inputIconStyle = {
    position: "absolute",
    left: "0.75rem",
    color: "#64748b",
  };

  const labelStyle = {
    display: "block",
    marginBottom: "0.5rem",
    color: "#4a5568",
    fontWeight: "500",
    textAlign: "left",
  };

  const buttonStyle = {
    width: "100%",
    padding: "0.75rem",
    borderRadius: "0.5rem",
    border: "none",
    background: "var(--primary-color)",
    color: "white",
    fontSize: "1rem",
    fontWeight: "500",
    cursor: "pointer",
    transition: "all 0.3s ease",
  };

  const backLinkStyle = {
    display: "inline-flex",
    alignItems: "center",
    gap: "0.5rem",
    color: "#64748b",
    textDecoration: "none",
    marginBottom: "1.5rem",
    fontSize: "0.875rem",
    transition: "color 0.3s ease",
  };

  return (
    <div style={containerStyle}>
      <Helmet>
        <meta name="description" content={settings.siteDescription} />
        <meta name="keywords" content={settings.keywords} />
        <meta name="author" content={settings.websiteName} />
        <meta property="og:title" content={settings.websiteName} />
        <meta property="og:description" content={settings.siteDescription} />
        <meta
          property="og:image"
          content={`${settings.serverUrl}${settings.logoUrl}`}
        />
        <meta property="og:url" content={settings.baseUrl} />
        <meta property="og:type" content="website" />
        <title>{`New Password | ${settings.websiteName}`}</title>
      </Helmet>

      <div style={cardStyle}>
        <a
          href="/login"
          style={backLinkStyle}
          onMouseEnter={(e) => {
            e.target.style.color = "#1e293b";
          }}
          onMouseLeave={(e) => {
            e.target.style.color = "#64748b";
          }}
        >
          <ArrowLeft size={16} />
          Back to login
        </a>

        <h1
          style={{
            fontSize: "1.875rem",
            fontWeight: "600",
            marginBottom: "0.5rem",
            color: "#1e293b",
          }}
        >
          Set new password
        </h1>
        <p
          style={{
            color: "#64748b",
            marginBottom: "2rem",
          }}
        >
          Enter your new password below
        </p>

        {error && (
          <div
            style={{
              padding: "0.75rem",
              marginBottom: "1.5rem",
              borderRadius: "0.5rem",
              background: "#fee2e2",
              color: "#991b1b",
              fontSize: "0.875rem",
            }}
          >
            {error}
          </div>
        )}

        {success && (
          <div
            style={{
              padding: "0.75rem",
              marginBottom: "1.5rem",
              borderRadius: "0.5rem",
              background: "#dcfce7",
              color: "#166534",
              fontSize: "0.875rem",
            }}
          >
            {success}
          </div>
        )}

        <form onSubmit={handleSubmit}>
          <div style={formGroupStyle}>
            <label style={labelStyle}>New Password</label>
            <div style={inputContainerStyle}>
              <Lock size={18} style={inputIconStyle} />
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                placeholder="Enter new password"
                style={inputStyle}
              />
            </div>
          </div>

          <div style={formGroupStyle}>
            <label style={labelStyle}>Confirm Password</label>
            <div style={inputContainerStyle}>
              <Lock size={18} style={inputIconStyle} />
              <input
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
                placeholder="Confirm new password"
                style={inputStyle}
              />
            </div>
          </div>

          <button
            type="submit"
            style={buttonStyle}
            onMouseEnter={(e) => {
              e.target.style.opacity = "0.9";
            }}
            onMouseLeave={(e) => {
              e.target.style.opacity = "1";
            }}
          >
            Reset Password
          </button>
        </form>
      </div>
    </div>
  );
};

export default NewPassword;
