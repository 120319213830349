import React, { useState, useEffect, useContext, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import "./css/Checkout.css";
import { UserContext } from "../UserContext";
import { Helmet } from "react-helmet";

const Checkout = () => {
  const { user } = useContext(UserContext);
  const location = useLocation();
  const navigate = useNavigate();
  const { cartItems = [] } = location.state || {};
  const [deliveryMethod, setDeliveryMethod] = useState("pickup");
  const [pickupAddresses, setPickupAddresses] = useState([]);
  const [expandedCountries, setExpandedCountries] = useState({});
  const [expandedLocations, setExpandedLocations] = useState({});
  const [placeInput, setPlaceInput] = useState(null); // Initialize as null instead of empty object
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [homeDeliveryAddress, setHomeDeliveryAddress] = useState("");
  const [homeDeliveryAddressCoordinates, setHomeDeliveryAddressCoordinates] =
    useState("");
  const [deliveryInstructions, setDeliveryInstructions] = useState("");
  const [loadingUser, setLoadingUser] = useState(true);
  const [showCurrentLocation, setShowCurrentLocation] = useState(false);
  const [settings, setSettings] = useState({
    costPerKilometer: 0,
    minimumCharge: 0,
    storeLocation: { lat: -1.286389, lng: 36.817223 },
  });

  const [settingsMain, setSettingsMain] = useState({
    host: "",
    port: "",
    username: "",
    password: "",
    phones: "",
    currency: "",
    websiteName: "",
    baseUrl: "",
    keywords: "",
    siteDescription: "",
  });

  const [deliveryCost, setDeliveryCost] = useState(0);
  const [marker, setMarker] = useState(null);
  const [autocomplete, setAutocomplete] = useState(null);
  let [geocoder, setGeocoder] = useState(null);
  const [loading, setLoading] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    if (user === null && !loadingUser) {
      window.scrollTo(0, 0);
      navigate("/login");
    }
  }, [user, navigate, loadingUser]);

  useEffect(() => {
    if (user !== undefined) {
      setLoadingUser(false);
    }
  }, [user]);

  useEffect(() => {
    const fetchSettings1 = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/api/settings`
        );
        setSettingsMain(response.data || {});
      } catch (error) {
        console.error("Error fetching SMTP settings:", error);
      }
    };

    fetchSettings1();
  }, []);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/api/store-location-settings`
        );
        setSettings(response.data);
      } catch (error) {
        console.error("Error fetching settings:", error);
      }
    };

    const fetchPickupAddresses = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/api/delivery-addresses`
        );
        setPickupAddresses(response.data);
      } catch (error) {
        console.error("Error fetching delivery addresses:", error);
      }
    };

    fetchSettings();
    fetchPickupAddresses();
  }, []);

  useEffect(() => {
    if (deliveryMethod === "home") {
      if (window.google && window.google.maps) {
        console.log("Google Maps already loaded, initializing map");
        initializeMap();
        return;
      }

      const loadGoogleMapsScript = async () => {
        console.log("LOADING SCRIPT - Creating script element");
        try {
          const script = document.createElement("script");
          script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_PLACES_API_KEY}&v=beta`;
          script.async = true;

          const scriptLoadPromise = new Promise((resolve, reject) => {
            script.onload = resolve;
            script.onerror = reject;
          });

          document.head.appendChild(script);
          await scriptLoadPromise;

          await window.google.maps.importLibrary("places");
          console.log("SCRIPT AND LIBRARIES LOADED - Calling initializeMap");
          initializeMap();
        } catch (error) {
          console.error("Error loading Google Maps:", error);
        }
      };

      loadGoogleMapsScript();
    }
  }, [deliveryMethod]);

  // Add this at the top with your other state declarations
  const markerRef = useRef(null);

  // Then modify the updateMarker function
  const updateMarker = (position) => {
    // Clear existing marker if any
    if (markerRef.current) {
      markerRef.current.setMap(null);
    }

    // Create new marker
    const newMarker = new window.google.maps.Marker({
      position: position,
      map: window.map,
      draggable: true,
    });

    // Add drag listener
    newMarker.addListener("dragend", handleMarkerDragEnd);

    // Update both ref and state
    markerRef.current = newMarker;
    setMarker(newMarker);
  };

  const initializeMap = () => {
    console.log("INITIALIZE MAP START");

    const mapInstance = new window.google.maps.Map(
      document.getElementById("map"),
      {
        center: { lat: -1.2921, lng: 36.8219 },
        zoom: 8,
      }
    );
    window.map = mapInstance;

    const geocoderInstance = new window.google.maps.Geocoder();
    setGeocoder(geocoderInstance);

    // Create the PlaceAutocompleteElement
    const placeAutocomplete =
      new window.google.maps.places.PlaceAutocompleteElement();

    // Get the input container
    const inputContainer = document.querySelector(".pac-input").parentElement;

    // Replace the existing input with the new PlaceAutocompleteElement
    inputContainer.innerHTML = ""; // Clear existing input
    inputContainer.appendChild(placeAutocomplete);

    // Add event listener for place selection
    placeAutocomplete.addEventListener("gmp-placeselect", async ({ place }) => {
      setShowCurrentLocation(false);
      console.log("Place selected event fired");

      try {
        await place.fetchFields({
          fields: ["displayName", "formattedAddress", "location"],
        });

        const placeJson = place.toJSON();
        const location = placeJson.location;
        const address = placeJson.formattedAddress || placeJson.displayName;
        const newLocation = `${location.lat},${location.lng}`;
        const latLng = { lat: location.lat, lng: location.lng };

        setHomeDeliveryAddress(address);
        setHomeDeliveryAddressCoordinates(newLocation);

        if (window.map) {
          window.map.setCenter(latLng);
          window.map.setZoom(15);
          // Use updateMarker instead of manual marker handling
          updateMarker(latLng);
          calculateDeliveryCost(newLocation);
        }
      } catch (error) {
        console.error("Error processing place selection:", error);
      }
    });
    console.log("INITIALIZE MAP COMPLETE");
  };

  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371e3; // metres
    const φ1 = (lat1 * Math.PI) / 180;
    const φ2 = (lat2 * Math.PI) / 180;
    const Δφ = ((lat2 - lat1) * Math.PI) / 180;
    const Δλ = ((lon2 - lon1) * Math.PI) / 180;

    const a =
      Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const distance = R * c; // in metres
    return distance / 1000; // in kilometres
  };

  const handleDeliveryMethodChange = (e) => {
    setDeliveryMethod(e.target.value);
    setDeliveryCost(0);
    setSelectedAddress(null);
    setHomeDeliveryAddress("");
  };

  const handlePickupAddressChange = (cost, address) => {
    setDeliveryCost(cost);
    setSelectedAddress(address);
  };

  /*
  const handleMapClick = (e) => {
    const newLocation = `${e.latLng.lat()},${e.latLng.lng()}`;
    setHomeDeliveryAddress(newLocation);
    if (marker) {
      marker.setPosition(e.latLng);
    } else {
      const newMarker = new window.google.maps.Marker({
        position: e.latLng,
        map: window.map,
        draggable: true,
      });
      newMarker.addListener('dragend', handleMarkerDragEnd);
      setMarker(newMarker);
    }
    calculateDeliveryCost(newLocation);
  };
  */

  const handleMarkerDragEnd = (e) => {
    const newLocation = `${e.latLng.lat()},${e.latLng.lng()}`;
    setHomeDeliveryAddressCoordinates(newLocation);

    if (geocoder) {
      geocoder.geocode(
        { location: { lat: e.latLng.lat(), lng: e.latLng.lng() } },
        (results, status) => {
          if (status === "OK" && results[0]) {
            const address = results[0].formatted_address;
            setHomeDeliveryAddress(address);

            // Update the input inside PlaceAutocompleteElement

            if (placeInput) {
              placeInput.value = address;
            }
          } else {
            console.error("Geocoder failed due to: " + status);
          }
        }
      );
    }

    calculateDeliveryCost(newLocation);
  };

  const handlePlaceSelect = (place) => {
    console.log("Handle place select called with:", place);

    if (!place || !place.geometry) {
      console.warn("Invalid place object received");
      return;
    }

    try {
      const location = place.geometry.location;
      const newLocation = `${location.lat()},${location.lng()}`;
      const address = place.formatted_address || place.name || "";

      console.log("Setting new location:", {
        address,
        coordinates: newLocation,
      });

      setHomeDeliveryAddress(address);
      setHomeDeliveryAddressCoordinates(newLocation);

      if (window.map) {
        window.map.setCenter(location);
        window.map.setZoom(15);

        if (marker) {
          marker.setPosition(location);
        } else {
          const newMarker = new window.google.maps.Marker({
            position: location,
            map: window.map,
            draggable: true,
          });
          newMarker.addListener("dragend", handleMarkerDragEnd);
          setMarker(newMarker);
        }

        calculateDeliveryCost(newLocation);
      }
    } catch (error) {
      console.error("Error in handlePlaceSelect:", error);
    }
  };

  const handleHomeDeliveryAddressChange = (e) => {
    setHomeDeliveryAddress(e.target.value);
  };

  const handleUseCurrentLocation = () => {
    if (navigator.geolocation) {
      setLoading(true);
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          if (!geocoder) {
            geocoder = new window.google.maps.Geocoder();
            setGeocoder(geocoder);
          }
          geocoder.geocode({ location: pos }, (results, status) => {
            setLoading(false);
            if (status === "OK" && results[0]) {
              const placeName = results[0].formatted_address;
              const newLocation = `${pos.lat},${pos.lng}`;

              // Update state
              setHomeDeliveryAddress(placeName);
              setShowCurrentLocation(true);
              setHomeDeliveryAddressCoordinates(newLocation);

              // Update map and marker
              window.map.setCenter(pos);
              window.map.setZoom(15);
              updateMarker(pos);

              // Update the PlaceAutocompleteElement input

              if (placeInput) {
                placeInput.value = placeName;
              } else {
                console.error("Could not find place autocomplete input");
              }

              calculateDeliveryCost(newLocation);
            } else {
              console.error(
                "Geocode was not successful for the following reason: " + status
              );
            }
          });
        },
        (error) => {
          setLoading(false);
          console.error("Error getting current location: ", error);
        }
      );
    }
  };
  const calculateDeliveryCost = (location) => {
    const [lat, lng] = location.split(",").map(Number);
    const [storeLocationLat, storeLocationLng] = settings.storeLocation
      .split(",")
      .map(Number);
    const distance = calculateDistance(
      storeLocationLat,
      storeLocationLng,
      lat,
      lng
    );

    let cost = distance * settings.costPerKilometer;
    if (cost < settings.minimumCharge) {
      cost = settings.minimumCharge;
    }

    setDeliveryCost(Math.ceil(cost));
  };

  const groupPickupAddresses = () => {
    return pickupAddresses.reduce((acc, address) => {
      const { country, generalLocation, specificLocation, pickupCost } =
        address;
      if (!acc[country]) acc[country] = {};
      if (!acc[country][generalLocation]) acc[country][generalLocation] = [];
      acc[country][generalLocation].push({ specificLocation, pickupCost });
      return acc;
    }, {});
  };

  const toggleCountry = (country) => {
    setExpandedCountries((prev) => ({
      ...prev,
      [country]: !prev[country],
    }));
  };

  const toggleLocation = (country, location) => {
    setExpandedLocations((prev) => ({
      ...prev,
      [`${country}-${location}`]: !prev[`${country}-${location}`],
    }));
  };

  const groupedPickupAddresses = groupPickupAddresses();

  const getTotalAmount = () => {
    const productCost = (cartItems || []).reduce(
      (total, item) => total + item.price * item.quantity,
      0
    );
    return {
      productCost,
      totalAmount: productCost + deliveryCost,
    };
  };

  const { productCost, totalAmount } = getTotalAmount();
  const isProceedDisabled = () => {
    if (deliveryMethod === "home") {
      return !homeDeliveryAddress || !deliveryInstructions;
    }
    return !selectedAddress;
  };

  return (
    <div className="checkout-container container" align="left">
      <Helmet>
        <meta name="description" content={settingsMain.siteDescription} />
        <meta name="keywords" content={settingsMain.keywords} />
        <meta name="author" content={settingsMain.websiteName} />
        <meta property="og:title" content={settingsMain.websiteName} />
        <meta
          property="og:description"
          content={settingsMain.siteDescription}
        />
        <meta
          property="og:image"
          content={`${settingsMain.serverUrl}${settings.logoUrl}`}
        />
        <meta property="og:url" content={settingsMain.baseUrl} />
        <meta property="og:type" content="website" />
        <title>{`Delivery methods | ${settingsMain.websiteName}`}</title>
      </Helmet>
      <h3 className="checkout-title">Delivery information</h3>
      <div className="form-group">
        <label className="checkout-input-label">
          <b>Delivery Method</b>
        </label>
        <select
          className="form-control"
          value={deliveryMethod}
          onChange={handleDeliveryMethodChange}
        >
          <option value="pickup">Pickup Station</option>
          <option value="home">Home Delivery</option>
        </select>
      </div>
      {deliveryMethod === "pickup" && (
        <div className="form-group">
          <label className="checkout-input-label">
            <b>Pickup Station</b>
          </label>
          <div className="pickup-stations">
            {Object.keys(groupedPickupAddresses).map((country) => (
              <div key={country} className="country-group">
                <b
                  onClick={() => toggleCountry(country)}
                  className="country-title"
                >
                  {country}{" "}
                  <span className="chevron">
                    {expandedCountries[country] ? "▲" : "▼"}
                  </span>
                </b>
                {expandedCountries[country] && (
                  <div className="locations">
                    {Object.keys(groupedPickupAddresses[country]).map(
                      (city) => (
                        <div key={city} className="city-group">
                          <span
                            onClick={() => toggleLocation(country, city)}
                            className="city-title"
                          >
                            {city}{" "}
                            <span className="chevron">
                              {expandedLocations[`${country}-${city}`]
                                ? "▲"
                                : "▼"}
                            </span>
                          </span>
                          {expandedLocations[`${country}-${city}`] && (
                            <div className="specific-locations">
                              {groupedPickupAddresses[country][city].map(
                                (loc, index) => (
                                  <div
                                    key={index}
                                    className="specific-location"
                                  >
                                    <input
                                      placeholder="Search your location"
                                      className="pickup-radio"
                                      type="radio"
                                      name="pickupLocation"
                                      value={loc.pickupCost}
                                      onChange={() =>
                                        handlePickupAddressChange(
                                          loc.pickupCost,
                                          loc.specificLocation
                                        )
                                      }
                                    />
                                    {loc.specificLocation} @
                                    {settingsMain.currency}
                                    {loc.pickupCost}
                                  </div>
                                )
                              )}
                            </div>
                          )}
                        </div>
                      )
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
      {deliveryMethod === "home" && (
        <div className="home-delivery-section">
          <p>
            Please use the map below to set your delivery location. You can
            search for a location, click on the map, or drag the marker to the
            desired location.
          </p>
          {loading && (
            <div className="fullscreen-loader">
              <div className="loader-content">
                <p>
                  Getting location coordinates, this may take a while please
                  wait...
                </p>
              </div>
            </div>
          )}
          <button
            className="btn btn-secondary-store"
            onClick={handleUseCurrentLocation}
          >
            Use My Current Location
          </button>

          <p>Or search your location below:</p>
          <div className="form-group">
            <label className="checkout-input-label">
              <b>Search location</b>
            </label>
            <input
              id="pac-input"
              ref={inputRef}
              className="controls pac-input"
              type="text"
              placeholder="Search location here..."
              onChange={handleHomeDeliveryAddressChange}
            />
          </div>
          {showCurrentLocation && (
            <div className="text-muted mt-1">
              <b>
                Your current location: <u>{homeDeliveryAddress}</u>
              </b>
            </div>
          )}
          <div id="map" className="store-location-map"></div>
          <div className="form-group">
            <label
              className="checkout-input-label"
              htmlFor="deliveryInstructions"
            >
              <b>
                Detailed Delivery Instructions (e.g., Building Name, Apartment,
                Landmarks, Specific Directions)
              </b>
            </label>
            <textarea
              id="deliveryInstructions"
              className="form-control"
              value={deliveryInstructions}
              onChange={(e) => setDeliveryInstructions(e.target.value)}
              required
            />
          </div>
        </div>
      )}
      <div className="checkout-summary mt-3">
        <h5 className="cost-summary">
          Products Costs: {settingsMain.currency} {productCost.toLocaleString()}
        </h5>
        <h5 className="cost-summary">
          Delivery Costs: {settingsMain.currency}{" "}
          {deliveryCost.toLocaleString()}
        </h5>
        <h5 className="cost-summary">
          <b>
            Total Costs: {settingsMain.currency} {totalAmount.toLocaleString()}
          </b>
        </h5>
      </div>
      <button
        className="checkout-payment btn btn-primary mt-3"
        onClick={() =>
          navigate("/payment", {
            state: {
              totalAmount,
              productCost,
              deliveryMethod,
              deliveryCost,
              deliveryAddress: selectedAddress || homeDeliveryAddress,
              deliveryCoordinates: homeDeliveryAddressCoordinates,
              deliveryInstructions,
              cartItems,
            },
          })
        }
        disabled={isProceedDisabled()}
      >
        Proceed to Payment
      </button>
    </div>
  );
};

export default Checkout;
