import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { Lock, Mail, AlertCircle } from "lucide-react";
import ReCAPTCHA from "react-google-recaptcha";

const AdminLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [captchaToken, setCaptchaToken] = useState("");
  const navigate = useNavigate();

  const styles = {
    container: {
      minHeight: "100vh",
      background: "#f8f8f8",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "20px",
    },
    card: {
      width: "100%",
      maxWidth: "420px",
      backgroundColor: "white",
      borderRadius: "20px",
      padding: "40px",
      boxShadow: "0 10px 30px rgba(0, 0, 0, 0.1)",
      border: "1px solid rgba(255, 255, 255, 0.2)",
    },
    header: {
      textAlign: "center",
      marginBottom: "40px",
    },
    title: {
      fontSize: "28px",
      fontWeight: "700",
      color: "#1a1a1a",
      marginBottom: "10px",
    },
    subtitle: {
      fontSize: "16px",
      color: "#666",
      marginTop: "8px",
    },
    errorContainer: {
      backgroundColor: "#FEE2E2",
      borderLeft: "4px solid #EF4444",
      borderRadius: "8px",
      padding: "16px",
      marginBottom: "24px",
      display: "flex",
      alignItems: "center",
      gap: "12px",
    },
    errorText: {
      color: "#DC2626",
      fontSize: "14px",
    },
    formGroup: {
      marginBottom: "24px",
    },
    label: {
      display: "block",
      fontSize: "14px",
      fontWeight: "600",
      color: "#374151",
      marginBottom: "8px",
      textAlign: "left",
    },
    inputWrapper: {
      position: "relative",
    },
    input: {
      width: "100%",
      padding: "14px 14px 14px 45px",
      borderRadius: "12px",
      border: "1px solid #E5E7EB",
      fontSize: "15px",
      transition: "all 0.3s ease",
      backgroundColor: "#F9FAFB",
    },
    icon: {
      position: "absolute",
      left: "16px",
      top: "50%",
      transform: "translateY(-50%)",
      color: "#9CA3AF",
    },
    forgotPassword: {
      display: "block",
      textAlign: "right",
      fontSize: "14px",
      color: "#2563EB",
      textDecoration: "none",
      marginTop: "12px",
      marginBottom: "24px",
      fontWeight: "500",
      transition: "color 0.3s ease",
    },
    button: {
      width: "100%",
      padding: "14px",
      borderRadius: "12px",
      backgroundColor: "#2563EB",
      color: "white",
      fontSize: "16px",
      fontWeight: "500",
      border: "none",
      cursor: "pointer",
      transition: "all 0.3s ease",
      position: "relative",
      overflow: "hidden",
    },
    buttonHover: {
      backgroundColor: "#1D4ED8",
    },
    loadingButton: {
      opacity: "0.7",
      cursor: "not-allowed",
    },
    captchaContainer: {
      marginBottom: "24px",
      display: "flex",
      justifyContent: "center",
    },
  };

  const handleCaptchaChange = (token) => {
    setCaptchaToken(token);
    setError(""); // Clear any existing errors when captcha is completed
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    if (!captchaToken) {
      setError("Please complete the captcha");
      return;
    }

    setIsLoading(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/admin/login`,
        { email, password, captchaToken }
      );

      if (response.data.token) {
        localStorage.setItem("adminToken", response.data.token);
        navigate("/admin/dashboard");
      }
    } catch (error) {
      setError(error.response?.data?.error || "An error occurred during login");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.card}>
        <div style={styles.header}>
          <h2 style={styles.title}>Admin Login</h2>
          <p style={styles.subtitle}>Sign in to access the admin panel</p>
        </div>

        {error && (
          <div style={styles.errorContainer}>
            <AlertCircle size={20} color="#DC2626" />
            <p style={styles.errorText}>{error}</p>
          </div>
        )}

        <form onSubmit={handleSubmit}>
          <div style={styles.formGroup}>
            <label style={styles.label}>Email Address</label>
            <div style={styles.inputWrapper}>
              <Mail size={20} style={styles.icon} />
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                style={styles.input}
                placeholder="Enter your email"
                required
              />
            </div>
          </div>

          <div style={styles.formGroup}>
            <label style={styles.label}>Password</label>
            <div style={styles.inputWrapper}>
              <Lock size={20} style={styles.icon} />
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                style={styles.input}
                placeholder="Enter your password"
                required
              />
            </div>
          </div>

          <Link
            to="/admin/reset-password"
            style={styles.forgotPassword}
            onMouseEnter={(e) => (e.target.style.color = "#1D4ED8")}
            onMouseLeave={(e) => (e.target.style.color = "#2563EB")}
          >
            Forgot password?
          </Link>

          <div style={styles.captchaContainer}>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_V2_CAPTCHA_API_KEY}
              onChange={handleCaptchaChange}
            />
          </div>

          <button
            type="submit"
            disabled={isLoading}
            style={{
              ...styles.button,
              ...(isLoading ? styles.loadingButton : {}),
            }}
            onMouseEnter={(e) =>
              !isLoading && (e.target.style.backgroundColor = "#1D4ED8")
            }
            onMouseLeave={(e) =>
              !isLoading && (e.target.style.backgroundColor = "#2563EB")
            }
          >
            {isLoading ? "Signing in..." : "Sign in"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default AdminLogin;
