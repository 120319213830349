import React, { useEffect, useState } from "react";
import axios from "axios";
import "./css/ProductList.css";
import { useNavigate } from "react-router-dom";

const TopSelling = ({ onAddToCart, onRemoveFromCart, cartItems }) => {
  const [topProducts, setTopProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [settings, setSettings] = useState({
    phones: "",
    currency: "",
  });
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/api/settings`
        );
        setSettings(response.data);
      } catch (error) {
        console.error("Error fetching settings from the database:", error);
      }
    };

    fetchSettings();
  }, []);
  useEffect(() => {
    const fetchTopProducts = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/api/products/top-selling`
        );
        setTopProducts(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching top products:", error);
        setError(error.message);
        setLoading(false);
      }
    };

    fetchTopProducts();
  }, []);

  const handleAddToCartClick = (product) => {
    onAddToCart(product);
  };

  const handleCardClick = (productId) => {
    navigate(`/product/${productId}`);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;
  if (topProducts.length === 0) return <p>No top selling products found.</p>;

  return (
    <div>
      <h6 className="mb-4 no-margin top-selling-title" align="left">
        Top Selling Products
      </h6>
      <div className="top-selling-container">
        <div className="container">
          <div className="row">
            {topProducts.map((product) => {
              const inCart = cartItems.some((item) => item.id === product.id);
              return (
                <div
                  className="col-md-4 no-padding"
                  key={product.id}
                  style={{
                    margin: "0px !important",
                  }}
                >
                  <div
                    className="mb-4"
                    onClick={() => handleCardClick(product.id)}
                    style={{
                      cursor: "pointer",
                      marginBottom: "0px !important",
                    }}
                  >
                    <div className="position-relative card">
                      <img
                        src={
                          product.images && product.images[0]
                            ? `${process.env.REACT_APP_SERVER_URL}${product.images[0]}`
                            : "default-image-path"
                        }
                        className="card-img-top"
                        alt={product.name}
                      />
                      {product.discount > 0 && (
                        <span className="badge badge-discount position-absolute top-0 end-0">
                          -{Math.ceil(product.discount)}%
                        </span>
                      )}
                    </div>
                    <div align="left" className="card-body">
                      <h5 className="card-title text-truncate product-name-top-selling">
                        {product.name}
                      </h5>
                      {/* <p className="product-condition">{product.condition}</p>*/}
                      <p className="card-text">
                        <span className="price-new product-new-price-top-selling">
                          {settings.currency}{" "}
                          {Number(product.price).toLocaleString()}
                        </span>
                        {product.originalPrice &&
                          product.originalPrice !== product.price && (
                            <span className="price-old product-old-price-top-selling">
                              {settings.currency}{" "}
                              {Number(product.originalPrice).toLocaleString()}
                            </span>
                          )}
                      </p>
                      {/*
                      {product.stock === 0 ? (
                        <p
                          className="out-of-stock-text"
                          style={{ color: "red" }}
                        >
                          <b>Out of stock</b>
                        </p>
                      ) : inCart ? (
                        <button
                          className="btn btn-danger d-flex justify-content-center align-items-center"
                          onClick={(e) => {
                            e.stopPropagation();
                            onRemoveFromCart(product.id);
                          }}
                          style={{ width: "100%" }}
                        >
                          <span className="material-icons me-1">
                            remove_shopping_cart
                          </span>{" "}
                          Remove from Cart
                        </button>
                      ) : (
                        <button
                          className="btn btn-primary d-flex justify-content-center align-items-center"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleAddToCartClick(product);
                          }}
                          style={{ width: "100%" }}
                        >
                            
                          <span className="material-icons me-1">
                            add_shopping_cart
                          </span>{" "}
                          Add to Cart
                        </button>
                      )}
                      */}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopSelling;
