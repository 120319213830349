import React, { useState, useEffect } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import { Mail, Server, Key, Lock, Save } from "lucide-react";

const SMTPSettings = () => {
  // State
  const [settings, setSettings] = useState({
    websiteName: "",
    siteDescription: "",
    keywords: "",
    baseUrl: "",
    logoUrl: "",
  });

  const [SMTPsettings, setSMTPSettings] = useState({
    host: "",
    port: "",
    username: "",
    password: "",
  });

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/api/settings`
        );
        setSettings(response.data || {});
      } catch (error) {
        console.error("Error fetching settings:", error);
      }
    };

    const fetchSMTPSettings = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/api/smtp/settings`
        );
        setSMTPSettings(response.data || {});
      } catch (error) {
        console.error("Error fetching SMTP settings:", error);
      }
    };

    fetchSettings();
    fetchSMTPSettings();
  }, []);

  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState({ type: "", message: "" });

  // Styles
  const cardStyle = {
    background: "white",
    borderRadius: "12px",
    padding: "24px",
    marginBottom: "24px",
    boxShadow: "0 2px 12px rgba(0,0,0,0.1)",
    border: "1px solid #eee",
  };

  const inputGroupStyle = {
    marginBottom: "20px",
  };

  const labelStyle = {
    display: "block",
    marginBottom: "8px",
    fontWeight: "500",
    color: "#2c3e50",
    fontSize: "14px",
    textAlign: "left",
  };

  const inputStyle = {
    width: "100%",
    padding: "12px",
    borderRadius: "8px",
    border: "1px solid #e0e0e0",
    fontSize: "14px",
    transition: "border-color 0.3s ease",
  };

  const buttonStyle = {
    padding: "12px 24px",
    borderRadius: "8px",
    border: "none",
    backgroundColor: "#2c3e50",
    color: "white",
    fontWeight: "500",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    gap: "8px",
    transition: "background 0.3s ease",
  };

  // Effects
  useEffect(() => {
    fetchSettings();
  }, []);

  // Handlers
  const fetchSettings = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/smtp/settings`
      );
      setSettings((prev) => ({
        ...prev,
        ...response.data,
      }));
    } catch (error) {
      console.error("Error fetching SMTP settings:", error);
      setStatus({
        type: "error",
        message: "Failed to load SMTP settings",
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSettings((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await axios.put(`${process.env.REACT_APP_SERVER_URL}/api/smtp/settings`, {
        host: settings.host,
        port: parseInt(settings.port),
        username: settings.username,
        password: settings.password,
      });

      setStatus({
        type: "success",
        message: "SMTP settings updated successfully",
      });
    } catch (error) {
      console.error("Error updating SMTP settings:", error);
      setStatus({
        type: "error",
        message: "Failed to update SMTP settings",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ margin: "0 auto", padding: "40px 20px" }}>
      <Helmet>
        <title>{`SMTP Settings | ${settings.websiteName}`}</title>
        <meta name="description" content={settings.siteDescription} />
        <meta name="keywords" content={settings.keywords} />
      </Helmet>

      <div style={cardStyle}>
        {status.message && (
          <div
            style={{
              padding: "12px",
              borderRadius: "8px",
              marginBottom: "20px",
              backgroundColor:
                status.type === "success" ? "#e3fcef" : "#fee2e2",
              color: status.type === "success" ? "#059669" : "#dc2626",
            }}
          >
            {status.message}
          </div>
        )}

        <form onSubmit={handleSubmit}>
          <div style={inputGroupStyle}>
            <label style={labelStyle}>
              <Mail
                size={16}
                style={{
                  marginRight: "8px",
                  verticalAlign: "middle",
                  textAlign: "left",
                }}
              />
              SMTP Host
            </label>
            <input
              type="text"
              name="host"
              value={SMTPsettings.host}
              onChange={handleChange}
              style={inputStyle}
              placeholder="e.g., smtp.gmail.com"
              required
            />
          </div>

          <div style={inputGroupStyle}>
            <label style={labelStyle}>
              <Server
                size={16}
                style={{
                  marginRight: "8px",
                  verticalAlign: "middle",
                  textAlign: "left",
                }}
              />
              SMTP Port
            </label>
            <input
              type="number"
              name="port"
              value={SMTPsettings.port}
              onChange={handleChange}
              style={inputStyle}
              placeholder="e.g., 587"
              required
            />
          </div>

          <div style={inputGroupStyle}>
            <label style={labelStyle}>
              <Key
                size={16}
                style={{
                  marginRight: "8px",
                  verticalAlign: "middle",
                  textAlign: "left",
                }}
              />
              SMTP Username
            </label>
            <input
              type="text"
              name="username"
              value={SMTPsettings.username}
              onChange={handleChange}
              style={inputStyle}
              placeholder="Enter SMTP username"
              required
            />
          </div>

          <div style={inputGroupStyle}>
            <label style={labelStyle}>
              <Lock
                size={16}
                style={{
                  marginRight: "8px",
                  verticalAlign: "middle",
                  textAlign: "left",
                }}
              />
              SMTP Password
            </label>
            <input
              type="password"
              name="password"
              value={SMTPsettings.password}
              onChange={handleChange}
              style={inputStyle}
              placeholder="Enter SMTP password"
              required
            />
          </div>

          <button type="submit" style={buttonStyle} disabled={loading}>
            <Save size={18} />
            {loading ? "Saving..." : "Save Settings"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default SMTPSettings;
