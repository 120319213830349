import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import successIcon from './assets/success-icon.png'; // Ensure you have the icon in the specified path
import './css/PaymentSuccess.css'; // Create a new CSS file for styling this component

const PaymentSuccess = () => {
  const location = useLocation();
  const { orderNumber } = location.state || {};
  const [settings, setSettings] = useState({
    phones: '',
    currency: '',
    websiteName: '',
    baseUrl: '',
    keywords: '',
    siteDescription: '',
  });

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/settings`);
        setSettings(response.data);
      } catch (error) {
        console.error('Error fetching settings from the database:', error);
      }
    };

    fetchSettings();
  }, []);

  return (
    <div className="payment-success-container container text-center">
      <Helmet>
        <title>Payment Success | {settings.websiteName}</title>
      </Helmet>
      <div className="success-icon-container">
        <img src={successIcon} alt="Success Icon" className="success-icon" />
      </div>
      <h1 className="success-title">Payment Successful!</h1>
      <p className="success-message">We have received your payment.</p>
      <p className="order-number">Your order number is <strong>#{orderNumber}</strong>.</p>
      <p className="thank-you-message">Thank you for shopping with us!</p>
      <a href="/" className="btn btn-primary mt-3">Continue Shopping</a>
    </div>
  );
};

export default PaymentSuccess;
