import './css/Cart.css';
import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { UserContext } from '../UserContext';
import { useNavigate } from 'react-router-dom';

const Cart = ({ cartItems, handleRemoveFromCart, handleQuantityChange }) => {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const [settings, setSettings] = useState({
    phones: '',
    currency: '',
    websiteName: '',
    baseUrl: '',
    keywords: '',
    siteDescription: '',
  });

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/settings`);
        setSettings(response.data);
      } catch (error) {
        console.error('Error fetching settings from the database:', error);
      }
    };

    fetchSettings();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getTotalAmount = () => {
    return cartItems.reduce((total, item) => total + item.price * item.quantity, 0);
  };

  const handleInputChange = (e, item) => {
    const value = parseInt(e.target.value, 10);
    if (value > 0 && value <= item.stock) {
      handleQuantityChange(item.id, value);
    }
  };

  const handleQuantityUpdate = (item, delta) => {
    const newQuantity = item.quantity + delta;
    if (newQuantity > 0 && newQuantity <= item.stock) {
      handleQuantityChange(item.id, newQuantity);
    }
  };

  const handleCheckout = () => {
    if (user) {
      window.scrollTo(0, 0);
      navigate('/checkout', { state: { cartItems } });
    } else {
      window.scrollTo(0, 0);
      navigate('/login');
    }
  };

  return (
    <div className="cart-container container">
      <Helmet>
        <meta name="description" content={settings.siteDescription} />
        <meta name="keywords" content={settings.keywords} />
        <meta name="author" content={settings.websiteName} />
        <meta property="og:title" content={settings.websiteName} />
        <meta property="og:description" content={settings.siteDescription} />
        <meta property="og:image" content={`${settings.serverUrl}${settings.logoUrl}`} />
        <meta property="og:url" content={settings.baseUrl} />
        <meta property="og:type" content="website" />
        <title>{`Cart | ${settings.websiteName}`}</title>
      </Helmet>
      <h3 className="cart-title">Shopping Cart</h3>
      {cartItems.length === 0 ? (
        <p className="cart-empty">Your cart is empty.</p>
      ) : (
        <div>
          <ul className="cart-list list-group" align="left">
            {cartItems.map(item => (
              <li key={item.id} className="cart-list-item list-group-item d-flex flex-column flex-md-row align-items-center">
                <div className="cart-item-image mb-3 mb-md-0">
                  <img
                    src={item.images && item.images[0] ? `${process.env.REACT_APP_SERVER_URL}${item.images[0]}` : 'default-image-path'}
                    alt={item.name}
                    className="img-fluid"
                    style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                  />
                </div>
                <div className="cart-item-details ms-md-3 mb-3 mb-md-0 text-center text-md-start">
                  <h4 className="cart-item-name my-0">{item.name}</h4>
                  <div className="d-flex justify-content-center justify-content-md-start align-items-center mt-2">
                    <button className="btn btn-outline-secondary btn-sm" onClick={() => handleQuantityUpdate(item, -1)}>-</button>
                    <input
                      align="center"
                      type="number"
                      className="cart-item-quantity form-control form-control-sm mx-2"
                      value={item.quantity}
                      onChange={(e) => handleInputChange(e, item)}
                      style={{ width: '60px' }}
                    />
                    <button className="btn btn-outline-secondary btn-sm" onClick={() => handleQuantityUpdate(item, 1)}>+</button>
                  </div>
                  <small className="cart-item-stock text-muted mt-2">{settings.currency} {(item.price * item.quantity).toLocaleString()}</small>
                </div>
                <div className="ms-md-auto d-flex flex-column align-items-center align-items-md-end">
                  <span className="material-icons cart-item-remove" onClick={() => handleRemoveFromCart(item.id)} style={{ cursor: 'pointer'}}>
                    delete
                  </span>
                </div>
              </li>
            ))}
          </ul>
          <h5 className="cart-total mt-3"><b>Total: {settings.currency} {getTotalAmount().toLocaleString()}</b></h5>
          <button className="cart-checkout btn btn-primary mt-3" onClick={handleCheckout}>Checkout</button>
        </div>
      )}
    </div>
  );
};

export default Cart;
