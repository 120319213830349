import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "./css/StoreLocationSettings.css";
import { Helmet } from "react-helmet";
import {
  MapPin,
  Navigation,
  DollarSign,
  Save,
  AlertCircle,
} from "lucide-react";

const StoreLocationSettings = () => {
  const [settings, setSettings] = useState({
    storeLocationName: "",
    storeLocation: "",
    costPerKilometer: 0,
    minimumCharge: 0,
  });
  const [marker, setMarker] = useState(null);
  const [placeInput, setPlaceInput] = useState(null);
  let [geocoder, setGeocoder] = useState(null);
  const [loading, setLoading] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    const loadGoogleMapsScript = async () => {
      console.log("LOADING SCRIPT - Creating script element");
      try {
        const script = document.createElement("script");
        script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_PLACES_API_KEY}&v=beta`;
        script.async = true;

        const scriptLoadPromise = new Promise((resolve, reject) => {
          script.onload = resolve;
          script.onerror = reject;
        });

        document.head.appendChild(script);
        await scriptLoadPromise;

        await window.google.maps.importLibrary("places");
        console.log("SCRIPT AND LIBRARIES LOADED - Calling initializeMap");
        initializeMap();
      } catch (error) {
        console.error("Error loading Google Maps:", error);
      }
    };

    loadGoogleMapsScript();
  }, []);

  const markerRef = useRef(null);

  const updateMarker = (position) => {
    // Clear existing marker if any
    if (markerRef.current) {
      markerRef.current.setMap(null);
    }

    // Create new marker
    const newMarker = new window.google.maps.Marker({
      position: position,
      map: window.map,
      draggable: true,
    });

    // Add drag listener
    newMarker.addListener("dragend", handleMarkerDragEnd);

    // Update both ref and state
    markerRef.current = newMarker;
    setMarker(newMarker);
  };

  const [settingsMain, setSettingsMain] = useState({
    host: "",
    port: "",
    username: "",
    password: "",
    phones: "",
    currency: "",
    websiteName: "",
    baseUrl: "",
    keywords: "",
    siteDescription: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchSettings1 = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/api/settings`
        );
        setSettingsMain(response.data || {});
      } catch (error) {
        console.error("Error fetching main settings:", error);
      }
    };

    fetchSettings1();
  }, []);

  const fetchStoreLocationSettings = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/store-location-settings`
      );
      if (response.data && response.data.storeLocation) {
        setSettings(response.data);
        const [lat, lng] = response.data.storeLocation.split(",").map(Number);
        if (window.map) {
          const position = { lat, lng };
          window.map.setCenter(position);
          window.map.setZoom(15);
          updateMarker(position);
        }
      } else if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          window.map.setCenter(pos);
          window.map.setZoom(15);
          updateMarker(pos);
          setSettings((prev) => ({
            ...prev,
            storeLocation: `${pos.lat},${pos.lng}`,
          }));
        });
      }
    } catch (error) {
      console.error("Error fetching store location settings:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSettings((prev) => ({ ...prev, [name]: value }));
  };

  const handleMapClick = (e) => {
    const newLocation = `${e.latLng.lat()},${e.latLng.lng()}`;
    setSettings((prev) => ({ ...prev, storeLocation: newLocation }));
    updateMarker(e.latLng);
  };

  const handleMarkerDragEnd = (e) => {
    const newLocation = `${e.latLng.lat()},${e.latLng.lng()}`;
    setSettings((prev) => ({ ...prev, storeLocation: newLocation }));

    if (geocoder) {
      geocoder.geocode(
        { location: { lat: e.latLng.lat(), lng: e.latLng.lng() } },
        (results, status) => {
          if (status === "OK" && results[0]) {
            const address = results[0].formatted_address;
            setSettings((prev) => ({
              ...prev,
              storeLocationName: address,
            }));

            if (placeInput) {
              placeInput.value = address;
            }
          } else {
            console.error("Geocoder failed due to: " + status);
          }
        }
      );
    }
  };

  const handleUseCurrentLocation = () => {
    if (navigator.geolocation) {
      setLoading(true);
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          if (!geocoder) {
            geocoder = new window.google.maps.Geocoder();
            setGeocoder(geocoder);
          }
          geocoder.geocode({ location: pos }, (results, status) => {
            setLoading(false);
            if (status === "OK" && results[0]) {
              const placeName = results[0].formatted_address;
              const newLocation = `${pos.lat},${pos.lng}`;

              setSettings((prev) => ({
                ...prev,
                storeLocationName: placeName,
                storeLocation: newLocation,
              }));

              window.map.setCenter(pos);
              window.map.setZoom(15);
              updateMarker(pos);

              if (placeInput) {
                placeInput.value = placeName;
              }
            } else {
              console.error(
                "Geocode was not successful for the following reason: " + status
              );
            }
          });
        },
        (error) => {
          setLoading(false);
          console.error("Error getting current location: ", error);
        }
      );
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/api/store-location-settings`,
        settings
      );
      alert("Store location settings updated successfully.");
    } catch (error) {
      console.error("Error updating store location settings:", error);
    }
  };

  const initializeMap = () => {
    const mapInstance = new window.google.maps.Map(
      document.getElementById("map"),
      {
        center: { lat: -1.2921, lng: 36.8219 },
        zoom: 8,
      }
    );
    window.map = mapInstance;

    const geocoderInstance = new window.google.maps.Geocoder();
    setGeocoder(geocoderInstance);

    // Set up map click listener
    mapInstance.addListener("click", handleMapClick);

    // Create the PlaceAutocompleteElement
    const placeAutocomplete =
      new window.google.maps.places.PlaceAutocompleteElement();

    // Get just the input element
    const inputElement = document.querySelector(".pac-input");

    // Replace only the input element
    if (inputElement) {
      // Preserve the className from the original input
      placeAutocomplete.className = inputElement.className;
      placeAutocomplete.placeholder = "Search location here...";
      placeAutocomplete.classList.add("form-control");
      inputElement.replaceWith(placeAutocomplete);
    }

    // Add event listener for place selection
    placeAutocomplete.addEventListener("gmp-placeselect", async ({ place }) => {
      console.log("Place selected event fired");

      try {
        await place.fetchFields({
          fields: ["displayName", "formattedAddress", "location"],
        });

        const placeJson = place.toJSON();
        const location = placeJson.location;
        const address = placeJson.formattedAddress || placeJson.displayName;
        const newLocation = `${location.lat},${location.lng}`;
        const latLng = { lat: location.lat, lng: location.lng };

        setSettings((prev) => ({
          ...prev,
          storeLocationName: address,
          storeLocation: newLocation,
        }));

        if (window.map) {
          window.map.setCenter(latLng);
          window.map.setZoom(15);
          updateMarker(latLng);
        }
      } catch (error) {
        console.error("Error processing place selection:", error);
      }
    });

    // Add place_changed event listener
    placeAutocomplete.addEventListener("input", () => {
      console.log("Input changed");
    });

    // Add bounds changed listener to map
    mapInstance.addListener("bounds_changed", () => {
      const bounds = mapInstance.getBounds();
      if (bounds) {
        placeAutocomplete.bounds = bounds;
      }
    });

    // Store placeAutocomplete reference
    setPlaceInput(placeAutocomplete);

    // Fetch existing settings
    fetchStoreLocationSettings();
  };

  // Add styles for modern UI
  const cardStyle = {
    background: "white",
    borderRadius: "12px",
    padding: "24px",
    marginBottom: "24px",
    boxShadow: "0 2px 12px rgba(0,0,0,0.1)",
    border: "1px solid #eee",
  };

  const inputStyle = {
    width: "100%",
    padding: "12px",
    borderRadius: "8px",
    border: "1px solid #e0e0e0",
    fontSize: "14px",
    marginBottom: "16px",
    transition: "border-color 0.3s ease",
  };

  const buttonStyle = {
    padding: "12px 24px",
    borderRadius: "8px",
    border: "none",
    backgroundColor: "#2c3e50",
    color: "white",
    fontWeight: "500",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    gap: "8px",
    transition: "background 0.3s ease",
  };

  const labelStyle = {
    fontWeight: "500",
    color: "#2c3e50",
    marginBottom: "8px",
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
    gap: "8px",
  };

  return (
    <div style={{ margin: "0 auto", padding: "20px 20px" }}>
      <Helmet>
        <meta name="description" content={settingsMain.siteDescription} />
        <meta name="keywords" content={settingsMain.keywords} />
        <meta name="author" content={settingsMain.websiteName} />
        <meta property="og:title" content={settingsMain.websiteName} />
        <meta
          property="og:description"
          content={settingsMain.siteDescription}
        />
        <meta
          property="og:image"
          content={`${settingsMain.serverUrl}${settingsMain.logoUrl}`}
        />
        <meta property="og:url" content={settingsMain.baseUrl} />
        <meta property="og:type" content="website" />
        <title>{`Main Store | ${settingsMain.websiteName}`}</title>
      </Helmet>

      <div style={cardStyle}>
        <p style={{ marginBottom: "20px", color: "#4b5563" }}>
          Please use the map below to set the store location. You can search for
          a location, click on the map, or drag the marker to the desired
          location.
        </p>

        <div style={{ marginBottom: "20px" }}>
          <input
            id="pac-input"
            ref={inputRef}
            className="controls pac-input form-control"
            type="text"
            placeholder="Search location here..."
            style={inputStyle}
          />
          <button
            className="btn btn-secondary-store"
            onClick={handleUseCurrentLocation}
            style={{
              ...buttonStyle,
              backgroundColor: "#4b5563",
              marginBottom: "20px",
            }}
          >
            <Navigation size={18} />
            Use My Current Location
          </button>
        </div>

        <div
          id="map"
          className="store-location-map"
          style={{
            marginBottom: "24px",
            borderRadius: "8px",
            overflow: "hidden",
          }}
        ></div>

        {loading && (
          <div
            className="fullscreen-loader"
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0,0,0,0.5)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 1000,
            }}
          >
            <div
              className="loader-content"
              style={{
                backgroundColor: "white",
                padding: "20px",
                borderRadius: "8px",
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <AlertCircle size={24} />
              <p style={{ margin: 0 }}>
                Getting location coordinates, please wait...
              </p>
            </div>
          </div>
        )}

        <form onSubmit={handleSubmit}>
          <div style={{ marginBottom: "24px" }}>
            <label style={labelStyle}>
              <MapPin size={16} />
              Store Location Name
            </label>
            <input
              type="text"
              name="storeLocationName"
              value={settings.storeLocationName}
              onChange={handleChange}
              style={inputStyle}
              required
            />
          </div>

          <div style={{ marginBottom: "24px" }}>
            <label style={labelStyle}>
              <MapPin size={16} />
              Store Location Coordinates
            </label>
            <input
              type="text"
              name="storeLocation"
              value={settings.storeLocation}
              onChange={handleChange}
              style={inputStyle}
              required
            />
          </div>

          <div style={{ marginBottom: "24px" }}>
            <label style={labelStyle}>
              <DollarSign size={16} />
              Cost Per Kilometer ({settingsMain.currency})
            </label>
            <input
              type="number"
              name="costPerKilometer"
              value={settings.costPerKilometer}
              onChange={handleChange}
              style={inputStyle}
              required
            />
          </div>

          <div style={{ marginBottom: "24px" }}>
            <label style={labelStyle}>
              <DollarSign size={16} />
              Minimum Charge ({settingsMain.currency})
            </label>
            <input
              type="number"
              name="minimumCharge"
              value={settings.minimumCharge}
              onChange={handleChange}
              style={inputStyle}
              required
            />
          </div>

          <button type="submit" style={buttonStyle}>
            <Save size={18} />
            Save Settings
          </button>
        </form>
      </div>
    </div>
  );
};

export default StoreLocationSettings;
