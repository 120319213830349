import React, { useState, useEffect } from "react";
import axios from "axios";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  Settings as SettingsIcon,
  Image,
  Link,
  Mail,
  Phone,
  Globe,
  FileText,
  KeyRound,
  AlertCircle,
  Share2,
  Info,
} from "lucide-react";

const SettingsPage = () => {
  const [settings, setSettings] = useState({
    phones: "",
    email: "",
    location: "",
    currency: "",
    websiteName: "",
    shortDescription: "",
    privacyPolicy: "",
    termsConditions: "",
    returnPolicy: "",
    facebookLink: "",
    twitterLink: "",
    youtubeLink: "",
    whatsappLink: "",
    jwtSecret: "",
    baseUrl: "",
    logoUrl: "",
    whatsappPhone: "",
    serverUrl: "",
    siteDescription: "",
    lowStockThreshold: "",
    homePageTitle: "",
    bannerImages: [],
    keywords: "",
  });

  const [previewImages, setPreviewImages] = useState({
    logo: "",
    favicon: "",
    bannerImages: [],
  });

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/api/settings`
        );
        const data = response.data || {};
        setSettings({
          ...data,
          bannerImages: data.bannerImages || [],
        });
        setPreviewImages({
          logo: data.logoUrl || "",
          favicon: data.favicon || "",
          bannerImages: data.bannerImages || [],
        });
      } catch (error) {
        console.error("Error fetching settings:", error);
      }
    };

    fetchSettings();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSettings({
      ...settings,
      [name]: value,
    });
  };

  const handleQuillChange = (name, value) => {
    setSettings({
      ...settings,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    const file = files[0];
    if (file) {
      const previewUrl = URL.createObjectURL(file);
      setPreviewImages((prev) => ({
        ...prev,
        [name]: previewUrl,
      }));
      setSettings((prev) => ({
        ...prev,
        [name]: file,
      }));
    }
  };

  const getImageSrc = (image) => {
    return image.includes("blob")
      ? image
      : `${process.env.REACT_APP_SERVER_URL}${image}`;
  };

  const handleBannerFileChange = (e) => {
    const files = Array.from(e.target.files);
    const newPreviewImages = files.map((file) => URL.createObjectURL(file));

    setPreviewImages((prev) => ({
      ...prev,
      bannerImages: [...prev.bannerImages, ...newPreviewImages],
    }));

    setSettings((prev) => ({
      ...prev,
      bannerImages: [...prev.bannerImages, ...files],
    }));
  };

  const handleRemoveBannerImage = (index) => {
    setSettings((prev) => ({
      ...prev,
      bannerImages: prev.bannerImages.filter((_, i) => i !== index),
    }));
    setPreviewImages((prev) => ({
      ...prev,
      bannerImages: prev.bannerImages.filter((_, i) => i !== index),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    Object.keys(settings).forEach((key) => {
      if (key !== "logo" && key !== "favicon" && key !== "bannerImages") {
        formData.append(key, settings[key] || "");
      }
    });

    if (settings.logo instanceof File) {
      formData.append("logo", settings.logo);
    }
    if (settings.favicon instanceof File) {
      formData.append("favicon", settings.favicon);
    }

    if (settings.bannerImages) {
      settings.bannerImages.forEach((image) => {
        if (image instanceof File) {
          formData.append("bannerImages", image);
        }
      });
    }

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/api/settings`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data) {
        alert("Settings updated successfully");
        const updatedSettings = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/api/settings`
        );
        setSettings(updatedSettings.data || {});
        setPreviewImages({
          logo: updatedSettings.data?.logoUrl || "",
          favicon: updatedSettings.data?.favicon || "",
          bannerImages: updatedSettings.data?.bannerImages || [],
        });
      }
    } catch (error) {
      console.error("Error updating settings:", error);
      alert(
        "Error updating settings: " +
          (error.response?.data?.error || error.message)
      );
    }
  };

  // Styles
  const cardStyle = {
    background: "white",
    borderRadius: "12px",
    padding: "24px",
    marginBottom: "24px",
    boxShadow: "0 2px 12px rgba(0,0,0,0.1)",
    border: "1px solid #eee",
  };

  const sectionTitleStyle = {
    fontSize: "1.2rem",
    fontWeight: "600",
    color: "#2c3e50",
    marginBottom: "20px",
    display: "flex",
    alignItems: "center",
    gap: "10px",
  };

  const inputStyle = {
    padding: "12px",
    borderRadius: "8px",
    border: "1px solid #e0e0e0",
    fontSize: "14px",
    width: "100%",
    transition: "all 0.3s ease",
  };

  const labelStyle = {
    fontWeight: "500",
    color: "#2c3e50",
    marginBottom: "8px",
    fontSize: "14px",
    textAlign: "left",
  };

  return (
    <div style={{ padding: "20px 20px" }}>
      <Helmet>
        <meta name="description" content={settings.siteDescription} />
        <meta name="keywords" content={settings.keywords} />
        <meta name="author" content={settings.websiteName} />
        <meta property="og:title" content={settings.websiteName} />
        <meta property="og:description" content={settings.siteDescription} />
        <meta
          property="og:image"
          content={`${settings.serverUrl}${settings.logoUrl}`}
        />
        <meta property="og:url" content={settings.baseUrl} />
        <meta property="og:type" content="website" />
        <title>{`Settings | ${settings.websiteName}`}</title>
      </Helmet>

      <Form onSubmit={handleSubmit} align="left">
        {/* Basic Information */}
        <div style={cardStyle}>
          <h3 style={sectionTitleStyle}>
            <Info size={20} />
            Basic Information
          </h3>
          <Row className="g-4">
            <Col md={6}>
              <Form.Group>
                <Form.Label alignItems="left" style={labelStyle}>
                  Website Name
                </Form.Label>
                <Form.Control
                  type="text"
                  name="websiteName"
                  value={settings.websiteName || ""}
                  onChange={handleChange}
                  style={inputStyle}
                  placeholder="Enter website name"
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label style={labelStyle}>Currency</Form.Label>
                <Form.Control
                  type="text"
                  name="currency"
                  value={settings.currency || ""}
                  onChange={handleChange}
                  style={inputStyle}
                  placeholder="Enter currency"
                />
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group>
                <Form.Label style={labelStyle}>Short Description</Form.Label>
                <ReactQuill
                  theme="snow"
                  value={settings.shortDescription || ""}
                  onChange={(value) =>
                    handleQuillChange("shortDescription", value)
                  }
                />
              </Form.Group>
            </Col>
          </Row>
        </div>

        {/* Contact Information */}
        <div style={cardStyle}>
          <h3 style={sectionTitleStyle}>
            <Phone size={20} />
            Contact Information
          </h3>
          <Row className="g-4">
            <Col md={4}>
              <Form.Group>
                <Form.Label style={labelStyle}>Phone Numbers</Form.Label>
                <Form.Control
                  type="text"
                  name="phones"
                  value={settings.phones || ""}
                  onChange={handleChange}
                  style={inputStyle}
                  placeholder="Enter phone numbers"
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <Form.Label style={labelStyle}>Email</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={settings.email || ""}
                  onChange={handleChange}
                  style={inputStyle}
                  placeholder="Enter email"
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <Form.Label style={labelStyle}>Location</Form.Label>
                <Form.Control
                  type="text"
                  name="location"
                  value={settings.location || ""}
                  onChange={handleChange}
                  style={inputStyle}
                  placeholder="Enter location"
                />
              </Form.Group>
            </Col>
          </Row>
        </div>

        {/* Media Files */}
        <div style={cardStyle}>
          <h3 style={sectionTitleStyle}>
            <Image size={20} />
            Media Files
          </h3>
          <Row className="g-4">
            <Col md={6}>
              <Form.Group>
                <Form.Label style={labelStyle}>Logo</Form.Label>
                <Form.Control
                  type="file"
                  name="logo"
                  onChange={handleFileChange}
                  style={{ ...inputStyle, padding: "8px" }}
                />
                {previewImages.logo && (
                  <div
                    style={{
                      marginTop: "10px",
                      padding: "10px",
                      border: "1px solid #eee",
                      borderRadius: "8px",
                    }}
                  >
                    <img
                      src={getImageSrc(previewImages.logo)}
                      alt="Logo Preview"
                      style={{
                        maxWidth: "20vw",
                        maxHeight: "100px",
                        objectFit: "contain",
                      }}
                    />
                  </div>
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label style={labelStyle}>Favicon</Form.Label>
                <Form.Control
                  type="file"
                  name="favicon"
                  onChange={handleFileChange}
                  style={{ ...inputStyle, padding: "8px" }}
                />
                {previewImages.favicon && (
                  <div
                    style={{
                      marginTop: "10px",
                      padding: "10px",
                      border: "1px solid #eee",
                      borderRadius: "8px",
                    }}
                  >
                    <img
                      src={getImageSrc(previewImages.favicon)}
                      alt="Favicon Preview"
                      style={{
                        width: "32px",
                        height: "32px",
                      }}
                    />
                  </div>
                )}
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group>
                <Form.Label style={labelStyle}>Banner Images</Form.Label>
                <Form.Control
                  type="file"
                  multiple
                  onChange={handleBannerFileChange}
                  style={{ ...inputStyle, padding: "8px" }}
                />
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "15px",
                    marginTop: "15px",
                  }}
                >
                  {previewImages.bannerImages.map((image, index) => (
                    <div
                      key={index}
                      style={{
                        position: "relative",
                        borderRadius: "8px",
                        overflow: "hidden",
                      }}
                    >
                      <img
                        src={getImageSrc(image)}
                        alt={`Banner ${index + 1}`}
                        style={{
                          width: "200px",
                          height: "120px",
                          objectFit: "cover",
                        }}
                      />
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={() => handleRemoveBannerImage(index)}
                        style={{
                          position: "absolute",
                          top: "5px",
                          right: "5px",
                        }}
                      >
                        Remove
                      </Button>
                    </div>
                  ))}
                </div>
              </Form.Group>
            </Col>
          </Row>
        </div>

        {/* Policy Information */}
        <div style={cardStyle}>
          <h3 style={sectionTitleStyle}>
            <FileText size={20} />
            Policies
          </h3>
          <Row className="g-4">
            <Col md={12}>
              <Form.Group>
                <Form.Label style={labelStyle}>Privacy Policy</Form.Label>
                <ReactQuill
                  theme="snow"
                  value={settings.privacyPolicy || ""}
                  onChange={(value) =>
                    handleQuillChange("privacyPolicy", value)
                  }
                />
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group>
                <Form.Label style={labelStyle}>Terms & Conditions</Form.Label>
                <ReactQuill
                  theme="snow"
                  value={settings.termsConditions || ""}
                  onChange={(value) =>
                    handleQuillChange("termsConditions", value)
                  }
                />
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group>
                <Form.Label style={labelStyle}>Return Policy</Form.Label>
                <ReactQuill
                  theme="snow"
                  value={settings.returnPolicy || ""}
                  onChange={(value) => handleQuillChange("returnPolicy", value)}
                />
              </Form.Group>
            </Col>
          </Row>
        </div>

        {/* Social Links */}
        <div style={cardStyle}>
          <h3 style={sectionTitleStyle}>
            <Share2 size={20} />
            Social Media Links
          </h3>
          <Row className="g-4">
            <Col md={6}>
              <Form.Group>
                <Form.Label style={labelStyle}>Facebook Link</Form.Label>
                <Form.Control
                  type="text"
                  name="facebookLink"
                  value={settings.facebookLink || ""}
                  onChange={handleChange}
                  style={inputStyle}
                  placeholder="Enter Facebook link"
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label style={labelStyle}>Twitter Link</Form.Label>
                <Form.Control
                  type="text"
                  name="twitterLink"
                  value={settings.twitterLink || ""}
                  onChange={handleChange}
                  style={inputStyle}
                  placeholder="Enter Twitter link"
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label style={labelStyle}>Youtube Link</Form.Label>
                <Form.Control
                  type="text"
                  name="youtubeLink"
                  value={settings.youtubeLink || ""}
                  onChange={handleChange}
                  style={inputStyle}
                  placeholder="Enter Youtube link"
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label style={labelStyle}>WhatsApp Link</Form.Label>
                <Form.Control
                  type="text"
                  name="whatsappLink"
                  value={settings.whatsappLink || ""}
                  onChange={handleChange}
                  style={inputStyle}
                  placeholder="Enter WhatsApp link"
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label style={labelStyle}>WhatsApp Phone</Form.Label>
                <Form.Control
                  type="text"
                  name="whatsappPhone"
                  value={settings.whatsappPhone || ""}
                  onChange={handleChange}
                  style={inputStyle}
                  placeholder="Enter WhatsApp phone"
                />
              </Form.Group>
            </Col>
          </Row>
        </div>

        {/* System Settings */}
        <div style={cardStyle}>
          <h3 style={sectionTitleStyle}>
            <KeyRound size={20} />
            System Settings
          </h3>
          <Row className="g-4">
            <Col md={6}>
              <Form.Group>
                <Form.Label style={labelStyle}>JWT Secret</Form.Label>
                <Form.Control
                  type="text"
                  name="jwtSecret"
                  value={settings.jwtSecret || ""}
                  onChange={handleChange}
                  style={inputStyle}
                  placeholder="Enter JWT Secret"
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label style={labelStyle}>Base URL</Form.Label>
                <Form.Control
                  type="text"
                  name="baseUrl"
                  value={settings.baseUrl || ""}
                  onChange={handleChange}
                  style={inputStyle}
                  placeholder="Enter Base URL"
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label style={labelStyle}>Server URL</Form.Label>
                <Form.Control
                  type="text"
                  name="serverUrl"
                  value={settings.serverUrl || ""}
                  onChange={handleChange}
                  style={inputStyle}
                  placeholder="Enter Server URL"
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label style={labelStyle}>Low Stock Threshold</Form.Label>
                <Form.Control
                  type="number"
                  name="lowStockThreshold"
                  value={settings.lowStockThreshold || ""}
                  onChange={handleChange}
                  style={inputStyle}
                  placeholder="Enter threshold amount"
                />
              </Form.Group>
            </Col>
          </Row>
        </div>

        {/* SEO Settings */}
        <div style={cardStyle}>
          <h3 style={sectionTitleStyle}>
            <Globe size={20} />
            SEO Settings
          </h3>
          <Row className="g-4">
            <Col md={12}>
              <Form.Group>
                <Form.Label style={labelStyle}>Site Description</Form.Label>
                <Form.Control
                  type="text"
                  name="siteDescription"
                  value={settings.siteDescription || ""}
                  onChange={handleChange}
                  style={inputStyle}
                  placeholder="Enter site description for SEO"
                />
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group>
                <Form.Label style={labelStyle}>Home Page Title</Form.Label>
                <Form.Control
                  type="text"
                  name="homePageTitle"
                  value={settings.homePageTitle || ""}
                  onChange={handleChange}
                  style={inputStyle}
                  placeholder="Enter home page title for SEO"
                />
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group>
                <Form.Label style={labelStyle}>
                  Keywords (Separate with commas)
                </Form.Label>
                <Form.Control
                  type="text"
                  name="keywords"
                  value={settings.keywords || ""}
                  onChange={handleChange}
                  style={inputStyle}
                  placeholder="Enter keywords separated by commas"
                />
              </Form.Group>
            </Col>
          </Row>
        </div>

        {/* Submit Button */}
        <div
          style={{
            textAlign: "center",
            marginTop: "30px",
            marginBottom: "50px",
          }}
        >
          <Button
            variant="primary"
            type="submit"
            style={{
              padding: "12px 40px",
              fontSize: "16px",
              fontWeight: "500",
              borderRadius: "8px",
              backgroundColor: "#2c3e50",
              border: "none",
              transition: "all 0.3s ease",
            }}
            onMouseOver={(e) => (e.target.style.backgroundColor = "#34495e")}
            onMouseOut={(e) => (e.target.style.backgroundColor = "#2c3e50")}
          >
            Save Settings
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default SettingsPage;
