import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));

const primaryColor = process.env.REACT_APP_PRIMARY_COLOR;
const secondaryColor = process.env.REACT_APP_SECONDARY_COLOR;
const accentColor = process.env.REACT_APP_ACCENT_COLOR;

if (primaryColor) {
  document.documentElement.style.setProperty("--primary-color", primaryColor);
}
if (secondaryColor) {
  document.documentElement.style.setProperty(
    "--secondary-color",
    secondaryColor
  );
}
if (accentColor) {
  document.documentElement.style.setProperty("--accent-color", accentColor);
}

root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
