import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Container, Form, Button, Alert, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../UserContext";
import { Helmet } from "react-helmet";
import { User, Mail, Phone, Bell, ArrowLeft } from "lucide-react";

const Profile = () => {
  const { user, setUser, logout } = useContext(UserContext);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    receiveNewsletters: false,
  });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const navigate = useNavigate();
  const [settings, setSettings] = useState({
    phones: "",
    currency: "",
    websiteName: "",
    baseUrl: "",
    keywords: "",
    siteDescription: "",
  });

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/api/settings`
        );
        setSettings(response.data);
      } catch (error) {
        console.error("Error fetching settings:", error);
      }
    };

    fetchSettings();
  }, []);

  useEffect(() => {
    const fetchUserProfile = async () => {
      const token = localStorage.getItem("token");
      if (token) {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_SERVER_URL}/api/profile`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
          setUser(response.data);
          setFormData(response.data);
        } catch (error) {
          setError("Error fetching profile");
        }
      }
    };

    fetchUserProfile();
  }, [setUser]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
    try {
      await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/api/profile`,
        formData,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setSuccess("Profile updated successfully");
      setError("");
    } catch (error) {
      setError("Error updating profile");
      setSuccess("");
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    logout();
    navigate("/login");
  };

  const containerStyle = {
    maxWidth: "800px",
    margin: "2rem auto",
    padding: "0 1rem",
  };

  const headerStyle = {
    background: "var(--primary-color)",
    color: "white",
    padding: "2rem",
    borderRadius: "1rem",
    marginBottom: "2rem",
  };

  const cardStyle = {
    border: "none",
    borderRadius: "1rem",
  };

  const formGroupStyle = {
    marginBottom: "1.5rem",
    position: "relative",
  };

  const inputContainerStyle = {
    position: "relative",
    display: "flex",
    alignItems: "center",
  };

  const inputStyle = {
    padding: "0.75rem 1rem 0.75rem 2.5rem",
    borderRadius: "0.5rem",
    border: "1px solid #e2e8f0",
    width: "100%",
    fontSize: "1rem",
    transition: "all 0.3s ease",
  };

  const inputIconStyle = {
    position: "absolute",
    left: "0.75rem",
    color: "#64748b",
    zIndex: 1,
  };

  const labelStyle = {
    display: "block",
    marginBottom: "0.5rem",
    color: "#4a5568",
    fontWeight: "500",
    textAlign: "left",
  };

  const buttonStyle = {
    width: "100%",
    padding: "0.75rem",
    borderRadius: "0.5rem",
    border: "none",
    fontWeight: "500",
    transition: "all 0.3s ease",
  };

  const primaryButtonStyle = {
    ...buttonStyle,
    background: "var(--primary-color)",
    color: "white",
  };

  const dangerButtonStyle = {
    ...buttonStyle,
    background: "#dc2626",
    color: "white",
    marginTop: "1rem",
  };

  const backButtonStyle = {
    display: "inline-flex",
    alignItems: "center",
    gap: "0.5rem",
    color: "white",
    textDecoration: "none",
    marginBottom: "1rem",
    cursor: "pointer",
  };

  return (
    <div style={containerStyle}>
      <Helmet>
        <meta name="description" content={settings.siteDescription} />
        <meta name="keywords" content={settings.keywords} />
        <meta name="author" content={settings.websiteName} />
        <title>{`Profile Settings | ${settings.websiteName}`}</title>
      </Helmet>

      <div style={headerStyle}>
        <div style={backButtonStyle} onClick={() => navigate("/dashboard")}>
          <ArrowLeft size={20} /> Back to Dashboard
        </div>
        <h1 style={{ fontSize: "2rem", marginBottom: "0.5rem" }}>
          Profile Settings
        </h1>
        <p style={{ opacity: 0.9, margin: 0 }}>
          Manage your account information
        </p>
      </div>

      <Card style={cardStyle}>
        <Card.Body style={{ padding: "2rem" }}>
          {error && (
            <Alert
              variant="danger"
              style={{
                marginBottom: "1.5rem",
                borderRadius: "0.5rem",
                border: "none",
                background: "#fee2e2",
                color: "#991b1b",
              }}
            >
              {error}
            </Alert>
          )}
          {success && (
            <Alert
              variant="success"
              style={{
                marginBottom: "1.5rem",
                borderRadius: "0.5rem",
                border: "none",
                background: "#dcfce7",
                color: "#166534",
              }}
            >
              {success}
            </Alert>
          )}
          {user && (
            <Form onSubmit={handleSubmit}>
              <div style={formGroupStyle}>
                <label style={labelStyle}>First Name</label>
                <div style={inputContainerStyle}>
                  <User size={18} style={inputIconStyle} />
                  <input
                    type="text"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    required
                    style={inputStyle}
                    placeholder="Enter your first name"
                  />
                </div>
              </div>

              <div style={formGroupStyle}>
                <label style={labelStyle}>Last Name</label>
                <div style={inputContainerStyle}>
                  <User size={18} style={inputIconStyle} />
                  <input
                    type="text"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    required
                    style={inputStyle}
                    placeholder="Enter your last name"
                  />
                </div>
              </div>

              <div style={formGroupStyle}>
                <label style={labelStyle}>Phone</label>
                <div style={inputContainerStyle}>
                  <Phone size={18} style={inputIconStyle} />
                  <input
                    type="text"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    required
                    style={inputStyle}
                    placeholder="Enter your phone number"
                  />
                </div>
              </div>

              <div style={{ ...formGroupStyle, marginBottom: "2rem" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "0.5rem",
                  }}
                >
                  <Bell size={18} style={{ color: "#64748b" }} />
                  <label style={{ margin: 0, cursor: "pointer" }}>
                    <input
                      type="checkbox"
                      name="receiveNewsletters"
                      checked={formData.receiveNewsletters}
                      onChange={handleChange}
                      style={{ marginRight: "0.5rem" }}
                    />
                    Receive newsletters and updates
                  </label>
                </div>
              </div>

              <button
                type="submit"
                style={primaryButtonStyle}
                onMouseEnter={(e) => {
                  e.target.style.opacity = "0.9";
                }}
                onMouseLeave={(e) => {
                  e.target.style.opacity = "1";
                }}
              >
                Update Profile
              </button>

              <button
                onClick={handleLogout}
                style={dangerButtonStyle}
                onMouseEnter={(e) => {
                  e.target.style.opacity = "0.9";
                }}
                onMouseLeave={(e) => {
                  e.target.style.opacity = "1";
                }}
              >
                Sign Out
              </button>
            </Form>
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

export default Profile;
