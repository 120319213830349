import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import "./css/CategoryView.css";
import PriceRangeFilter from "./PriceRangeFilter";
import CategoryListFilter from "./CategoryListFilter";
import AgeFilter from "./AgeFilter";
import { Breadcrumb } from "react-bootstrap";
import AddToCartModal from "./AddToCartModal";
import { Helmet } from "react-helmet";

const CategoryView = ({ handleAddToCart, cartItems, handleRemoveFromCart }) => {
  const { categoryName, subcategoryName } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [sortOption, setSortOption] = useState("");
  const [filters, setFilters] = useState({
    minPrice: 0,
    maxPrice: Infinity,
    age: "all",
  });
  const [showFilters, setShowFilters] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [settings, setSettings] = useState({
    phones: "",
    currency: "",
    websiteName: "",
    baseUrl: "",
    keywords: "",
    siteDescription: "",
  });

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/api/settings`
        );
        setSettings(response.data);
      } catch (error) {
        console.error("Error fetching settings from the database:", error);
      }
    };

    fetchSettings();
  }, []);

  const fetchProducts = useCallback(async () => {
    try {
      const params = {
        category: categoryName,
        subcategory: subcategoryName,
        page: currentPage,
        sort: sortOption,
        minPrice: filters.minPrice,
        maxPrice: filters.maxPrice,
        condition: filters.age !== "all" ? filters.age : undefined, // Change 'age' to 'condition' in the API request
      };

      const searchParams = new URLSearchParams(location.search);
      const searchQuery = searchParams.get("search");
      if (searchQuery) {
        params.search = searchQuery;
      }

      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/products`,
        { params }
      );

      setProducts(response.data.products || []);
      setTotalPages(response.data.totalPages || 1);
    } catch (error) {
      console.error("Error fetching products:", error);
      setProducts([]);
      setTotalPages(1);
    }
  }, [
    categoryName,
    subcategoryName,
    currentPage,
    sortOption,
    filters,
    location.search,
  ]);

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  const handleSortChange = (event) => {
    setSortOption(event.target.value);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleFilterChange = (name, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
    setCurrentPage(1);
  };

  const renderPagination = () => {
    const pageNumbers = [];

    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      if (currentPage <= 3) {
        pageNumbers.push(1, 2, 3, 4, "...", totalPages);
      } else if (currentPage > totalPages - 3) {
        pageNumbers.push(
          1,
          "...",
          totalPages - 3,
          totalPages - 2,
          totalPages - 1,
          totalPages
        );
      } else {
        pageNumbers.push(
          1,
          "...",
          currentPage - 1,
          currentPage,
          currentPage + 1,
          "...",
          totalPages
        );
      }
    }

    return (
      <nav>
        <ul className="pagination justify-content-center">
          <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
            <button
              className="page-link"
              onClick={() => handlePageChange(currentPage - 1)}
            >
              &laquo;
            </button>
          </li>
          {pageNumbers.map((page, index) =>
            page === "..." ? (
              <li key={`ellipsis-${index}`} className="page-item disabled">
                <span className="page-link">...</span>
              </li>
            ) : (
              <li
                key={page}
                className={`page-item ${currentPage === page ? "active" : ""}`}
              >
                <button
                  className="page-link"
                  onClick={() => handlePageChange(page)}
                >
                  {page}
                </button>
              </li>
            )
          )}
          <li
            className={`page-item ${
              currentPage === totalPages ? "disabled" : ""
            }`}
          >
            <button
              align="center"
              className="page-link"
              onClick={() => handlePageChange(currentPage + 1)}
            >
              &raquo;
            </button>
          </li>
        </ul>
      </nav>
    );
  };

  const handleCardClick = (productId) => {
    navigate(`/product/${productId}`);
  };

  const handleAddToCartClick = (product) => {
    setSelectedProduct(product);
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleModalSave = (product, quantity) => {
    if (quantity > 0 && quantity <= product.stock) {
      handleAddToCart(product, quantity);
    } else {
      alert("Invalid quantity");
    }
    setShowModal(false);
  };

  return (
    <div className="container">
      <Helmet>
        <title>
          {categoryName} - {subcategoryName ? `${subcategoryName} - ` : ""}
          {settings.websiteName}
        </title>
        <meta name="description" content={settings.siteDescription} />
        <meta name="robots" content="index, follow" />
        <meta name="keywords" content={settings.keywords} />
        <meta name="author" content={settings.websiteName} />
        <meta
          property="og:title"
          content={`${categoryName} ${
            subcategoryName ? ` - ${subcategoryName}` : ""
          } - ${settings.websiteName}`}
        />
        <meta property="og:description" content={settings.siteDescription} />
        <meta
          property="og:image"
          content={`${settings.baseUrl}${settings.logoUrl}`}
        />
        <meta
          property="og:url"
          content={`${settings.baseUrl}${location.pathname}`}
        />
        <meta property="og:type" content="website" />
        <link
          rel="canonical"
          href={`${settings.baseUrl}${location.pathname}`}
        />
      </Helmet>

      <div className="row">
        <div className="col-md-3 filter-sidebar">
          <button
            className="btn-toogle-filters btn btn-primary mb-3 d-md-none"
            onClick={() => setShowFilters(!showFilters)}
          >
            {showFilters ? "Hide Filters" : "Show Filters"}{" "}
            <span className="material-icons">
              {showFilters ? "expand_less" : "expand_more"}
            </span>
          </button>
          {showFilters && (
            <>
              <PriceRangeFilter
                onChange={handleFilterChange}
                minPrice={filters.minPrice}
                maxPrice={filters.maxPrice}
              />
              <AgeFilter onChange={handleFilterChange} age={filters.age} />
              <CategoryListFilter />
            </>
          )}
          <div className="d-none d-md-block">
            <PriceRangeFilter
              onChange={handleFilterChange}
              minPrice={filters.minPrice}
              maxPrice={filters.maxPrice}
            />
            <AgeFilter onChange={handleFilterChange} age={filters.age} />
            <CategoryListFilter />
          </div>
        </div>
        <div className="col-md-9 product-list">
          <div className="d-flex justify-content-between align-items-center mb-3 sort-options">
            <span className="breadcrumb-path">
              <Breadcrumb>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>
                  Home
                </Breadcrumb.Item>
                <Breadcrumb.Item
                  linkAs={Link}
                  linkProps={{ to: `/category/${categoryName}` }}
                >
                  {categoryName}
                </Breadcrumb.Item>
                {subcategoryName && (
                  <Breadcrumb.Item active>{subcategoryName}</Breadcrumb.Item>
                )}
              </Breadcrumb>
            </span>

            <select
              value={sortOption}
              onChange={handleSortChange}
              className="form-select sort-by"
            >
              <option value="">Sort by</option>
              <option value="priceAsc">Price: Low to High</option>
              <option value="priceDesc">Price: High to Low</option>
              <option value="idAsc">Newest</option>
              <option value="idDesc">Oldest</option>
            </select>
          </div>

          <div className="row">
            {products.length === 0 ? (
              <div className="col-12">
                <p>No products found</p>
              </div>
            ) : (
              products.map((product) => {
                const inCart = cartItems.some((item) => item.id === product.id);
                return (
                  <div className="col-md-3" key={product.id}>
                    <div
                      className="card mb-3"
                      onClick={() => handleCardClick(product.id)}
                      style={{ cursor: "pointer" }}
                    >
                      <div className="position-relative">
                        {product.images && product.images.length > 0 ? (
                          <img
                            src={`${process.env.REACT_APP_SERVER_URL}${product.images[0]}`}
                            alt={`${product.name} 1`}
                            className="card-img-top"
                          />
                        ) : (
                          <img
                            src="default-image-path"
                            alt="Default"
                            className="card-img-top"
                          />
                        )}
                        {product.discount > 0 && (
                          <span className="badge badge-discount position-absolute top-0 end-0">
                            -{Math.ceil(product.discount)}%
                          </span>
                        )}
                      </div>
                      <div align="left" className="card-body">
                        <h5 className="card-title text-truncate">
                          {product.name}
                        </h5>
                        <p className="product-condition">{product.condition}</p>
                        <p className="card-text category-product-pricing-area d-flex align-items-center gap-2">
                          <span className="price-new category-price">
                            {settings.currency}{" "}
                            {Number(product.price).toLocaleString()}
                          </span>
                          {product.originalPrice &&
                            product.originalPrice !== product.price && (
                              <span className="price-old category-old-price">
                                {settings.currency}{" "}
                                {Number(product.originalPrice).toLocaleString()}
                              </span>
                            )}
                        </p>
                        {product.stock === 0 ? (
                          <p
                            className="out-of-stock-text"
                            style={{ color: "red" }}
                          >
                            <b>Out of stock</b>
                          </p>
                        ) : inCart ? (
                          <button
                            className="btn btn-danger d-flex align-items-center justify-content-center"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleRemoveFromCart(product.id);
                            }}
                          >
                            <span className="material-icons me-2">
                              remove_shopping_cart
                            </span>{" "}
                            Remove from Cart
                          </button>
                        ) : (
                          <button
                            className="btn btn-primary d-flex align-items-center justify-content-center"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleAddToCartClick(product);
                            }}
                          >
                            <span className="material-icons me-2">
                              add_shopping_cart
                            </span>{" "}
                            Add to Cart
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })
            )}
          </div>
          {renderPagination()}
        </div>
      </div>
      <AddToCartModal
        show={showModal}
        handleClose={handleModalClose}
        handleSave={handleModalSave}
        product={selectedProduct}
      />
    </div>
  );
};

export default CategoryView;
