import React, { useState, useEffect } from "react";
import { Link, useLocation, Navigate, Outlet } from "react-router-dom";
import {
  LayoutDashboard,
  Component,
  Package,
  Mail,
  Users,
  MapPin,
  Truck,
  ClipboardList,
  ShoppingCart,
  HelpCircle,
  CreditCard,
  Settings,
  LogOut,
  Menu,
  X,
  ChevronLeft,
  ChevronRight,
  Building2,
} from "lucide-react";

const AdminLayout = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const location = useLocation();
  const token = localStorage.getItem("adminToken");

  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth <= 768;
      setIsMobile(mobile);
      setIsOpen(!mobile);
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (!token) {
    return <Navigate to="/admin/login" replace />;
  }

  const navigationItems = [
    {
      path: "/admin/dashboard",
      icon: <LayoutDashboard size={20} />,
      label: "Dashboard",
    },
    {
      path: "/admin/manage-categories",
      icon: <Component size={20} />,
      label: "Categories",
    },
    {
      path: "/admin/manage-products",
      icon: <Package size={20} />,
      label: "Products",
    },
    {
      path: "/admin/smtp-settings",
      icon: <Mail size={20} />,
      label: "SMTP Settings",
    },
    {
      path: "/admin/send-emails",
      icon: <Mail size={20} />,
      label: "Send Emails",
    },
    { path: "/admin/manage-users", icon: <Users size={20} />, label: "Users" },
    {
      path: "/admin/store-location-settings",
      icon: <MapPin size={20} />,
      label: "Store Location",
    },
    {
      path: "/admin/manage-delivery-addresses",
      icon: <Truck size={20} />,
      label: "Delivery Addresses",
    },
    {
      path: "/admin/manage-order-categories",
      icon: <ClipboardList size={20} />,
      label: "Order Categories",
    },
    {
      path: "/admin/order-list",
      icon: <ShoppingCart size={20} />,
      label: "Orders",
    },
    {
      path: "/admin/manage-faqs",
      icon: <HelpCircle size={20} />,
      label: "FAQs",
    },
    {
      path: "/admin/mpesa-settings",
      icon: <CreditCard size={20} />,
      label: "MPesa Settings",
    },
    {
      path: "/admin/manage-card-payments",
      icon: <CreditCard size={20} />,
      label: "Card Payments",
    },
    {
      path: "/admin/settings",
      icon: <Settings size={20} />,
      label: "Settings",
    },
  ];

  const handleLogout = () => {
    localStorage.removeItem("adminToken");
    window.location.href = "/admin/login";
  };

  const handleNavClick = () => {
    if (isMobile) {
      setIsOpen(false);
    }
  };

  return (
    <div className="admin-layout">
      {/* Mobile Header */}
      {isMobile && (
        <header className="mobile-header">
          <button className="menu-button" onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? <X size={24} /> : <Menu size={24} />}
          </button>
          <h1 className="mobile-title">Admin Panel</h1>
        </header>
      )}

      {/* Overlay */}
      {isMobile && isOpen && (
        <div className="sidebar-overlay" onClick={() => setIsOpen(false)} />
      )}

      {/* Sidebar */}
      <aside className={`sidebar ${isOpen ? "open" : ""}`}>
        <div className="sidebar-header">
          <div className="logo-container">
            <Building2 size={24} className="logo-icon" />
            {isOpen && <h1 className="logo-text">Admin Panel</h1>}
          </div>
          {!isMobile && (
            <button
              className="toggle-button"
              onClick={() => setIsOpen(!isOpen)}
            >
              {isOpen ? <ChevronLeft size={20} /> : <ChevronRight size={20} />}
            </button>
          )}
        </div>

        <nav className="sidebar-nav">
          {navigationItems.map((item) => (
            <Link
              key={item.path}
              to={item.path}
              className={`nav-item ${
                location.pathname === item.path ? "active" : ""
              }`}
              onClick={handleNavClick}
            >
              {item.icon}
              {isOpen && <span>{item.label}</span>}
            </Link>
          ))}
        </nav>

        <button className="logout-button" onClick={handleLogout}>
          <LogOut size={20} />
          {isOpen && <span>Logout</span>}
        </button>
      </aside>

      {/* Main Content */}
      <main className={`main-content ${isOpen ? "shifted" : ""}`}>
        <Outlet />
      </main>

      <style jsx>{`
        .admin-layout {
          display: flex;
          min-height: 100vh;
          overflow-x: hidden;
        }

        .mobile-header {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          height: 60px;
          background: var(--primary-color) !important;
          display: flex;
          align-items: center;
          padding: 0 1rem;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          z-index: 1000;
        }

        .mobile-title {
          margin: 0 auto;
          font-size: 1.2rem;
          color: #2c3e50;
        }

        .menu-button {
          background: none;
          border: none;
          color: #333;
          cursor: pointer;
          padding: 0.5rem;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .sidebar-overlay {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: rgba(0, 0, 0, 0.5);
          z-index: 998;
        }

        .sidebar {
          position: fixed;
          top: 0;
          left: 0;
          bottom: 0;
          width: ${isOpen ? "240px" : "70px"};
          background: #fff !important;
          transition: all 0.3s ease;
          display: flex;
          flex-direction: column;
          z-index: 999;
          box-shadow: 2px 0 8px rgba(0, 0, 0, 0.1);
          overflow-y: auto;
          overflow-x: hidden;
        }

        @media (max-width: 768px) {
          .sidebar {
            transform: translateX(${isOpen ? "0" : "-100%"});
            width: 240px;
            top: 60px;
          }
        }

        .sidebar-header {
          padding: 1rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-bottom: 1px solid rgba(255, 255, 255, 0.1);
          background: black;
          height: 60px;
          box-sizing: border-box;
        }

        .logo-container {
          display: flex;
          align-items: center;
          gap: 12px;
        }

        .logo-icon {
          color: white;
          min-width: 24px;
        }

        .logo-text {
          margin: 0;
          font-size: 1.2rem;
          color: white;
          white-space: nowrap;
        }

        .toggle-button {
          background: rgba(255, 255, 255, 0.1);
          border: none;
          color: white;
          width: 28px;
          height: 28px;
          border-radius: 8px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: background-color 0.2s;
        }

        .toggle-button:hover {
          background: rgba(255, 255, 255, 0.2);
        }

        .sidebar-nav {
          flex: 1;
          padding: 1rem 0;
          overflow-y: hidden;
        }

        .nav-item {
          font-weight: 400;
          display: flex;
          align-items: center;
          padding: 0.75rem 1rem;
          color: #000 !important;
          text-decoration: none;
          gap: 1rem;
          font-size: 14px;
          transition: all 0.2s;
        }
        .nav-item svg {
          width: 25px;
          height: 25px;
          color: var(--primary-color) !important;
        }

        .nav-item:hover {
          background: rgba(255, 255, 255, 0.1);
          color: grey !important;
        }

        .nav-item.active {
          background: var(--primary-color) !important;
          color: white !important;
          border-left: 3px solid white;
        }
        .nav-item.active svg {
          color: white !important;
        }

        .logout-button {
          margin: 1rem;
          padding: 0.75rem;
          background: var(--secondary-color) !important;
          color: white;
          border: none;
          border-radius: 8px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 0.5rem;
          transition: background-color 0.2s;
        }

        .logout-button:hover {
          background: rgba(255, 59, 48, 1);
        }

        .main-content {
          flex: 1;
          padding: 0;
          margin-left: ${isOpen ? "240px" : "70px"};
          transition: margin-left 0.3s ease;
          min-height: 100vh;
          background: #f8f9fa;
          width: calc(100% - ${isOpen ? "240px" : "70px"});
          box-sizing: border-box;
        }

        @media (max-width: 768px) {
          .main-content {
            margin-left: 0;
            width: 100%;
            padding-top: 60px;
          }
        }

        /* Scrollbar Styling */
        .sidebar::-webkit-scrollbar {
          width: 6px;
        }

        .sidebar::-webkit-scrollbar-track {
          background: rgba(255, 255, 255, 0.1);
        }

        .sidebar::-webkit-scrollbar-thumb {
          background: rgba(255, 255, 255, 0.2);
          border-radius: 3px;
        }

        .sidebar::-webkit-scrollbar-thumb:hover {
          background: rgba(255, 255, 255, 0.3);
        }
      `}</style>
    </div>
  );
};

export default AdminLayout;
