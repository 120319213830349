import React, { useState, useEffect } from "react";
import axios from "axios";
import { Modal, Button, Form } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { Plus, Edit2, Trash2, Tag, AlertCircle } from "lucide-react";

const ManageOrderCategories = () => {
  const [statuses, setStatuses] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [newStatus, setNewStatus] = useState("");
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [alertMessage, setAlertMessage] = useState({ type: "", message: "" });
  const [loading, setLoading] = useState(false);
  const [settings, setSettings] = useState({});

  // Styles
  const cardStyle = {
    background: "white",
    borderRadius: "12px",
    padding: "24px",
    marginBottom: "24px",
    boxShadow: "0 2px 12px rgba(0,0,0,0.1)",
    border: "1px solid #eee",
  };

  const tableContainerStyle = {
    background: "white",
    borderRadius: "12px",
    overflow: "auto",
    boxShadow: "0 2px 12px rgba(0,0,0,0.1)",
    border: "1px solid #eee",
  };

  const inputStyle = {
    padding: "12px",
    borderRadius: "8px",
    border: "1px solid #e0e0e0",
    fontSize: "14px",
    width: "100%",
    transition: "all 0.3s ease",
  };

  useEffect(() => {
    fetchSettings();
    fetchStatuses();
  }, []);

  const fetchSettings = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/settings`
      );
      setSettings(response.data || {});
    } catch (error) {
      console.error("Error fetching settings:", error);
    }
  };

  const fetchStatuses = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/order-statuses`
      );
      setStatuses(response.data || []);
    } catch (error) {
      console.error("Error fetching order statuses:", error);
      setAlertMessage({
        type: "error",
        message: "Failed to load order statuses",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleShowModal = (status = null) => {
    setSelectedStatus(status);
    setNewStatus(status ? status.name : "");
    setShowModal(true);
  };

  const handleSaveStatus = async () => {
    setLoading(true);
    try {
      if (selectedStatus) {
        await axios.put(
          `${process.env.REACT_APP_SERVER_URL}/api/order-statuses/${selectedStatus.id}`,
          { name: newStatus }
        );
        setAlertMessage({
          type: "success",
          message: "Order status updated successfully.",
        });
      } else {
        await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/api/order-statuses`,
          { name: newStatus }
        );
        setAlertMessage({
          type: "success",
          message: "Order status added successfully.",
        });
      }
      fetchStatuses();
      setShowModal(false);
      setNewStatus("");
    } catch (error) {
      setAlertMessage({
        type: "error",
        message: "Failed to save order status.",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteStatus = async (statusId) => {
    if (!window.confirm("Are you sure you want to delete this status?")) return;

    setLoading(true);
    try {
      await axios.delete(
        `${process.env.REACT_APP_SERVER_URL}/api/order-statuses/${statusId}`
      );
      setAlertMessage({
        type: "success",
        message: "Order status deleted successfully.",
      });
      fetchStatuses();
    } catch (error) {
      setAlertMessage({
        type: "error",
        message: "Failed to delete order status.",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ margin: "0 auto", padding: "20px 20px" }}>
      <Helmet>
        <title>{`Manage Order Categories | ${settings.websiteName}`}</title>
        <meta name="description" content={settings.siteDescription} />
      </Helmet>

      <div style={cardStyle}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "24px",
          }}
        >
          <Button
            onClick={() => handleShowModal()}
            style={{
              padding: "12px 24px",
              borderRadius: "8px",
              backgroundColor: "#2c3e50",
              border: "none",
              display: "flex",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <Plus size={18} />
            Add Status
          </Button>
        </div>

        {alertMessage.message && (
          <div
            style={{
              padding: "12px",
              borderRadius: "8px",
              marginBottom: "20px",
              backgroundColor:
                alertMessage.type === "success" ? "#e3fcef" : "#fee2e2",
              color: alertMessage.type === "success" ? "#059669" : "#dc2626",
              display: "flex",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <AlertCircle size={18} />
            {alertMessage.message}
          </div>
        )}

        <div style={tableContainerStyle}>
          <table style={{ width: "100%", borderCollapse: "collapse" }}>
            <thead style={{ backgroundColor: "#f8f9fa" }}>
              <tr>
                <th style={{ padding: "16px", textAlign: "left" }}>
                  Status Name
                </th>
                <th style={{ padding: "16px", textAlign: "left" }}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td
                    colSpan="2"
                    style={{ padding: "24px", textAlign: "left" }}
                  >
                    Loading...
                  </td>
                </tr>
              ) : statuses.length === 0 ? (
                <tr>
                  <td
                    colSpan="2"
                    style={{ padding: "24px", textAlign: "left" }}
                  >
                    No order statuses found
                  </td>
                </tr>
              ) : (
                statuses.map((status) => (
                  <tr
                    key={status.id}
                    style={{ borderBottom: "1px solid #eee" }}
                  >
                    <td style={{ padding: "16px", textAlign: "left" }}>
                      {status.name}
                    </td>
                    <td style={{ padding: "16px", textAlign: "leftt" }}>
                      <div
                        style={{
                          display: "flex",
                          gap: "8px",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Button
                          variant="outline-primary"
                          size="sm"
                          onClick={() => handleShowModal(status)}
                          style={{
                            padding: "8px",
                            borderRadius: "6px",
                            display: "flex",
                            alignItems: "left",
                          }}
                        >
                          <Edit2 size={16} />
                        </Button>
                        <Button
                          variant="outline-danger"
                          size="sm"
                          onClick={() => handleDeleteStatus(status.id)}
                          style={{
                            padding: "8px",
                            borderRadius: "6px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Trash2 size={16} />
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header
          closeButton
          style={{ border: "none", padding: "20px 24px 0" }}
        >
          <Modal.Title>
            {selectedStatus ? "Edit Status" : "Add New Status"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: "20px 24px" }}>
          <Form.Group>
            <Form.Label style={{ fontWeight: "500", marginBottom: "8px" }}>
              Status Name
            </Form.Label>
            <Form.Control
              type="text"
              value={newStatus}
              onChange={(e) => setNewStatus(e.target.value)}
              style={inputStyle}
              placeholder="Enter status name"
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer style={{ border: "none", padding: "0 24px 20px" }}>
          <Button
            variant="outline-secondary"
            onClick={() => setShowModal(false)}
            style={{
              padding: "10px 16px",
              borderRadius: "6px",
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleSaveStatus}
            disabled={loading}
            style={{
              padding: "10px 16px",
              borderRadius: "6px",
              backgroundColor: "#2c3e50",
              border: "none",
            }}
          >
            {loading
              ? "Saving..."
              : selectedStatus
              ? "Update Status"
              : "Add Status"}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ManageOrderCategories;
