import React, { useState, useEffect } from "react";
import axios from "axios";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "./css/ProductForm.css";

const ProductForm = ({ product, onSuccess, categories = [] }) => {
  const [formData, setFormData] = useState({
    name: "",
    images: [],
    price: "",
    originalPrice: "",
    discount: "",
    category: "",
    subcategory: "",
    colors: "",
    description: "",
    stock: "",
    features: "",
    condition: "", // Add condition field
    keywords: "",
    is_featured: false, // Add this line
  });

  const [imagePreviews, setImagePreviews] = useState([]);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [settings, setSettings] = useState({
    phones: "",
    currency: "",
  });

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/api/settings`
        );
        setSettings(response.data);
      } catch (error) {
        console.error("Error fetching settings from the database:", error);
      }
    };

    fetchSettings();
  }, []);

  useEffect(() => {
    if (product) {
      const updatedImages = product.images.map((img) => ({
        src: `${process.env.REACT_APP_SERVER_URL}${img}`,
        isNew: false,
      }));
      setFormData({
        ...product,
        images: product.images || [],
        colors: product.colors ? product.colors.join(", ") : "",
        features: product.features ? product.features.join(", ") : "",
        condition: product.condition || "",
      });
      setImagePreviews(updatedImages || []);
    }
  }, [product]);

  useEffect(() => {
    calculateDiscount();
  }, [formData.price, formData.originalPrice]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    const newImagePreviews = files.map((file) => ({
      src: URL.createObjectURL(file),
      isNew: true,
      file: file,
    }));
    setFormData({ ...formData, images: [...formData.images, ...files] });
    setImagePreviews([...imagePreviews, ...newImagePreviews]);
  };

  const handleDeleteImage = (index) => {
    const newImages = [...formData.images];
    const newPreviews = [...imagePreviews];
    newImages.splice(index, 1);
    newPreviews.splice(index, 1);
    setFormData({ ...formData, images: newImages });
    setImagePreviews(newPreviews);
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedPreviews = Array.from(imagePreviews);
    const [reorderedItem] = reorderedPreviews.splice(result.source.index, 1);
    reorderedPreviews.splice(result.destination.index, 0, reorderedItem);

    const reorderedImages = Array.from(formData.images);
    const [reorderedImage] = reorderedImages.splice(result.source.index, 1);
    reorderedImages.splice(result.destination.index, 0, reorderedImage);

    setImagePreviews(reorderedPreviews);
    setFormData({ ...formData, images: reorderedImages });
  };

  const calculateDiscount = () => {
    if (formData.originalPrice && formData.price) {
      const discount =
        ((formData.originalPrice - formData.price) / formData.originalPrice) *
        100;
      setFormData((prevState) => ({
        ...prevState,
        discount: discount.toFixed(2),
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        discount: "",
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccessMessage(null);
    try {
      const formDataWithImages = new FormData();
      const existingImages = [];

      imagePreviews.forEach((image, index) => {
        if (image.isNew) {
          formDataWithImages.append("images", image.file);
        } else {
          existingImages.push(formData.images[index]);
        }
      });

      formDataWithImages.append(
        "existingImages",
        JSON.stringify(existingImages)
      );
      formDataWithImages.append("name", formData.name);
      formDataWithImages.append("price", formData.price);
      formDataWithImages.append("originalPrice", formData.originalPrice);
      formDataWithImages.append("discount", formData.discount);
      formDataWithImages.append("category", formData.category);
      formDataWithImages.append("subcategory", formData.subcategory);
      formDataWithImages.append("colors", formData.colors);
      formDataWithImages.append("description", formData.description);
      formDataWithImages.append("stock", formData.stock);
      formDataWithImages.append("features", formData.features);
      formDataWithImages.append("condition", formData.condition); // Append condition
      formDataWithImages.append("keywords", formData.keywords);
      formDataWithImages.append("is_featured", formData.is_featured);

      if (product) {
        await axios.put(
          `${process.env.REACT_APP_SERVER_URL}/api/products/${product.id}`,
          formDataWithImages,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      } else {
        await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/api/products`,
          formDataWithImages,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      }
      setSuccessMessage("Product saved successfully!");
      onSuccess();
    } catch (error) {
      setError(error.message);
    }
  };

  const selectedCategory = categories.length
    ? categories.find((category) => category.name === formData.category)
    : null;
  const subcategories = selectedCategory ? selectedCategory.subcategories : [];

  return (
    <form onSubmit={handleSubmit} className="product-form">
      <div className="mb-3">
        <label className="form-label">Product Name</label>
        <input
          type="text"
          className="form-control"
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
        />
      </div>
      <div className="mb-3">
        <label className="form-label">Images</label>
        <input
          type="file"
          className="form-control"
          name="images"
          onChange={handleImageChange}
          multiple
        />
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable-images" direction="horizontal">
            {(provided) => (
              <div
                className="image-previews"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {imagePreviews.map((image, index) => (
                  <Draggable
                    key={index}
                    draggableId={`draggable-${index}`}
                    index={index}
                  >
                    {(provided) => (
                      <div
                        className="image-preview-wrapper"
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                      >
                        <img
                          src={image.src}
                          alt={`preview ${index}`}
                          className="preview-image"
                        />
                        <button
                          type="button"
                          className="btn btn-danger btn-sm"
                          onClick={() => handleDeleteImage(index)}
                        >
                          Delete
                        </button>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <div className="mb-3">
        <label className="form-label">Price ({settings.currency})</label>
        <input
          type="number"
          className="form-control"
          name="price"
          value={formData.price}
          onChange={handleChange}
          required
        />
      </div>
      <div className="mb-3">
        <label className="form-label">
          Original Price ({settings.currency})
        </label>
        <input
          type="number"
          className="form-control"
          name="originalPrice"
          value={formData.originalPrice}
          onChange={handleChange}
        />
      </div>
      <div className="mb-3">
        <label className="form-label">Discount (%)</label>
        <input
          type="number"
          className="form-control"
          name="discount"
          value={formData.discount}
          readOnly
        />
      </div>
      <div className="mb-3">
        <label className="form-label">Category</label>
        <select
          className="form-control"
          name="category"
          value={formData.category}
          onChange={handleChange}
          required
        >
          <option value="">Select Category</option>
          {categories.map((category) => (
            <option key={category.id} value={category.name}>
              {category.name}
            </option>
          ))}
        </select>
      </div>
      {subcategories.length > 0 && (
        <div className="mb-3">
          <label className="form-label">Subcategory</label>
          <select
            className="form-control"
            name="subcategory"
            value={formData.subcategory}
            onChange={handleChange}
          >
            <option value="">Select Subcategory</option>
            {subcategories.map((subcategory, index) => (
              <option key={index} value={subcategory}>
                {subcategory}
              </option>
            ))}
          </select>
        </div>
      )}
      <div className="mb-3">
        <label className="form-label">Colors</label>
        <input
          type="text"
          className="form-control"
          name="colors"
          value={formData.colors}
          onChange={handleChange}
          placeholder="Comma-separated values"
        />
      </div>
      <div className="mb-3">
        <label className="form-label">Description</label>
        <textarea
          className="form-control"
          name="description"
          value={formData.description}
          onChange={handleChange}
          required
        />
      </div>
      <div className="mb-3">
        <label className="form-label">Stock</label>
        <input
          type="number"
          className="form-control"
          name="stock"
          value={formData.stock}
          onChange={handleChange}
          required
        />
      </div>
      <div className="mb-3">
        <label className="form-label">Features</label>
        <input
          type="text"
          className="form-control"
          name="features"
          value={formData.features}
          onChange={handleChange}
          placeholder="Comma-separated values"
        />
      </div>
      <div className="mb-3">
        <label className="form-label">Condition</label>
        <select
          className="form-control"
          name="condition"
          value={formData.condition}
          onChange={handleChange}
        >
          <option disabled selected value="">
            Select Condition
          </option>
          <option value="Brand New">Brand New</option>
          <option value="Used">Used</option>
          <option value="Refurbished">Refurbished</option>
        </select>
      </div>
      <div className="mb-3">
        <label className="form-label">
          SEO Keywords (Separate with commas)
        </label>
        <input
          type="text"
          className="form-control"
          name="keywords"
          value={formData.keywords}
          onChange={handleChange}
          placeholder="Comma-separated values"
          required
        />
      </div>

      <div className="mb-3 form-check">
        <input
          type="checkbox"
          className="form-check-input"
          id="is_featured"
          name="is_featured"
          checked={formData.is_featured}
          onChange={(e) =>
            setFormData({ ...formData, is_featured: e.target.checked })
          }
        />
        <label className="form-check-label" htmlFor="is_featured">
          Featured Product
        </label>
      </div>

      <button type="submit" className="btn btn-primary product-form-submit">
        Save
      </button>
      {error && <div className="alert alert-danger mt-3">{error}</div>}
      {successMessage && (
        <div className="alert alert-success mt-3">{successMessage}</div>
      )}
    </form>
  );
};

export default ProductForm;
