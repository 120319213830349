import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useParams, Link, useNavigate } from "react-router-dom";
import { Tabs, Tab, Breadcrumb, Carousel } from "react-bootstrap";
import "./css/ProductDetail.css";
import SimilarProducts from "./SimilarProducts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart, faHeartBroken } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { Helmet } from "react-helmet";
import { UserContext } from "../UserContext";

const ProductDetail = ({
  handleAddToCart,
  handleRemoveFromCart,
  cartItems,
}) => {
  const navigate = useNavigate();
  const { productId } = useParams();
  const { user } = useContext(UserContext);
  const [product, setProduct] = useState(null);
  const [similarProducts, setSimilarProducts] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [isInWishlist, setIsInWishlist] = useState(false);
  const [settings, setSettings] = useState({
    baseUrl: "",
    whatsappPhone: "",
    currency: "",
  });

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/api/settings`
        );
        setSettings(response.data);
      } catch (error) {
        console.error("Error fetching settings from the database:", error);
      }
    };

    fetchSettings();
  }, []);

  const handleOrderViaWhatsApp = () => {
    const productUrl = `${settings.baseUrl}/product/${product.id}`;
    const message = `Hello, I would like to order the product: ${product.name}. Here is the link: ${productUrl}`;
    const whatsappUrl = `https://wa.me/${
      settings.whatsappPhone
    }?text=${encodeURIComponent(message)}`;
    window.open(whatsappUrl, "_blank");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/api/products/${productId}`
        );
        setProduct(response.data);
        logProductView();
      } catch (error) {
        console.error("Error fetching product:", error);
      }
    };

    fetchProduct();
  }, [productId]);

  const logProductView = async () => {
    const token = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    try {
      await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/product-view`,
        { productId },
        config
      );
    } catch (error) {
      console.error("Error logging product view:", error);
    }
  };

  useEffect(() => {
    const fetchSimilarProducts = async () => {
      if (product && product.category) {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_SERVER_URL}/api/products/similar/${product.category}`,
            {
              params: {
                subcategory: product.subcategory,
                limit: 8,
                excludeId: product.id,
                productName: product.name,
              },
            }
          );
          setSimilarProducts(response.data.products);
        } catch (error) {
          console.error("Error fetching similar products:", error);
        }
      }
    };

    fetchSimilarProducts();
  }, [product]);

  useEffect(() => {
    const checkWishlist = async () => {
      if (user && product) {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_SERVER_URL}/api/wishlist/${user.id}`
          );
          const wishlistItems = response.data;
          setIsInWishlist(
            wishlistItems.some((item) => item.productId === product.id)
          );
        } catch (error) {
          console.error("Error checking wishlist:", error);
        }
      }
    };

    checkWishlist();
  }, [user, product]);

  const handleWishlistClick = async () => {
    if (!user) {
      navigate("/login");
      return;
    }
    try {
      if (isInWishlist) {
        await axios.delete(`${process.env.REACT_APP_SERVER_URL}/api/wishlist`, {
          data: { userId: user.id, productId: product.id },
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        });
        setIsInWishlist(false);
      } else {
        await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/api/wishlist`,
          {
            userId: user.id,
            productId: product.id,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setIsInWishlist(true);
      }
    } catch (error) {
      console.error("Error updating wishlist:", error);
    }
  };

  if (!product) {
    return <div>Loading...</div>;
  }

  const handleQuantityChange = (delta) => {
    setQuantity((prevQuantity) => {
      const newQuantity = Math.max(1, prevQuantity + delta);
      return newQuantity <= product.stock ? newQuantity : prevQuantity;
    });
  };

  const handleAddToCartClick = () => {
    handleAddToCart(product, quantity);
  };

  const inCart = cartItems.some((item) => item.id === product.id);

  const featuresList = Array.isArray(product.features) ? (
    <ul className="product-features">
      {product.features.map((feature, index) => (
        <li key={index}>
          <span>{feature.trim()}</span>
        </li>
      ))}
    </ul>
  ) : null;

  return (
    <div className="container">
      <Helmet>
        <title>
          {product?.name ?? "Product"} - {settings?.websiteName ?? "Website"}
        </title>
        <meta
          name="description"
          content={product?.description ?? "No description available"}
        />
        <meta
          name="keywords"
          content={`Buy ${product?.name ?? "product"}, ${
            product?.category ?? "category"
          }, ${product?.subcategory ?? "subcategory"}, ${
            product?.keywords ?? ""
          }`}
        />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content={product?.name ?? "Product"} />
        <meta
          property="og:description"
          content={product?.description ?? "No description available"}
        />
        <meta
          property="og:image"
          content={`${settings?.baseUrl ?? ""}${
            product?.images?.[0] ?? "/default-image.jpg"
          }`}
        />
        <meta
          property="og:url"
          content={`${settings?.baseUrl ?? ""}/product/${product?.id ?? ""}`}
        />
        <meta property="og:type" content="product" />
        <meta
          property="product:brand"
          content={settings?.websiteName ?? "Website"}
        />
        <meta
          property="product:availability"
          content={product?.stock > 0 ? "in stock" : "out of stock"}
        />
        <meta
          property="product:condition"
          content={product?.condition ?? "new"}
        />
        <meta
          property="product:price:amount"
          content={product?.price ?? "0.00"}
        />
        <meta
          property="product:price:currency"
          content={settings?.currency ?? "KES"}
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org/",
            "@type": "Product",
            name: product?.name ?? "Product",
            image: `${settings?.baseUrl ?? ""}${
              product?.images?.[0] ?? "/default-image.jpg"
            }`,
            description: product?.description ?? "No description available",
            sku: product?.sku ?? "N/A",
            mpn: product?.mpn ?? "N/A",
            brand: {
              "@type": "Brand",
              name: settings?.websiteName ?? "Website",
            },
            offers: {
              "@type": "Offer",
              url: `${settings?.baseUrl ?? ""}/product/${product?.id ?? ""}`,
              priceCurrency: settings?.currency ?? "USD",
              price: product?.price ?? "0.00",
              priceValidUntil: "2023-12-31",
              itemCondition: "http://schema.org/NewCondition",
              availability:
                product?.stock > 0
                  ? "http://schema.org/InStock"
                  : "http://schema.org/OutOfStock",
              seller: {
                "@type": "Organization",
                name: settings?.websiteName ?? "Website",
              },
            },
          })}
        </script>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "Store",
            name: settings?.websiteName ?? "Website",
            image: `${settings?.baseUrl ?? ""}${
              settings?.logoUrl ?? "/default-logo.jpg"
            }`,
            description:
              settings?.siteDescription ?? "No site description available",
            address: {
              "@type": "PostalAddress",
              streetAddress: settings?.location ?? "N/A",
            },
            url: settings?.baseUrl ?? "",
            telephone: settings?.phones ?? "N/A",
          })}
        </script>
      </Helmet>

      <div className="breadcrumb-path breadcrumb-path-product-detail">
        <Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item
            linkAs={Link}
            linkProps={{ to: `/category/${product.category}` }}
          >
            {product.category}
          </Breadcrumb.Item>
          <Breadcrumb.Item active>{product.name}</Breadcrumb.Item>
        </Breadcrumb>
      </div>

      <div className="row">
        <div className="col-md-6">
          <Carousel>
            {product.images &&
              product.images.map((image, index) => (
                <Carousel.Item key={index}>
                  <img
                    className="d-block w-100 zoom-image"
                    src={`${process.env.REACT_APP_SERVER_URL}${image}`}
                    alt={`${product.name} ${index + 1}`}
                  />
                </Carousel.Item>
              ))}
          </Carousel>
        </div>
        <div className="col-md-6" align="left">
          <h3>{product.name}</h3>
          <div className="product-info">
            <p className="product-condition-product-detail">
              {product.condition}
            </p>
            <button
              className="btn btn-secondary btn-wishlist"
              onClick={handleWishlistClick}
            >
              {isInWishlist ? (
                <>
                  <FontAwesomeIcon icon={faHeartBroken} className="me-2" />
                  <b>Remove from Wishlist</b>
                </>
              ) : (
                <>
                  <FontAwesomeIcon icon={faHeart} className="me-2" />
                  <b>Add to Wishlist</b>
                </>
              )}
            </button>
          </div>

          <div className="price-container">
            <p className="product-price">
              <b>
                {settings.currency} {Number(product.price).toLocaleString()}
              </b>
            </p>
            {product.originalPrice &&
              product.originalPrice !== product.price && (
                <p className="product-original-price">
                  {settings.currency}{" "}
                  {Number(product.originalPrice).toLocaleString()}
                </p>
              )}
          </div>

          <div className="d-flex align-items-center mb-3">
            <div className="quantity-control d-flex align-items-center">
              <button
                className="btn btn-outline-secondary"
                onClick={() => handleQuantityChange(-1)}
              >
                -
              </button>
              <input
                type="number"
                value={quantity}
                readOnly
                className="form-control text-center mx-2"
                style={{ width: "60px" }}
              />
              <button
                className="btn btn-outline-secondary"
                onClick={() => handleQuantityChange(1)}
              >
                +
              </button>
            </div>
            <p className="mb-0 ml-3 in-stock-number">
              <strong>In Stock:</strong> {product.stock}
            </p>
          </div>
          <div className="button-group mt-3">
            {product.stock === 0 ? (
              <p className="out-of-stock-text" style={{ color: "red" }}>
                <b>Out of stock</b>
              </p>
            ) : (
              <>
                {inCart ? (
                  <button
                    className="btn btn-danger btn-remove-from-cart"
                    onClick={() => handleRemoveFromCart(product.id)}
                  >
                    <span className="material-icons">remove_shopping_cart</span>
                    <b>Remove from Cart</b>
                  </button>
                ) : (
                  <button
                    className="btn btn-primary btn-add-to-cart"
                    onClick={handleAddToCartClick}
                  >
                    <span className="material-icons">add_shopping_cart</span>
                    <b>Add to Cart</b>
                  </button>
                )}
                <button
                  onClick={handleOrderViaWhatsApp}
                  className="btn btn-success btn-order-whatsapp"
                >
                  <FontAwesomeIcon
                    icon={faWhatsapp}
                    className="me-2 whatsapp-icon"
                  />
                  <b>Order via WhatsApp</b>
                </button>
              </>
            )}
          </div>
        </div>
      </div>

      <Tabs
        defaultActiveKey="features"
        id="product-detail-tabs"
        className="my-4"
      >
        <Tab eventKey="features" title="Features">
          {featuresList}
        </Tab>
        <Tab eventKey="description" title="Description">
          <p align="left" className="descriptionText">
            {product.description}
          </p>
        </Tab>
        <Tab eventKey="colors" title="Colors">
          <div className="colors-list">
            {product.colors && product.colors.length > 0 ? (
              product.colors.map((color, index) => (
                <div key={index} className="color-item">
                  <span className="color-name">{color}</span>
                </div>
              ))
            ) : (
              <p>No colors available</p>
            )}
          </div>
        </Tab>
      </Tabs>
      <br />
      <h5>Similar Products</h5>
      <br />
      <SimilarProducts
        products={similarProducts}
        handleAddToCart={handleAddToCart}
        onRemoveFromCart={handleRemoveFromCart}
        cartItems={cartItems}
      />
    </div>
  );
};

export default ProductDetail;
