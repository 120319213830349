import React, { useState, useEffect } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import {
  Save,
  DollarSign,
  Key,
  Lock,
  Phone,
  AlertCircle,
  ShieldCheck,
  Globe,
} from "lucide-react";

const MpesaSettings = () => {
  // States
  const [settings, setSettings] = useState({
    c2b_consumer_key: "",
    c2b_consumer_secret: "",
    shortcode: "",
    c2b_stk_callback_url: "",
    c2b_passkey: "",
  });
  const [savedSettings, setSavedSettings] = useState(null);
  const [status, setStatus] = useState({ type: "", message: "" });
  const [loading, setLoading] = useState(false);
  const [mainSettings, setMainSettings] = useState({
    websiteName: "",
    siteDescription: "",
    keywords: "",
  });

  // Styles
  const cardStyle = {
    background: "white",
    borderRadius: "12px",
    padding: "24px",
    marginBottom: "24px",
    boxShadow: "0 2px 12px rgba(0,0,0,0.1)",
    border: "1px solid #eee",
  };

  const inputGroupStyle = {
    marginBottom: "20px",
  };

  const labelStyle = {
    display: "block",
    marginBottom: "8px",
    fontWeight: "500",
    color: "#2c3e50",
    fontSize: "14px",
    textAlign: "left",
  };

  const inputStyle = {
    width: "100%",
    padding: "12px",
    borderRadius: "8px",
    border: "1px solid #e0e0e0",
    fontSize: "14px",
    transition: "border-color 0.3s ease",
  };

  const buttonStyle = {
    padding: "12px 24px",
    borderRadius: "8px",
    border: "none",
    backgroundColor: "#2c3e50",
    color: "white",
    fontWeight: "500",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    gap: "8px",
    transition: "background 0.3s ease",
  };

  // Effects
  useEffect(() => {
    fetchSettings();
    fetchMainSettings();
  }, []);

  // API Calls
  const fetchMainSettings = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/settings`
      );
      setMainSettings(response.data || {});
    } catch (error) {
      console.error("Error fetching main settings:", error);
    }
  };

  const fetchSettings = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/mpesa/settings`
      );
      setSavedSettings(response.data);
      if (response.data) {
        setSettings(response.data);
      }
    } catch (error) {
      setStatus({
        type: "error",
        message: "Failed to fetch M-Pesa settings",
      });
    } finally {
      setLoading(false);
    }
  };

  // Handlers
  const handleChange = (e) => {
    const { name, value } = e.target;
    setSettings((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/api/mpesa/settings`,
        settings
      );
      setStatus({
        type: "success",
        message: response.data.message || "Settings updated successfully",
      });
      setSavedSettings(settings);
    } catch (error) {
      setStatus({
        type: "error",
        message: "Failed to update settings",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ margin: "0 auto", padding: "20px 20px" }}>
      <Helmet>
        <title>{`M-Pesa Settings | ${mainSettings.websiteName}`}</title>
        <meta name="description" content={mainSettings.siteDescription} />
        <meta name="keywords" content={mainSettings.keywords} />
      </Helmet>

      <div style={cardStyle}>
        <div
          style={{
            alignItems: "center",
            gap: "10px",
            marginBottom: "24px",
          }}
        ></div>

        {status.message && (
          <div
            style={{
              padding: "12px",
              borderRadius: "8px",
              marginBottom: "20px",
              backgroundColor:
                status.type === "success" ? "#e3fcef" : "#fee2e2",
              color: status.type === "success" ? "#059669" : "#dc2626",
              display: "flex",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <AlertCircle size={18} />
            {status.message}
          </div>
        )}

        <form onSubmit={handleSubmit}>
          <div style={inputGroupStyle}>
            <label style={labelStyle}>
              <Key
                size={16}
                style={{ marginRight: "8px", verticalAlign: "middle" }}
              />
              Consumer Key
            </label>
            <input
              type="text"
              name="c2b_consumer_key"
              value={settings.c2b_consumer_key}
              onChange={handleChange}
              style={inputStyle}
              placeholder="Enter consumer key"
            />
          </div>

          <div style={inputGroupStyle}>
            <label style={labelStyle}>
              <Lock
                size={16}
                style={{ marginRight: "8px", verticalAlign: "middle" }}
              />
              Consumer Secret
            </label>
            <input
              type="password"
              name="c2b_consumer_secret"
              value={settings.c2b_consumer_secret}
              onChange={handleChange}
              style={inputStyle}
              placeholder="Enter consumer secret"
            />
          </div>

          <div style={inputGroupStyle}>
            <label style={labelStyle}>
              <Phone
                size={16}
                style={{ marginRight: "8px", verticalAlign: "middle" }}
              />
              Shortcode
            </label>
            <input
              type="text"
              name="shortcode"
              value={settings.shortcode}
              onChange={handleChange}
              style={inputStyle}
              placeholder="Enter shortcode"
            />
          </div>

          <div style={inputGroupStyle}>
            <label style={labelStyle}>
              <Globe
                size={16}
                style={{ marginRight: "8px", verticalAlign: "middle" }}
              />
              STK Callback URL
            </label>
            <input
              type="text"
              name="c2b_stk_callback_url"
              value={settings.c2b_stk_callback_url}
              onChange={handleChange}
              style={inputStyle}
              placeholder="Enter callback URL"
            />
          </div>

          <div style={inputGroupStyle}>
            <label style={labelStyle}>
              <ShieldCheck
                size={16}
                style={{ marginRight: "8px", verticalAlign: "middle" }}
              />
              Passkey
            </label>
            <input
              type="password"
              name="c2b_passkey"
              value={settings.c2b_passkey}
              onChange={handleChange}
              style={inputStyle}
              placeholder="Enter passkey"
            />
          </div>

          <button type="submit" style={buttonStyle} disabled={loading}>
            <Save size={18} />
            {loading ? "Saving..." : "Save Settings"}
          </button>
        </form>
      </div>

      {savedSettings && (
        <div
          style={{
            ...cardStyle,
            backgroundColor: "#f8fafc",
          }}
        >
          <h3
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              color: "#2c3e50",
              fontSize: "1.2rem",
              marginBottom: "16px",
            }}
          >
            <DollarSign size={20} />
            Current Settings
          </h3>

          <div
            style={{
              textAlign: "left",
              gap: "12px",
            }}
          >
            <div>
              <strong style={{ fontSize: "14px", color: "#4b5563" }}>
                Consumer Key:
              </strong>
              <div style={{ marginTop: "4px", fontSize: "14px" }}>
                {savedSettings.c2b_consumer_key}
              </div>
            </div>

            <div>
              <strong style={{ fontSize: "14px", color: "#4b5563" }}>
                Shortcode:
              </strong>
              <div style={{ marginTop: "4px", fontSize: "14px" }}>
                {savedSettings.shortcode}
              </div>
            </div>

            <div>
              <strong style={{ fontSize: "14px", color: "#4b5563" }}>
                Callback URL:
              </strong>
              <div style={{ marginTop: "4px", fontSize: "14px" }}>
                {savedSettings.c2b_stk_callback_url}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MpesaSettings;
