import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Mail, AlertCircle, ArrowLeft, CheckCircle } from "lucide-react";

const AdminResetPassword = () => {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  const styles = {
    container: {
      minHeight: "100vh",
      background: "#f8f8f8",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "20px",
    },
    card: {
      width: "100%",
      maxWidth: "420px",
      backgroundColor: "white",
      borderRadius: "20px",
      padding: "40px",
      boxShadow: "0 10px 30px rgba(0, 0, 0, 0.1)",
      border: "1px solid rgba(255, 255, 255, 0.2)",
    },
    backLink: {
      display: "flex",
      alignItems: "center",
      gap: "8px",
      color: "#64748b",
      textDecoration: "none",
      fontSize: "15px",
      marginBottom: "32px",
      transition: "color 0.3s ease",
    },
    header: {
      textAlign: "center",
      marginBottom: "32px",
    },
    title: {
      fontSize: "28px",
      fontWeight: "700",
      color: "#1a1a1a",
      marginBottom: "10px",
    },
    subtitle: {
      fontSize: "16px",
      color: "#666",
      lineHeight: "1.5",
    },
    errorContainer: {
      backgroundColor: "#FEE2E2",
      borderLeft: "4px solid #EF4444",
      borderRadius: "8px",
      padding: "16px",
      marginBottom: "24px",
      display: "flex",
      alignItems: "center",
      gap: "12px",
    },
    successContainer: {
      backgroundColor: "#ECFDF5",
      borderLeft: "4px solid #10B981",
      borderRadius: "8px",
      padding: "16px",
      marginBottom: "24px",
      textAlign: "center",
    },
    successText: {
      color: "#059669",
      fontSize: "15px",
      lineHeight: "1.5",
    },
    errorText: {
      color: "#DC2626",
      fontSize: "14px",
    },
    formGroup: {
      marginBottom: "24px",
    },
    label: {
      textAlign: "left",
      display: "block",
      fontSize: "14px",
      fontWeight: "600",
      color: "#374151",
      marginBottom: "8px",
    },
    inputWrapper: {
      position: "relative",
    },
    input: {
      width: "100%",
      padding: "14px 14px 14px 45px",
      borderRadius: "12px",
      border: "1px solid #E5E7EB",
      fontSize: "15px",
      transition: "all 0.3s ease",
      backgroundColor: "#F9FAFB",
    },
    icon: {
      position: "absolute",
      left: "16px",
      top: "50%",
      transform: "translateY(-50%)",
      color: "#9CA3AF",
    },
    button: {
      width: "100%",
      padding: "14px",
      borderRadius: "12px",
      backgroundColor: "#2563EB",
      color: "white",
      fontSize: "16px",
      fontWeight: "500",
      border: "none",
      cursor: "pointer",
      transition: "all 0.3s ease",
      marginTop: "16px",
    },
    returnLink: {
      display: "inline-block",
      marginTop: "16px",
      color: "#2563EB",
      textDecoration: "none",
      fontSize: "15px",
      fontWeight: "500",
    },
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError("");
    setSuccess(false);

    try {
      await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/admin/reset-password`,
        { email }
      );
      setSuccess(true);
    } catch (error) {
      setError(error.response?.data?.error || "Failed to send reset email");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.card}>
        <Link
          to="/admin/login"
          style={styles.backLink}
          onMouseEnter={(e) => (e.target.style.color = "#475569")}
          onMouseLeave={(e) => (e.target.style.color = "#64748b")}
        >
          <ArrowLeft size={20} />
          Back to login
        </Link>

        <div style={styles.header}>
          <h2 style={styles.title}>Reset Password</h2>
          <p style={styles.subtitle}>
            Enter your email address and we'll send you a link to reset your
            password
          </p>
        </div>

        {error && (
          <div style={styles.errorContainer}>
            <AlertCircle size={20} color="#DC2626" />
            <p style={styles.errorText}>{error}</p>
          </div>
        )}

        {success ? (
          <div style={styles.successContainer}>
            <CheckCircle
              size={24}
              color="#059669"
              style={{ margin: "0 auto 12px" }}
            />
            <p style={styles.successText}>
              If an account exists with that email, you will receive password
              reset instructions.
            </p>
            <Link
              to="/admin/login"
              style={styles.returnLink}
              onMouseEnter={(e) => (e.target.style.color = "#1D4ED8")}
              onMouseLeave={(e) => (e.target.style.color = "#2563EB")}
            >
              Return to login
            </Link>
          </div>
        ) : (
          <form onSubmit={handleSubmit}>
            <div style={styles.formGroup}>
              <label style={styles.label}>Email Address</label>
              <div style={styles.inputWrapper}>
                <Mail size={20} style={styles.icon} />
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  style={styles.input}
                  placeholder="Enter your email"
                  required
                />
              </div>
            </div>

            <button
              type="submit"
              disabled={isLoading}
              style={{
                ...styles.button,
                opacity: isLoading ? 0.7 : 1,
                cursor: isLoading ? "not-allowed" : "pointer",
              }}
              onMouseEnter={(e) =>
                !isLoading && (e.target.style.backgroundColor = "#1D4ED8")
              }
              onMouseLeave={(e) =>
                !isLoading && (e.target.style.backgroundColor = "#2563EB")
              }
            >
              {isLoading ? "Sending..." : "Send Reset Link"}
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default AdminResetPassword;
