import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { Spinner } from "react-bootstrap";
import { UserContext } from "../UserContext";
import { Helmet } from "react-helmet";
import { Mail, Lock, ArrowRight } from "lucide-react";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [captchaToken, setCaptchaToken] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { login, user } = useContext(UserContext);
  const [settings, setSettings] = useState({
    websiteName: "",
    siteDescription: "",
    keywords: "",
  });

  useEffect(() => {
    // Redirect if user is already logged in
    if (user) {
      navigate("/dashboard");
    }
  }, [user, navigate]);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/api/settings`
        );
        setSettings(response.data);
      } catch (error) {
        console.error("Error fetching settings:", error);
      }
    };

    fetchSettings();
  }, []);

  const handleCaptchaChange = (token) => {
    setCaptchaToken(token);
    setError(""); // Clear any existing errors when captcha is completed
  };

  // In Login.js handleSubmit function, update the URL to use the environment variable
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    // Validate inputs
    if (!email.trim() || !password.trim()) {
      setError("Please fill in all fields");
      return;
    }

    if (!captchaToken) {
      setError("Please complete the captcha");
      return;
    }

    try {
      setIsLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/login`,
        {
          email,
          password,
          captchaToken,
        }
      );

      if (response.data.token) {
        // Get user profile data immediately after login
        const profileResponse = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/api/profile`,
          {
            headers: {
              Authorization: `Bearer ${response.data.token}`,
            },
          }
        );

        // Use the login function from context with both token and user data
        await login(response.data.token, profileResponse.data);
        navigate("/dashboard");
      } else {
        setError("Invalid response from server");
      }
    } catch (error) {
      if (error.response?.status === 401) {
        setError("Invalid email or password");
      } else if (error.response?.status === 403) {
        setError("Account is not verified. Please check your email");
      } else if (error.response?.data?.error) {
        setError(error.response.data.error);
      } else {
        setError("An error occurred. Please try again later");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const containerStyle = {
    minHeight: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "2rem",
    background: "#f8fafc",
  };

  const cardStyle = {
    textAlign: "left",
    width: "100%",
    maxWidth: "400px",
    background: "white",
    borderRadius: "1rem",
    boxShadow:
      "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
    padding: "2rem",
  };

  const formGroupStyle = {
    marginBottom: "1.5rem",
    position: "relative",
  };

  const inputContainerStyle = {
    position: "relative",
    display: "flex",
    alignItems: "center",
  };

  const inputStyle = {
    width: "100%",
    padding: "0.75rem 1rem 0.75rem 2.5rem",
    borderRadius: "0.5rem",
    border: "1px solid #e2e8f0",
    fontSize: "1rem",
    transition: "all 0.3s ease",
    outline: "none",
  };

  const inputIconStyle = {
    position: "absolute",
    left: "0.75rem",
    color: "#64748b",
  };

  const labelStyle = {
    display: "block",
    marginBottom: "0.5rem",
    color: "#4a5568",
    fontWeight: "500",
    textAlign: "left",
  };

  const buttonStyle = {
    width: "100%",
    padding: "0.75rem",
    borderRadius: "0.5rem",
    border: "none",
    background: "var(--primary-color)",
    color: "white",
    fontSize: "1rem",
    fontWeight: "500",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "0.5rem",
    cursor: "pointer",
    transition: "all 0.3s ease",
  };

  const linkStyle = {
    color: "var(--primary-color)",
    textDecoration: "none",
    fontSize: "0.875rem",
    transition: "color 0.3s ease",
  };

  return (
    <div style={containerStyle}>
      <Helmet>
        <meta name="description" content={settings.siteDescription} />
        <meta name="keywords" content={settings.keywords} />
        <meta name="author" content={settings.websiteName} />
        <meta name="robots" content="index, follow" />
        <meta name="googlebot" content="index, follow" />
        <meta name="bingbot" content="index, follow" />
        <meta property="og:title" content={settings.websiteName} />
        <meta property="og:description" content={settings.siteDescription} />
        <meta
          property="og:image"
          content={`${settings.serverUrl}${settings.logoUrl}`}
        />
        <meta property="og:url" content={settings.baseUrl} />
        <meta property="og:type" content="website" />
        <title>{`Login | ${settings.websiteName}`}</title>
      </Helmet>

      <div style={cardStyle}>
        <h1
          style={{
            fontSize: "1.875rem",
            fontWeight: "600",
            marginBottom: "0.5rem",
            color: "#1e293b",
          }}
        >
          Welcome back
        </h1>
        <p
          style={{
            color: "#64748b",
            marginBottom: "2rem",
          }}
        >
          Please enter your details to sign in
        </p>

        {error && (
          <div
            style={{
              padding: "0.75rem",
              marginBottom: "1.5rem",
              borderRadius: "0.5rem",
              background: "#fee2e2",
              color: "#991b1b",
              fontSize: "0.875rem",
            }}
          >
            {error}
          </div>
        )}

        <form onSubmit={handleSubmit}>
          <div style={formGroupStyle}>
            <label style={labelStyle}>Email</label>
            <div style={inputContainerStyle}>
              <Mail size={18} style={inputIconStyle} />
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                disabled={isLoading}
                placeholder="Enter your email"
                style={inputStyle}
              />
            </div>
          </div>

          <div style={formGroupStyle}>
            <label style={labelStyle}>Password</label>
            <div style={inputContainerStyle}>
              <Lock size={18} style={inputIconStyle} />
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                disabled={isLoading}
                placeholder="Enter your password"
                style={inputStyle}
              />
            </div>
          </div>

          <div style={{ marginBottom: "1.5rem" }}>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_V2_CAPTCHA_API_KEY}
              onChange={handleCaptchaChange}
            />
          </div>

          <button
            type="submit"
            style={buttonStyle}
            disabled={isLoading}
            onMouseEnter={(e) => {
              e.target.style.opacity = "0.9";
            }}
            onMouseLeave={(e) => {
              e.target.style.opacity = "1";
            }}
          >
            {isLoading ? (
              <>
                <Spinner size="sm" />
                Signing in...
              </>
            ) : (
              <>
                Sign in
                <ArrowRight size={18} />
              </>
            )}
          </button>

          <div
            style={{
              marginTop: "1.5rem",
              display: "flex",
              flexDirection: "column",
              gap: "0.75rem",
              textAlign: "center",
            }}
          >
            <a
              href="/reset-password"
              style={linkStyle}
              onMouseEnter={(e) => {
                e.target.style.color = "#1e40af";
              }}
              onMouseLeave={(e) => {
                e.target.style.color = "var(--primary-color)";
              }}
            >
              Forgot your password?
            </a>
            <div style={{ color: "#64748b" }}>
              Don't have an account?{" "}
              <a
                href="/register"
                style={linkStyle}
                onMouseEnter={(e) => {
                  e.target.style.color = "#1e40af";
                }}
                onMouseLeave={(e) => {
                  e.target.style.color = "var(--primary-color)";
                }}
              >
                Sign up
              </a>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
