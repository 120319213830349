import React, { useState, useEffect } from "react";
import Carousel from "react-bootstrap/Carousel";
import axios from "axios";
import "./css/LargeTopBanner.css"; // Import CSS for additional styling
import TopSelling from "./TopSelling";

const LargeTopBanner = ({ onAddToCart, onRemoveFromCart, cartItems }) => {
  const [settings, setSettings] = useState({
    bannerImages: [],
  });

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/api/settings`
        );
        setSettings(response.data);
      } catch (error) {
        console.error("Error fetching settings from the database:", error);
      }
    };

    fetchSettings();
  }, []);

  return (
    <div className="large-top-banner">
      <Carousel interval={3000}>
        {settings.bannerImages.map((image, index) => (
          <Carousel.Item key={index}>
            <img
              className="d-block w-100 banner-image"
              src={`${process.env.REACT_APP_SERVER_URL}${image}`}
              alt={`Advert ${index + 1}`}
            />
          </Carousel.Item>
        ))}
      </Carousel>
      <TopSelling
        onAddToCart={onAddToCart}
        onRemoveFromCart={onRemoveFromCart}
        cartItems={cartItems}
      />
    </div>
  );
};

export default LargeTopBanner;
