import React, { useState, useEffect } from "react";
import axios from "axios";
import { Modal, Button, Form } from "react-bootstrap";
import { Helmet } from "react-helmet";
import {
  HelpCircle,
  Plus,
  Edit2,
  Trash2,
  AlertCircle,
  Search,
  Save,
} from "lucide-react";

const ManageFAQs = () => {
  // States
  const [faqs, setFaqs] = useState([]);
  const [formData, setFormData] = useState({ question: "", answer: "" });
  const [isEditing, setIsEditing] = useState(false);
  const [editingId, setEditingId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [settings, setSettings] = useState({
    websiteName: "",
    siteDescription: "",
    keywords: "",
  });

  // Styles
  const cardStyle = {
    background: "white",
    borderRadius: "12px",
    padding: "24px",
    marginBottom: "24px",
    boxShadow: "0 2px 12px rgba(0,0,0,0.1)",
    border: "1px solid #eee",
  };

  const inputStyle = {
    padding: "12px",
    borderRadius: "8px",
    border: "1px solid #e0e0e0",
    fontSize: "14px",
    width: "100%",
    transition: "border-color 0.3s ease",
    marginBottom: "16px",
  };

  const buttonStyle = {
    padding: "12px 24px",
    borderRadius: "8px",
    border: "none",
    backgroundColor: "#2c3e50",
    color: "white",
    fontWeight: "500",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    gap: "8px",
  };

  // Effects
  useEffect(() => {
    fetchSettings();
    fetchFaqs();
  }, []);

  // API Calls
  const fetchSettings = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/settings`
      );
      setSettings(response.data || {});
    } catch (error) {
      console.error("Error fetching settings:", error);
    }
  };

  const fetchFaqs = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/faqs`
      );
      setFaqs(response.data);
    } catch (error) {
      console.error("Error fetching FAQs:", error);
    } finally {
      setLoading(false);
    }
  };

  // Handlers
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (isEditing) {
        await axios.put(
          `${process.env.REACT_APP_SERVER_URL}/api/faqs/${editingId}`,
          formData
        );
      } else {
        await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/api/faqs`,
          formData
        );
      }
      resetForm();
      fetchFaqs();
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setLoading(false);
      setShowModal(false);
    }
  };

  const handleEditClick = (faq) => {
    setFormData({
      question: faq.question,
      answer: faq.answer,
    });
    setIsEditing(true);
    setEditingId(faq.id);
    setShowModal(true);
  };

  const handleDeleteClick = async (id) => {
    if (!window.confirm("Are you sure you want to delete this FAQ?")) return;

    setLoading(true);
    try {
      await axios.delete(`${process.env.REACT_APP_SERVER_URL}/api/faqs/${id}`);
      fetchFaqs();
    } catch (error) {
      console.error("Error deleting FAQ:", error);
    } finally {
      setLoading(false);
    }
  };

  const resetForm = () => {
    setFormData({ question: "", answer: "" });
    setIsEditing(false);
    setEditingId(null);
  };

  // Filter FAQs based on search
  const filteredFaqs = faqs.filter(
    (faq) =>
      faq.question.toLowerCase().includes(searchTerm.toLowerCase()) ||
      faq.answer.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div style={{ margin: "0 auto", padding: "20px 20px" }}>
      <Helmet>
        <title>{`Manage FAQs | ${settings.websiteName}`}</title>
        <meta name="description" content={settings.siteDescription} />
        <meta name="keywords" content={settings.keywords} />
      </Helmet>

      <div style={cardStyle}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "left",
            marginBottom: "24px",
          }}
        >
          <h2
            style={{
              display: "flex",
              alignItems: "left",
              gap: "10px",
              margin: 0,
              color: "#2c3e50",
              fontSize: "1.8rem",
            }}
          >
            <HelpCircle size={24} />
            FAQs
          </h2>

          <Button onClick={() => setShowModal(true)} style={buttonStyle}>
            <Plus size={18} />
            Add FAQ
          </Button>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "left",
            gap: "8px",
            padding: "8px 12px",
            borderRadius: "8px",
            border: "1px solid #e0e0e0",
            marginBottom: "24px",
          }}
        >
          <Search size={18} color="#6b7280" />
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search FAQs..."
            style={{
              border: "none",
              outline: "none",
              width: "100%",
              fontSize: "14px",
            }}
          />
        </div>

        {loading ? (
          <div style={{ textAlign: "left", padding: "40px" }}>Loading...</div>
        ) : filteredFaqs.length === 0 ? (
          <div style={{ textAlign: "left", padding: "40px", color: "#6b7280" }}>
            No FAQs found
          </div>
        ) : (
          <div style={{ display: "grid", gap: "16px" }}>
            {filteredFaqs.map((faq) => (
              <div
                key={faq.id}
                style={{
                  padding: "20px",
                  borderRadius: "8px",
                  border: "1px solid #e0e0e0",
                  backgroundColor: "#f8fafc",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    marginBottom: "12px",
                  }}
                >
                  <h3
                    style={{
                      margin: 0,
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "#2c3e50",
                    }}
                  >
                    {faq.question}
                  </h3>

                  <div style={{ display: "flex", gap: "8px" }}>
                    <Button
                      variant="outline-primary"
                      size="sm"
                      onClick={() => handleEditClick(faq)}
                      style={{
                        padding: "8px",
                        borderRadius: "6px",
                      }}
                    >
                      <Edit2 size={16} />
                    </Button>
                    <Button
                      variant="outline-danger"
                      size="sm"
                      onClick={() => handleDeleteClick(faq.id)}
                      style={{
                        padding: "8px",
                        borderRadius: "6px",
                      }}
                    >
                      <Trash2 size={16} />
                    </Button>
                  </div>
                </div>

                <p
                  style={{
                    textAlign: "left",
                    margin: 0,
                    fontSize: "14px",
                    color: "#4b5563",
                    lineHeight: 1.6,
                  }}
                >
                  {faq.answer}
                </p>
              </div>
            ))}
          </div>
        )}
      </div>

      <Modal
        show={showModal}
        onHide={() => {
          setShowModal(false);
          resetForm();
        }}
        size="lg"
      >
        <Modal.Header
          closeButton
          style={{ border: "none", padding: "20px 24px 0" }}
        >
          <Modal.Title>{isEditing ? "Edit FAQ" : "Add New FAQ"}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: "20px 24px" }}>
          <Form onSubmit={handleFormSubmit}>
            <Form.Group style={{ marginBottom: "16px" }}>
              <Form.Label style={{ fontWeight: "500" }}>Question</Form.Label>
              <Form.Control
                type="text"
                name="question"
                value={formData.question}
                onChange={handleInputChange}
                style={inputStyle}
                placeholder="Enter question"
                required
              />
            </Form.Group>

            <Form.Group style={{ marginBottom: "24px" }}>
              <Form.Label style={{ fontWeight: "500" }}>Answer</Form.Label>
              <Form.Control
                as="textarea"
                name="answer"
                value={formData.answer}
                onChange={handleInputChange}
                style={{
                  ...inputStyle,
                  minHeight: "120px",
                }}
                placeholder="Enter answer"
                required
              />
            </Form.Group>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "12px",
              }}
            >
              <Button
                variant="outline-secondary"
                onClick={() => {
                  setShowModal(false);
                  resetForm();
                }}
                style={{
                  padding: "10px 16px",
                  borderRadius: "6px",
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                style={{
                  ...buttonStyle,
                  padding: "10px 16px",
                }}
                disabled={loading}
              >
                <Save size={18} />
                {loading ? "Saving..." : isEditing ? "Update FAQ" : "Add FAQ"}
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ManageFAQs;
