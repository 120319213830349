import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container } from 'react-bootstrap';
import './css/Policy.css';
import { Helmet } from 'react-helmet';

const TermsConditions = () => {
  const [termsConditions, setTermsConditions] = useState('');
  const [settings, setSettings] = useState({
    phones: '',
    currency: '',
    websiteName: '',
    baseUrl: '',
    keywords: '',
    siteDescription: '',
  });


  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/settings`);
        setSettings(response.data);
      } catch (error) {
        console.error('Error fetching settings from the database:', error);
      }
    };

    fetchSettings();
  }, []);

  useEffect(() => {
    const fetchTermsConditions = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/settings`);
        setTermsConditions(response.data.termsConditions);
      } catch (error) {
        console.error('Error fetching terms and conditions:', error);
      }
    };

    fetchTermsConditions();
  }, []);

  return (
    <Container align="left" className="policy-container">
      <Helmet>
        <meta name="description" content={settings.siteDescription} />
        <meta name="keywords" content={settings.keywords} />
        <meta name="author" content={settings.websiteName} />
        <meta name="robots" content="index, follow" />
        <meta name="googlebot" content="index, follow" />
        <meta name="bingbot" content="index, follow" />
        <meta property="og:title" content={settings.websiteName} />
        <meta property="og:description" content={settings.siteDescription} />
        <meta property="og:image" content={`${settings.serverUrl}${settings.logoUrl}`} />
        <meta property="og:url" content={settings.baseUrl} />
        <meta property="og:type" content="website" />
        <title>{`Terms and conditions | ${settings.websiteName}`}</title>
      </Helmet>
      <h2 className="my-4 text-center">Terms and Conditions</h2>
      <div dangerouslySetInnerHTML={{ __html: termsConditions }} />
    </Container>
  );
};

export default TermsConditions;
