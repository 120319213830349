import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/ProductList.css";
import ViewMoreButton from "./ViewMoreButton";
import AddToCartModal from "./AddToCartModal";

const ProductList = ({ onAddToCart, onRemoveFromCart, cartItems }) => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [visible, setVisible] = useState(6); // Number of products to show initially
  const [showModal, setShowModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const navigate = useNavigate();
  const [settings, setSettings] = useState({
    phones: "",
    currency: "",
  });

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/api/settings`
        );
        setSettings(response.data);
      } catch (error) {
        console.error("Error fetching settings from the database:", error);
      }
    };

    fetchSettings();
  }, []);
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/api/products/featured`
        );
        if (response.data && Array.isArray(response.data.products)) {
          setProducts(response.data.products);
        } else {
          setProducts([]);
          setError("No products found");
        }
      } catch (error) {
        console.error("Error fetching products:", error);
        setError(error.message);
        setProducts([]); // Fallback to an empty array in case of error
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  const showMoreProducts = () => {
    setVisible((prevValue) => prevValue + 6);
  };

  const handleAddToCartClick = (product) => {
    setSelectedProduct(product);
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleModalSave = (product, quantity) => {
    if (quantity > 0 && quantity <= product.stock) {
      onAddToCart(product, quantity);
    } else {
      alert("Invalid quantity");
    }
    setShowModal(false);
  };

  const handleCardClick = (productId) => {
    navigate(`/product/${productId}`);
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="container">
      <h5 className="my-4">Featured products</h5>
      <div className="row">
        {products.slice(0, visible).map((product) => {
          const inCart = cartItems.some((item) => item.id === product.id);
          return (
            <div className="col-md-3 margin-class" key={product.id}>
              <div
                className="card mb-3"
                onClick={() => handleCardClick(product.id)}
                style={{ cursor: "pointer" }}
              >
                <div className="position-relative">
                  <img
                    src={
                      product.images && product.images[0]
                        ? `${process.env.REACT_APP_SERVER_URL}${product.images[0]}`
                        : "default-image-path"
                    } // Use the first image if available, otherwise a default image
                    className="card-img-top"
                    alt={product.name}
                  />
                  {product.discount > 0 && (
                    <span className="badge badge-discount position-absolute top-0 end-0">
                      -{Math.ceil(product.discount)}%
                    </span>
                  )}
                </div>
                <div align="left" className="card-body">
                  <h5 className="card-title text-truncate">{product.name}</h5>
                  <p className="product-condition">{product.condition}</p>
                  <p className="card-text">
                    <span className="price-new">
                      {settings.currency}{" "}
                      {Number(product.price).toLocaleString()}
                    </span>
                    {product.originalPrice &&
                      product.originalPrice !== product.price && (
                        <span className="price-old">
                          {settings.currency}{" "}
                          {Number(product.originalPrice).toLocaleString()}
                        </span>
                      )}
                  </p>
                  {product.stock === 0 ? (
                    <p className="out-of-stock-text" style={{ color: "red" }}>
                      <b>Out of stock</b>
                    </p>
                  ) : inCart ? (
                    <button
                      className="btn btn-danger d-flex justify-content-center align-items-center"
                      onClick={(e) => {
                        e.stopPropagation();
                        onRemoveFromCart(product.id);
                      }}
                      style={{ width: "100%" }}
                    >
                      <span className="material-icons me-1">
                        remove_shopping_cart
                      </span>{" "}
                      Remove from Cart
                    </button>
                  ) : (
                    <button
                      className="btn btn-primary d-flex justify-content-center align-items-center"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleAddToCartClick(product);
                      }}
                      style={{ width: "100%" }}
                    >
                      <span className="material-icons me-1">
                        add_shopping_cart
                      </span>{" "}
                      Add to Cart
                    </button>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      {visible < products.length && (
        <ViewMoreButton
          align="center"
          onClick={showMoreProducts}
          isVisible={visible < products.length}
        />
      )}

      {/* Quantity Modal */}
      <AddToCartModal
        show={showModal}
        handleClose={handleModalClose}
        handleSave={handleModalSave}
        product={selectedProduct}
      />
    </div>
  );
};

export default ProductList;
