import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { Lock, AlertCircle, Check, Eye, EyeOff } from "lucide-react";

const AdminNewPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  const styles = {
    container: {
      minHeight: "100vh",
      background: "#f8f8f8",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "20px",
    },
    card: {
      width: "100%",
      maxWidth: "420px",
      backgroundColor: "white",
      borderRadius: "20px",
      padding: "40px",
      boxShadow: "0 10px 30px rgba(0, 0, 0, 0.1)",
      border: "1px solid rgba(255, 255, 255, 0.2)",
    },
    header: {
      textAlign: "center",
      marginBottom: "32px",
    },
    title: {
      fontSize: "28px",
      fontWeight: "700",
      color: "#1a1a1a",
      marginBottom: "10px",
    },
    subtitle: {
      fontSize: "16px",
      color: "#666",
      lineHeight: "1.5",
    },
    errorContainer: {
      backgroundColor: "#FEE2E2",
      borderLeft: "4px solid #EF4444",
      borderRadius: "8px",
      padding: "16px",
      marginBottom: "24px",
      display: "flex",
      alignItems: "center",
      gap: "12px",
    },
    errorText: {
      color: "#DC2626",
      fontSize: "14px",
    },
    formGroup: {
      marginBottom: "24px",
    },
    label: {
      textAlign: "left",
      display: "block",
      fontSize: "14px",
      fontWeight: "600",
      color: "#374151",
      marginBottom: "8px",
    },
    inputWrapper: {
      position: "relative",
    },
    input: {
      width: "100%",
      padding: "14px 14px 14px 45px",
      borderRadius: "12px",
      border: "1px solid #E5E7EB",
      fontSize: "15px",
      transition: "all 0.3s ease",
      backgroundColor: "#F9FAFB",
      paddingRight: "45px", // Space for the eye icon
    },
    icon: {
      position: "absolute",
      left: "16px",
      top: "50%",
      transform: "translateY(-50%)",
      color: "#9CA3AF",
    },
    togglePasswordIcon: {
      position: "absolute",
      right: "16px",
      top: "50%",
      transform: "translateY(-50%)",
      color: "#9CA3AF",
      cursor: "pointer",
      padding: "4px",
      borderRadius: "4px",
      transition: "all 0.3s ease",
    },
    requirementsList: {
      marginTop: "8px",
      padding: "0",
      listStyle: "none",
    },
    requirementItem: {
      display: "flex",
      alignItems: "center",
      gap: "8px",
      fontSize: "13px",
      color: "#6B7280",
      marginBottom: "4px",
    },
    button: {
      width: "100%",
      padding: "14px",
      borderRadius: "12px",
      backgroundColor: "#2563EB",
      color: "white",
      fontSize: "16px",
      fontWeight: "500",
      border: "none",
      cursor: "pointer",
      transition: "all 0.3s ease",
      marginTop: "24px",
    },
    checkIcon: {
      color: "#10B981",
      marginRight: "4px",
    },
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    setIsLoading(true);
    setError("");

    try {
      await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/admin/new-password`,
        { token, password }
      );
      navigate("/admin/login", {
        state: {
          message:
            "Password updated successfully. Please login with your new password.",
        },
      });
    } catch (error) {
      setError(error.response?.data?.error || "Failed to update password");
    } finally {
      setIsLoading(false);
    }
  };

  const passwordRequirements = [
    { text: "At least 8 characters long", met: password.length >= 8 },
    {
      text: "Contains at least one uppercase letter",
      met: /[A-Z]/.test(password),
    },
    {
      text: "Contains at least one lowercase letter",
      met: /[a-z]/.test(password),
    },
    { text: "Contains at least one number", met: /\d/.test(password) },
    {
      text: "Contains at least one special character",
      met: /[!@#$%^&*]/.test(password),
    },
  ];

  return (
    <div style={styles.container}>
      <div style={styles.card}>
        <div style={styles.header}>
          <h2 style={styles.title}>Set New Password</h2>
          <p style={styles.subtitle}>
            Create a strong password for your account
          </p>
        </div>

        {error && (
          <div style={styles.errorContainer}>
            <AlertCircle size={20} color="#DC2626" />
            <p style={styles.errorText}>{error}</p>
          </div>
        )}

        <form onSubmit={handleSubmit}>
          <div style={styles.formGroup}>
            <label style={styles.label}>New Password</label>
            <div style={styles.inputWrapper}>
              <Lock size={20} style={styles.icon} />
              <input
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                style={styles.input}
                placeholder="Enter new password"
                required
              />
              <div
                style={styles.togglePasswordIcon}
                onClick={() => setShowPassword(!showPassword)}
                onMouseEnter={(e) =>
                  (e.target.style.backgroundColor = "#F3F4F6")
                }
                onMouseLeave={(e) =>
                  (e.target.style.backgroundColor = "transparent")
                }
              >
                {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
              </div>
            </div>
            <ul style={styles.requirementsList}>
              {passwordRequirements.map((req, index) => (
                <li key={index} style={styles.requirementItem}>
                  {req.met ? (
                    <Check size={16} style={styles.checkIcon} />
                  ) : (
                    <div
                      style={{
                        width: "16px",
                        height: "16px",
                        marginRight: "4px",
                      }}
                    />
                  )}
                  <span style={{ color: req.met ? "#10B981" : "#6B7280" }}>
                    {req.text}
                  </span>
                </li>
              ))}
            </ul>
          </div>

          <div style={styles.formGroup}>
            <label style={styles.label}>Confirm Password</label>
            <div style={styles.inputWrapper}>
              <Lock size={20} style={styles.icon} />
              <input
                type={showConfirmPassword ? "text" : "password"}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                style={styles.input}
                placeholder="Confirm new password"
                required
              />
              <div
                style={styles.togglePasswordIcon}
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                onMouseEnter={(e) =>
                  (e.target.style.backgroundColor = "#F3F4F6")
                }
                onMouseLeave={(e) =>
                  (e.target.style.backgroundColor = "transparent")
                }
              >
                {showConfirmPassword ? <EyeOff size={20} /> : <Eye size={20} />}
              </div>
            </div>
          </div>

          <button
            type="submit"
            disabled={isLoading}
            style={{
              ...styles.button,
              opacity: isLoading ? 0.7 : 1,
              cursor: isLoading ? "not-allowed" : "pointer",
            }}
            onMouseEnter={(e) =>
              !isLoading && (e.target.style.backgroundColor = "#1D4ED8")
            }
            onMouseLeave={(e) =>
              !isLoading && (e.target.style.backgroundColor = "#2563EB")
            }
          >
            {isLoading ? "Updating..." : "Update Password"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default AdminNewPassword;
