import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { CheckCircle, XCircle, Loader } from "lucide-react";

const VerifyEmail = () => {
  const [status, setStatus] = useState("verifying");
  const [error, setError] = useState("");
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [settings, setSettings] = useState({
    websiteName: "",
    siteDescription: "",
    keywords: "",
  });

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/api/settings`
        );
        setSettings(response.data);
      } catch (error) {
        console.error("Error fetching settings from the database:", error);
      }
    };

    fetchSettings();
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const verifyEmail = async () => {
      const token = searchParams.get("token");
      if (!token) {
        setStatus("failed");
        setError("Invalid verification link.");
        return;
      }

      try {
        await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/api/verify-email`,
          { token }
        );
        setStatus("success");
      } catch (error) {
        setStatus("failed");
        setError(
          error.response.data.error || "Verification failed. Please try again."
        );
      }
    };

    verifyEmail();
  }, [searchParams]);

  const handleLoginRedirect = () => {
    navigate("/dashboard");
  };

  const containerStyle = {
    minHeight: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "2rem",
    background: "#f8fafc",
  };

  const cardStyle = {
    width: "100%",
    maxWidth: "400px",
    background: "white",
    borderRadius: "1rem",
    boxShadow:
      "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
    padding: "2rem",
    textAlign: "center",
  };

  const buttonStyle = {
    padding: "0.75rem 2rem",
    borderRadius: "0.5rem",
    border: "none",
    background: "var(--primary-color)",
    color: "white",
    fontSize: "1rem",
    fontWeight: "500",
    cursor: "pointer",
    transition: "all 0.3s ease",
  };

  const iconContainerStyle = {
    width: "4rem",
    height: "4rem",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "0 auto 1.5rem",
  };

  const getStatusContent = () => {
    switch (status) {
      case "verifying":
        return {
          icon: (
            <Loader
              size={32}
              className="animate-spin"
              style={{ color: "#3b82f6" }}
            />
          ),
          title: "Verifying your email",
          message: "Please wait while we verify your email address...",
          containerStyle: {
            ...iconContainerStyle,
            background: "#dbeafe",
          },
        };
      case "success":
        return {
          icon: <CheckCircle size={32} style={{ color: "#16a34a" }} />,
          title: "Email verified successfully!",
          message:
            "Your email has been verified. You can now sign in to your account.",
          containerStyle: {
            ...iconContainerStyle,
            background: "#dcfce7",
          },
        };
      case "failed":
        return {
          icon: <XCircle size={32} style={{ color: "#dc2626" }} />,
          title: "Verification failed",
          message: error || "An error occurred during verification.",
          containerStyle: {
            ...iconContainerStyle,
            background: "#fee2e2",
          },
        };
      default:
        return null;
    }
  };

  const content = getStatusContent();

  return (
    <div style={containerStyle}>
      <Helmet>
        <title>{`Verify Email | ${settings.websiteName}`}</title>
      </Helmet>

      <div style={cardStyle}>
        <div style={content.containerStyle}>{content.icon}</div>

        <h1
          style={{
            fontSize: "1.5rem",
            fontWeight: "600",
            marginBottom: "0.5rem",
            color: "#1e293b",
          }}
        >
          {content.title}
        </h1>

        <p
          style={{
            color: "#64748b",
            marginBottom: status === "verifying" ? "0" : "1.5rem",
          }}
        >
          {content.message}
        </p>

        {status === "success" && (
          <button
            style={buttonStyle}
            onClick={() => navigate("/dashboard")}
            onMouseEnter={(e) => {
              e.target.style.opacity = "0.9";
            }}
            onMouseLeave={(e) => {
              e.target.style.opacity = "1";
            }}
          >
            Go to Dashboard
          </button>
        )}
      </div>
    </div>
  );
};

export default VerifyEmail;
